@import "./variables.scss";
@import "animate.css";

@import "../../node_modules/bpg-arial-caps/css/bpg-arial-caps.min.css";

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shantell+Sans:wght@500&display=swap");

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

@import url("https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Coiny&display=swap");

@import url("https://db.onlinewebfonts.com/c/6bd54ff5fbc2cb3bb5ae57d7a1745741?family=Candy+Town");

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

@import url("https://fonts.cdnfonts.com/css/the-jack-marron");

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Short+Stack&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Short+Stack&family=Sniglet:wght@400;800&display=swap");

@import url("https://free.bboxtype.com/embedfonts/?family=FiraGO:300,400");

@font-face {
  font-family: "BPG Excelsior DejaVu 2010";
  src: url("../fonts/BPGExcelsiorDejaVu2010.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BPG Excelsior Caps DejaVu 2010";
  src: url("../fonts/BPGExcelsiorCapsDejaVu2010.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mark GEO Regular";
  src: url("../fonts/MarkGEO-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Mark GEO Medium";
  src: url("../fonts/MarkGEO-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Mark GEO CAPS Medium";
  src: url("../fonts/MarkGEOCAPS-Medium.woff2") format("woff2");
}

// html {
//   scroll-behavior: smooth;
// }

:root {
  --background: #f7f6f5;
  --bodyback: white;
  --bodyback-grey: #f4f4f4;
  --shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $primary-color;
  outline: none;
  text-decoration: none;
}

::selection {
  background: $sec-design-color;
  color: white;
}

body {
  font-family: "BPG Excelsior DejaVu 2010", Arial;
  font-weight: normal;
  // line-height: 140%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
  background: var(--background);
  // background-image: url("../../public/img/banner-background.avif");
  // background-image: url("../../public/img/cat.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  cursor: default;
  overflow-x: hidden !important;
}

button,
input,
select,
textarea {
  outline-color: $sec-design-color;
  font-family: "BPG Excelsior DejaVu 2010", Arial;

  &::placeholder {
    color: $primary-color;
    font-family: "BPG Excelsior DejaVu 2010", Arial;
  }
}

.flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.flex2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
  overflow: hidden;
  // box-shadow: var(--shadow);
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;
}

.none {
  display: none;
}

.red {
  color: red;
}

.green {
  color: green;
}

.priority {
  color: $sec-design-color !important;
}

.show {
  display: block;
}

.active {
  display: block !important;
}

.no-active {
  display: none !important;
}

.modal-box-for-mobile {
  display: none;
}

.modal-background {
  display: none;
  animation: animate__slideInRight;
  animation-duration: 0.5s;
}

.login-container,
.registration-container {
  display: none;
  animation: animate__slideInDown;
  animation-duration: 0.5s;
}

.server-error-text {
  padding: 7px;
  color: red;
  border: 1px solid red;
}

.waiting-verification {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 14px;
  width: fit-content;
  max-width: 100%; // 320px;
  margin: 40vh auto;
  padding: 16px; //10px25px:;
  background: white;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  svg {
    width: 24px;
    height: 24px;
    padding: 4px;
    background: $sec-design-color; // #34d399;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    path {
      fill: white;
    }
  }

  .waiting-verification-message-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 14px;
      font-size: 0.9rem;
      font-weight: 700;
    }

    .waiting-verification-header-text {
      color: $sec-design-color; // #34d399;
    }

    .waiting-verification-message-text {
      font-size: 0.9rem;
      font-weight: 400;
    }

    .waiting-verification-go-back {
      width: 100%;
      text-align: center;
      color: white;
      font-size: 0.9rem;
      font-weight: 400;
      padding: 10px;
      margin: 30px auto 0 auto;
      background: $sec-design-color;
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;
    }
  }
}

.padding {
  padding: 7% 0 !important;
}

.border-left {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
  border: 1px solid $last-color;
  border-left: none !important;
}

.border-right {
  border-top-right-radius: $secondary-border-radius;
  border-bottom-right-radius: $secondary-border-radius;
}

.border-bottom {
  border-bottom-left-radius: $secondary-border-radius;
  border-bottom-right-radius: $secondary-border-radius;
}

.breeds-dropdown-size {
  width: 720px !important;
  scroll-behavior: contain !important;

  .select-search {
    input {
      background-position: 1.5% center !important;
    }
  }

  ul.options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    scroll-behavior: contain !important;
  }
}

.city-dropdown-size {
  width: 600px !important;
  scroll-behavior: contain !important;

  .select-search {
    input {
      background-position: 1.5% center !important;
    }
  }

  ul.options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    scroll-behavior: contain !important;
  }
}

.confirmation-background-container {
  position: absolute;
  // top: 50%;
  // left: 50%;
  z-index: 100;
  width: 100%;
  height: 100%;
  min-height: 500px;
  padding: 35px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  .confirmation-inside-container {
    position: -webkit-sticky;
    position: sticky;
    top: 105px;
    width: 40%;
    margin: auto;
    padding: 35px;
    background: white;
    border-radius: inherit;
    -webkit-border-radius: inherit;
    -moz-border-radius: inherit;
    -ms-border-radius: inherit;
    -o-border-radius: inherit;

    p {
      text-align: center;
      font-size: medium;
      color: rgba(55, 55, 55, 1);
      margin: 20px auto;
    }

    form {
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;

      select {
        width: 100%;
        padding: 10px;
        border-radius: $primary-border-radius;
        -webkit-border-radius: $primary-border-radius;
        -moz-border-radius: $primary-border-radius;
        -ms-border-radius: $primary-border-radius;
        -o-border-radius: $primary-border-radius;
      }
    }

    .confirmation-buttons-container {
      margin: 35px auto;
      border-radius: inherit;
      -webkit-border-radius: inherit;
      -moz-border-radius: inherit;
      -ms-border-radius: inherit;
      -o-border-radius: inherit;

      button {
        display: block;
        width: 60%;
        color: white;
        font-size: 0.83rem;
        margin: auto;
        margin-top: 21px;
        padding: 10.5px 21px;
        cursor: pointer;
        background: rgba(55, 55, 55, 1);
        border: none;
        border-radius: inherit;
        -webkit-border-radius: inherit;
        -moz-border-radius: inherit;
        -ms-border-radius: inherit;
        -o-border-radius: inherit;
      }
    }

    .confirmation-warning-text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 7px;
      color: red;
      text-align: center;
      font-size: 0.83rem;
      // white-space: nowrap;

      svg {
        display: none;
        width: 17.5px;
        height: 17.5px;

        path {
          fill: red;
        }
      }
    }
  }
}

.success-payment {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  width: fit-content;
  margin: 250px auto;
  padding: 10px 25px;
  background: #66ab67;
  border-radius: $third-border-radius;
  -webkit-border-radius: $third-border-radius;
  -moz-border-radius: $third-border-radius;
  -ms-border-radius: $third-border-radius;
  -o-border-radius: $third-border-radius;

  svg {
    width: 24.5px;
    height: 24.5px;

    path {
      stroke: white;
    }
  }

  h1 {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    color: white;
  }
}

.verify-account {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  width: fit-content;
  max-width: 100%; // 320px;
  margin: 40vh auto;
  padding: 16px; //10px25px:;
  background: rgb(240 253 244); //#66ab67:;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  svg {
    width: 24.5px;
    height: 24.5px;

    path {
      fill: rgb(74 222 128);
    }
  }

  .verify-message-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 14px;
      font-size: 1rem;
      font-weight: 700;
    }

    .verified-header-text {
      color: rgb(22 101 52);
    }

    .verified-message-text {
      color: rgb(21 128 61);
      font-size: 0.9rem;
      font-weight: 400;
    }

    .verify-failed-header-text {
      color: #991b1b;
    }

    .verify-failed-message-text {
      color: #b91c1c;
      font-size: 0.9rem;
      font-weight: 400;
    }

    .verified-account-go-back {
      width: 100%;
      text-align: center;
      color: white;
      font-size: 0.9rem;
      font-weight: 400;
      padding: 10px;
      margin: 30px auto 0 auto;
      background: rgb(74 222 128);
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;
    }

    .verify-failed-go-back {
      width: 100%;
      text-align: center;
      color: white;
      font-size: 0.9rem;
      font-weight: 400;
      padding: 10px;
      margin: 30px auto 0 auto;
      background: #f87171;
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;
    }
  }
}

.failed {
  background: rgb(254 242 242);

  svg {
    path {
      fill: #f87171;
    }
  }
}

.not-found-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  margin: 30px auto 50px auto;

  img.not-found-img {
    height: 250px;
  }

  p {
    font-size: 1.3rem;
    color: #5d4367;
  }
}

/****START HEAD NAVIGATION****/
.header-middle-parent-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  padding: 0;
  margin: 0;
  // margin-bottom: 25px;

  .header-middle-inside-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 47px;
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 20px 5px;
    border: none;

    * {
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
    }

    .logo-box {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 20px;
          object-fit: contain;
          margin: 0 1px;
        }
      }
    } /****END LOGO BOX*****/

    .header-middle-short-menu {
      display: flex; // flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      flex-grow: 1;

      a {
        font-size: 0.85rem;

        &:hover {
          color: $sec-design-color;
        }
      }

      .active-link {
        color: $sec-design-color;
      }
    }

    .header-search-container {
      display: none; // flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      overflow: hidden;
      background: var(--bodyback); // rgba(255, 255, 255, 0.5);
      border: none; // 1px solid var(--bodyback);
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      &:hover {
        background: var(--bodyback);

        // .select-category-button {
        //   border-color: $sec-design-color;
        // }
      }

      .select-category-button {
        color: grey;
        font-size: 0.8rem;
        white-space: nowrap;
        padding: 8px 20px;
        background: transparent;
        border: none;
        border-right: 1px solid $hover-color;
      }

      input[type="search"] {
        width: 100%;
        padding: 10px 25px;
        background: transparent;
        border: none;

        &::placeholder {
          font-size: 0.8rem;
        }
      }

      input[type="submit"] {
        padding: 10px 5px;
        color: transparent !important;
        cursor: pointer;
        background: transparent;
        background-image: url(../../public/svg/search.svg);
        background-size: 17.5px;
        background-repeat: no-repeat;
        background-position: center center;
        border: none;
      }
    }

    .header-right-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      // border: 1px solid red;

      .menu-for-mobile {
        display: none;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        border: 1px solid $hover-color;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;

        &:hover {
          border-color: $sec-design-color;
        }

        svg {
          height: 17px;
          max-height: 17px;
        }
      }

      .language-and-darkmode {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // flex-direction: row-reverse;
        gap: 15px;
        padding: 0;
        // background: green;

        .lang-switcher-container {
          position: relative;
          margin: 0;
          padding: 0;
          background: transparent;
          border: none;
          border-radius: $secondary-border-radius;
          -webkit-border-radius: $secondary-border-radius;
          -moz-border-radius: $secondary-border-radius;
          -ms-border-radius: $secondary-border-radius;
          -o-border-radius: $secondary-border-radius;
          // border-left: 2px solid $hover-color;
          // border-right: 2px solid $hover-color;

          button {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 100%;
            padding: 9px 15px;
            overflow: hidden;
            border: none;
            background: var(--bodyback); // rgba(255, 255, 255, 0.4);
            border: none; // 1px solid var(--bodyback);
            cursor: pointer;
            border-radius: inherit;
            -webkit-border-radius: inherit;
            -moz-border-radius: inherit;
            -ms-border-radius: inherit;
            -o-border-radius: inherit;

            &:after {
              display: none;
              background: #fff;
              content: "";
              height: 155px;
              left: -75px;
              opacity: 0.5; // 0.2;
              position: absolute;
              top: -50px;
              width: 50px;
              // z-index: -10;
              transform: rotate(35deg);
              transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            }

            &:hover {
              // border-color: $sec-design-color;

              // span {
              //   color: $sec-design-color;
              // }

              &:after {
                left: 120%;
                transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
                -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
                -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
                -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
                -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              }
            }

            svg.earth {
              display: none;
              width: 17px;
              max-height: 17px;

              path {
                stroke: transparent !important;
                fill: rgb(46, 46, 46);
              }
            }

            img {
              max-height: 18px;
              border-radius: 5px;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              -ms-border-radius: 5px;
              -o-border-radius: 5px;
            }

            span {
              font-size: 0.85rem;
              font-weight: 500;
              color: black;
            }

            svg.language-arrow {
              display: none;
              width: 10px;
              max-height: 14px;
              transition: all 0.4s;
              -webkit-transition: all 0.4s;
              -moz-transition: all 0.4s;
              -ms-transition: all 0.4s;
              -o-transition: all 0.4s;

              path {
                stroke: darkgrey;
              }
            }
          }

          .lang-list-container {
            display: none;
            position: absolute;
            right: 0;
            // left: 50%;
            width: 190px;
            margin-top: 10px;
            background: $primary-bg;
            overflow: hidden;
            border-radius: $secondary-border-radius;
            -webkit-border-radius: $secondary-border-radius;
            -moz-border-radius: $secondary-border-radius;
            -ms-border-radius: $secondary-border-radius;
            -o-border-radius: $secondary-border-radius;

            // transform: translateX(-50%);
            // -webkit-transform: translateX(-50%);
            // -moz-transform: translateX(-50%);
            // -ms-transform: translateX(-50%);
            // -o-transform: translateX(-50%);

            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

            hr {
              margin: 10px auto;
              border: 1px solid var(--bodyback-grey);
            }

            ul {
              h3 {
                color: gray;
                font-size: 0.85rem;
                padding: 20px 25px;
              }

              li {
                display: flex !important;
                justify-content: flex-start;
                align-items: center;
                gap: 14px;
                flex-wrap: nowrap;
                padding: 10px 30px;
                cursor: pointer;

                &:hover {
                  // color: $sec-design-color;
                  background: transparent;
                  background: var(--bodyback-grey);
                }

                img {
                  max-height: 20px;
                }

                input[type="radio"] {
                  appearance: none;
                  width: 13px;
                  height: 13px;

                  border: 1px solid #767676;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%;
                  -ms-border-radius: 50%;
                  -o-border-radius: 50%;
                }

                p {
                  font-size: 0.8rem;
                }
              }

              .current-currency.active {
                input[type="radio"] {
                  appearance: none;
                  width: 13px;
                  height: 13px;
                  // background: #767676;
                  border: 4px solid $sec-design-color;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%;
                  -ms-border-radius: 50%;
                  -o-border-radius: 50%;
                }
              }
            }
          }

          .lang-list-container::after {
            content: "";
            position: absolute;
            top: -5px;
            right: 50px;
            // z-index: -1;
            width: 10px;
            height: 10px;
            background: $primary-bg;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
          }
        }

        .lang-switcher-container.active {
          button {
            background: var(--bodyback);
            // border-color: $sec-design-color;
            // box-shadow: var(--shadow);

            svg.earth {
              transform: none;
              -webkit-transform: none;
              -moz-transform: none;
              -ms-transform: none;
            }

            svg.language-arrow {
              transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
              -moz-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
              -o-transform: rotate(180deg);

              path {
                stroke: $search-font-color;
              }
            }

            // span {
            //   color: $sec-design-color;
            // }
          }

          .lang-list-container {
            display: block;

            svg {
              path {
                stroke: transparent;
              }
            }
          }
        } // here end lang switcher

        .dark-mode-btn {
          position: relative;
          cursor: pointer;
          display: none; // flex !important;
          justify-content: center;
          align-items: center;
          background: rgba(255, 255, 255, 0.4);
          padding: 11px;
          overflow: hidden !important;
          border: 1px solid var(--bodyback);
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;

          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;

          &:after {
            display: none;
            background: #fff;
            content: "";
            height: 155px;
            left: -75px;
            opacity: 0.5; // 0.2;
            position: absolute;
            top: -50px;
            width: 50px;
            // z-index: -10;
            transform: rotate(35deg);
            transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          }

          &:hover {
            // background: $hover-color;
            // border-color: $sec-design-color;

            // svg path {
            //   fill: $sec-design-color;
            //   stroke: $sec-design-color;
            // }

            &:after {
              left: 120%;
              transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            }
          }

          ion-icon {
            color: white;
            font-size: 17px;
          }

          svg {
            width: 17px;
            max-height: 17px;

            path {
              stroke: rgb(46, 46, 46);
            }
          }

          .sun {
            position: absolute;
            top: 50%;
            left: 50%;

            transform: scale(0);
            -webkit-transform: scale(0);
            -moz-transform: scale(0);
            -ms-transform: scale(0);
            -o-transform: scale(0);
            // transition: 1s ease;

            // -webkit-transition: 0.4s ease;
            // -moz-transition: 1s ease;
            // -ms-transition: 1s ease;
            // -o-transition: 1s ease;
            // transition-delay: 0.2s;
          }

          .moon {
            // transition: 0.4s ease;
            // -webkit-transition: 0.4s ease;
            // -moz-transition: 0.4s ease;
            // -ms-transition: 0.4s ease;
            // -o-transition: 0.4s ease;
            // transition-delay: 0.2s;

            path {
              fill: black;
            }
          }
        }

        .dark-mode-btn.active {
          .moon {
            transform: scale(0) rotate(150deg);
            -webkit-transform: scale(0) rotate(150deg);
            -moz-transform: scale(0) rotate(150deg);
            -ms-transform: scale(0) rotate(150deg);
            -o-transform: scale(0) rotate(150deg);
            // transition: 0.4s ease;
            // -webkit-transition: 0.4s ease;
            // -moz-transition: 0.4s ease;
            // -ms-transition: 0.4s ease;
            // -o-transition: 0.4s ease;
            // transition-delay: 0.2s;
          }

          .sun {
            transform: scale(1) rotate(360deg) translate(-50%, -50%);
            -webkit-transform: scale(1) rotate(360deg) translate(-50%, -50%);
            -moz-transform: scale(1) rotate(360deg) translate(-50%, -50%);
            -ms-transform: scale(1) rotate(360deg) translate(-50%, -50%);
            -o-transform: scale(1) rotate(360deg) translate(-50%, -50%);
            // transition: 0.4s ease;
            // -webkit-transition: 0.4s ease;
            // -moz-transition: 0.4s ease;
            // -ms-transition: 0.4s ease;
            // -o-transition: 0.4s ease;
            // transition-delay: 0.2s;
          }
        }
      }

      .login-signup-container {
        position: relative;
        border: none;

        button {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          padding: 8px 12px;
          overflow: hidden;
          cursor: pointer;
          background: var(--bodyback); // rgba(255, 255, 255, 0.4);
          border: none; // 1px solid var(--bodyback);
          border-radius: $secondary-border-radius;
          -webkit-border-radius: $secondary-border-radius;
          -moz-border-radius: $secondary-border-radius;
          -ms-border-radius: $secondary-border-radius;
          -o-border-radius: $secondary-border-radius;

          svg {
            width: 23.5px;
            height: 23.5px;
            max-height: 23.5px;
            padding: 4.5px;
            background: $sec-design-color;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;

            path {
              stroke: white;
              fill: white;
            }
          }

          p {
            font-size: 0.85rem;
            font-weight: 500;
            color: black;
            white-space: nowrap;
          }

          &:after {
            display: none;
            background: #fff;
            content: "";
            height: 155px;
            left: -75px;
            opacity: 0.5; // 0.2;
            position: absolute;
            top: -50px;
            width: 50px;
            // z-index: -10;
            transform: rotate(35deg);
            transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          }

          &:hover {
            // border-color: $sec-design-color;

            &:after {
              left: 120%;
              transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            }
          }
        }
      }

      .login-signup-container.active {
        button {
          border-color: $sec-design-color;
        }

        .login-signup-dropdown {
          display: block;
        }
      }

      .user-head-container.active {
        button {
          background: var(--bodyback);
          //border-color: $sec-design-color;
          // box-shadow: var(--shadow);

          svg.user-arrow {
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);

            path {
              stroke: $search-font-color;
            }
          }

          // a.user-fullname {
          //   color: $sec-design-color;
          // }
        }

        .user-dropdown-container {
          display: block;
        }
      }

      .user-head-container {
        position: relative;
        display: none;
        background: transparent;
        border: none;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;

        button {
          position: relative;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
          width: 100%;
          padding: 9px 15px;
          overflow: hidden;
          border: none;
          background: var(--bodyback); // rgba(255, 255, 255, 0.5);
          cursor: pointer;
          border: none; // 1px solid var(--bodyback);
          border-radius: inherit;
          -webkit-border-radius: inherit;
          -moz-border-radius: inherit;
          -ms-border-radius: inherit;
          -o-border-radius: inherit;

          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;

          &:after {
            display: none;
            background: #fff;
            content: "";
            height: 155px;
            left: -75px;
            opacity: 0.5; // 0.2;
            position: absolute;
            top: -50px;
            width: 50px;
            // z-index: -10;
            transform: rotate(35deg);
            transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          }

          &:hover {
            // border-color: $sec-design-color;

            // a.user-fullname {
            //   color: $sec-design-color-2;
            // }

            &:after {
              left: 120%;
              transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            }
          }

          svg.logedin-user {
            display: none;
            width: 17px;
            max-height: 17px;

            path {
              // stroke: transparent !important;
              fill: rgb(46, 46, 46);
            }
          }

          .user-profile-first-letters {
            // display: flex !important;
            // justify-content: center;
            // align-items: center;
            width: 18px;
            height: 18px;
            font-size: 0.7rem;
            text-align: center !important;
            // font-weight: 600;
            color: white;
            // padding: 4px;
            background: $sec-design-color;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }

          a.user-fullname {
            color: black;
            max-width: 5em;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 0.9rem;
            font-weight: 500;

            span {
              color: black;
            }
          }

          .user-img-box {
            display: none;
            width: 19px;
            height: 19px;

            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
              object-fit: cover;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
            }
          }

          svg.user-arrow {
            display: none;
            width: 10px;
            max-height: 14px;
            transition: all 0.4s;
            -webkit-transition: all 0.4s;
            -moz-transition: all 0.4s;
            -ms-transition: all 0.4s;
            -o-transition: all 0.4s;

            path {
              stroke: darkgrey;
            }
          }
        }

        .user-dropdown-container {
          position: absolute;
          top: 100%;
          left: 0;
          display: none;
          width: 240px;
          margin-top: 10px;
          text-align: left;
          // overflow: hidden;
          background: $primary-bg;
          border: none;
          border-radius: $secondary-border-radius;
          -webkit-border-radius: $secondary-border-radius;
          -moz-border-radius: $secondary-border-radius;
          -ms-border-radius: $secondary-border-radius;
          -o-border-radius: $secondary-border-radius;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

          .user-info-mini-container {
            display: flex;
            justify-content: flex-start !important;
            align-items: center;
            padding: 7px 21px;
            cursor: default;
            border: none;
            // border-bottom: 2px solid grey;

            a {
              font-size: $secondary-fontsize;
              text-decoration: none;
              text-align: left;
              white-space: nowrap;
            }

            a.user-fullname {
              width: 10rem;
              font-size: 0.85rem;
              font-weight: normal !important;
              text-decoration: none;
              text-align: left;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              margin: 0 7px 0 7px;
              line-height: 1.7;
              cursor: default;

              span {
                font-size: 0.7rem;
                color: grey;
              }
            }

            img {
              width: 2.5rem;
              height: 2.5rem;
              object-fit: cover;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
            }
          }

          hr {
            width: 100%;
            padding: 0;
            margin: 0 auto 7px auto;
            background: var(--bodyback-grey);
            border: 1px solid var(--bodyback-grey);
            border-radius: $secondary-border-radius;
            -webkit-border-radius: $secondary-border-radius;
            -moz-border-radius: $secondary-border-radius;
            -ms-border-radius: $secondary-border-radius;
            -o-border-radius: $secondary-border-radius;
          }

          .hr-2 {
            width: 100%;
            padding: 0;
            margin: 7px auto auto auto;
            background: var(--bodyback-grey);
            border: 1px solid var(--bodyback-grey);
            border-radius: $secondary-border-radius;
            -webkit-border-radius: $secondary-border-radius;
            -moz-border-radius: $secondary-border-radius;
            -ms-border-radius: $secondary-border-radius;
            -o-border-radius: $secondary-border-radius;
          }

          ul li {
            list-style: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 14px;

            &:hover {
              background: var(--bodyback-grey);
            }

            a {
              width: 100%;
              font-size: 0.78rem !important;
              font-weight: normal !important;
              padding: 11px;
            }
          }
        }

        .user-dropdown-container::after {
          display: none;
          content: "";
          position: absolute;
          top: -5px;
          left: 50px;
          // z-index: -1;
          width: 10px;
          height: 10px;
          background: $primary-bg;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);

          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }
      } /****END USER HEAD CONTAINER****/

      .modal-search-button {
        display: none;
        position: relative;
        padding: 21px;
        background: transparent;
        background-image: url(../../public/svg/search.svg);
        background-size: 1.4em;
        background-repeat: no-repeat;
        background-position: center;
        text-align: left;
        font-size: 0.8rem;
        font-weight: 500;
        margin-left: 5px;
        overflow: hidden;
        cursor: pointer;
        border: none;
        //border-radius: $secondary-border-radius;
        //-webkit-border-radius: $secondary-border-radius;
        //-moz-border-radius: $secondary-border-radius;
        //-ms-border-radius: $secondary-border-radius;
        //-o-border-radius: $secondary-border-radius;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;

        transition: 0s;
        -webkit-transition: 0s;
        -moz-transition: 0s;
        -ms-transition: 0s;
        -o-transition: 0s;

        &:after {
          background: #fff;
          content: "";
          height: 155px;
          left: -75px;
          opacity: 0.5; // 0.2;
          position: absolute;
          top: -50px;
          width: 50px;
          // z-index: -10;
          transform: rotate(35deg);
          transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:hover {
          background: $hover-color;

          &:after {
            left: 120%;
            transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          }
        }
      }

      .modal-search-button.active {
        background: $hover-color;
        background-image: url(../../public/svg/xmark.svg);
      }
    }
  }
}

.header-middle-parent-container.change {
  background: var(--bodyback);
}

.head-nav {
  display: none;
  width: 100%;
  background: transparent; // $sec-design-color;

  .head-nav-inside-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 1300px;
    margin: auto;
    // margin-top: 20px;
    margin-bottom: 40px;
    // padding: 5px 20px;
    padding: 0 55px;
    // overflow: hidden;
    background: $sec-design-color;
    border-radius: $third-border-radius;
    -webkit-border-radius: $third-border-radius;
    -moz-border-radius: $third-border-radius;
    -ms-border-radius: $third-border-radius;
    -o-border-radius: $third-border-radius;

    * {
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
    }

    a {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3px;
      // width: 120px;
      // height: 80px;
      // height: 100%;
      color: var(--background); // #9da4b3;
      text-decoration: none;
      font-size: 0.78rem;
      // font-weight: 600;
      // white-space: nowrap;
      padding: 17px 0;
      border: none;
      text-transform: capitalize;
      // border-bottom: 3px solid transparent;
      // background: white;
      // border-radius: $secondary-border-radius;
      // -webkit-border-radius: $secondary-border-radius;
      // -moz-border-radius: $secondary-border-radius;
      // -ms-border-radius: $secondary-border-radius;
      // -o-border-radius: $secondary-border-radius;

      &:hover {
        color: rgba(55, 55, 55, 1);
        background: transparent; // rgb(55, 55, 55, 1);
        border-bottom-color: white;

        // &::before,
        // &::after {
        //   display: block;
        // }
      }

      &::before,
      &::after {
        position: absolute;
        top: -12px;
        display: none;
        content: "";
        width: 17px;
        height: 17px;
        background: var(--background);
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
      }

      &::after {
        top: auto !important;
        bottom: -12px !important;
      }
    } // here end <a> menu

    div.institutions-dropdown-button-container {
      display: none;
      // position: relative;
      cursor: pointer;

      &:hover {
        // background: rgba(55, 55, 55, 1);

        .institutions-dropdown-button {
          background: transparent;
        }
      }

      .institutions-dropdown-button {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        gap: 9px;
        width: 100%;
        color: $primary-color;
        text-decoration: none;
        font-size: 0.83rem;
        white-space: nowrap;
        background: transparent;
        border: none;
        //padding: 17px 0;
        padding: 3px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;

        svg {
          position: relative;
          top: 1.1px;
          display: none;
          width: 9px;
          height: 9px;

          path {
            stroke: #2e2e2e;
          }
        }

        svg.more-svg {
          position: relative;
          top: 0.4px;
          display: flex !important;
          width: auto;
          height: 25px !important;
          max-height: 25px;

          g {
            fill: rgba(55, 55, 55, 1);
          }
        }

        &::before,
        &::after {
          display: none !important;
        }
      }

      .institutions-dropdown-container {
        position: absolute;
        // top: 100%;
        right: 0;
        z-index: 1000;
        display: none;
        width: 100%;
        background: $third-color;
        border: none;
        padding: 7px 53px;
        margin-top: 11px;
        overflow: hidden;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        // border-radius: $secondary-border-radius;
        // -webkit-border-radius: $secondary-border-radius;
        // -moz-border-radius: $secondary-border-radius;
        // -ms-border-radius: $secondary-border-radius;
        // -o-border-radius: $secondary-border-radius;

        a {
          display: inline-block;
          text-align: left;
          font-size: 0.8rem;
          color: black;
          padding: 10.5px 21px;
          border: none;

          &:hover {
            color: $sec-design-color;
            // background: rgba(55, 55, 55, 1);
          }

          &::before,
          &::after {
            display: none;
          }
        }
      }
    }

    div.institutions-dropdown-button-container.active {
      // background: rgba(55, 55, 55, 1);

      .institutions-dropdown-button {
        background: var(--background);

        svg {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);

          path {
            stroke: white;
          }
        }
      }

      .institutions-dropdown-container {
        display: block;
      }
    }

    .institutions-for-pad {
      display: none;
    }
  }
}
/****END HEAD NAVIGATION****/

.qr-tags-swiper {
  width: 100%;
  max-width: 1200px;
  height: 400px !important;

  --swiper-pagination-color: #f58500ff;
  --swiper-pagination-bullet-inactive-color: rgba(77, 76, 76, 0.3);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bullet-horizontal-gap: 7px;
  // --swiper-pagination-bullet-border-radius: 2px;
  // --swiper-pagination-bullet-width: 35px;
  // --swiper-pagination-bullet-height: 7px;
  // --swiper-pagination-bullet-height: 7px;

  --swiper-pagination-top: 360px;
  --swiper-navigation-size: 25px;

  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  .swiper-pagination {
    display: none !important;
    justify-content: center;
    align-items: center;
    // background: red;
    height: auto;
    // padding: 0 80px;
    z-index: 1;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 380px;
    left: 37px;
    z-index: 15 !important;
    display: none; // flex;
    justify-content: center;
    align-items: center;
    background: rgba(77, 76, 76, 0.3);
    background-image: url("../../public/svg/right-dark-mode.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    padding: 14px;
    color: transparent !important;
    fill: transparent !important;
    stroke: transparent !important;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;

    &:hover {
      background: rgba(77, 76, 76, 0.6);
      background-image: url("../../public/svg/right-dark-mode.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px;
    }
  }

  .swiper-button-prev {
    left: 5px;
    background-image: url("../../public/svg/left-dark-mode.svg");

    &:hover {
      background: rgba(77, 76, 76, 0.6);
      background-image: url("../../public/svg/left-dark-mode.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px;
    }
  }

  span.swiper-notification {
    display: none;
  }
}

.qr-tags-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 400px !important;
  height: 100%;
  margin: 10px auto;
  // background: rgba(0, 0, 0, 0.45);
  // background: #ccdde5; // #fbebdb;
  background: #f3e5dc;
  overflow: hidden;
  // background-image: url("../../public/img/colorful.avif");
  // background-image: url("../../public/img/tag-ban.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  //background-attachment: fixed;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  .overlay-2 {
    .text-for-qr-tags-container {
      .right-face-img {
        // width: 260px !important;
        // position: absolute;
        // top: 0;
        // left: 0;
        // z-index: -1;
        // width: 100% !important;
      }
    }
  }

  .overlay-3 {
    .text-for-qr-tags-container {
      .right-face-img {
        width: auto !important;
      }
    }
  }

  .qr-tags-container-overlay {
    width: 100%;
    height: 100%;
    // max-width: 1200px;
    margin: auto;
    // background: rgba(0, 0, 0, 0.2);
    // background-image: url("../../public/img/Untitled-11.png");
    // background-position: center right 80px;
    // background-repeat: no-repeat;
    // background-size: 400px;
    border-radius: inherit;
    -webkit-border-radius: inherit;
    -moz-border-radius: inherit;
    -ms-border-radius: inherit;
    -o-border-radius: inherit;

    .text-for-qr-tags-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // width: 100%;
      height: 100%;
      margin: auto;
      padding: 45px;
      text-align: left;
      // padding: 0 12px;
      // background-image: url("../../public/img/qr-tag-back5edit.png");
      // background-position: right bottom;
      // background-repeat: no-repeat;
      // background-size: contain;
      // border: 1px solid red;

      .right-face-img {
        //display: none;
        // width: 350px;
        // width: 50%;
        height: 100%;
        max-width: 50%;
        max-height: 100%;
        object-fit: cover;
        // border: 1px solid red;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;
      }

      .qr-tag-main-text {
        font-family: "Mark GEO CAPS Medium";
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 50%;
        // border: 1px solid red;

        p {
          font-size: 2rem; // 2.3rem;
          font-weight: 700;
          text-align: left;
          line-height: 1.3;
          color: black !important;

          .priority {
            color: black !important;
            font-size: 2rem;
            font-weight: 700;
          }
        }

        .dont_lose_frinds {
          display: none;
          color: white;
          font-size: 1.5rem;
          margin: 15px 0 25px 0;
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          color: white;
          font-size: 0.75rem;
          text-align: center;
          text-decoration: none;
          letter-spacing: 1px;
          padding: 15px 20px;
          margin-top: 20px;
          margin-right: 14px;
          // background: rgb(29, 29, 29);
          background: $sec-design-color;
          border-radius: $primary-border-radius;
          -webkit-border-radius: $primary-border-radius;
          -moz-border-radius: $primary-border-radius;
          -ms-border-radius: $primary-border-radius;
          -o-border-radius: $primary-border-radius;

          &:hover {
            opacity: 0.9;
          }
        }

        .pethub_app_text {
          font-family: "Mark GEO Medium";
          font-size: 0.9rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: normal;
          // color: grey !important;
        }

        .app-download {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 20px;
          margin-top: 21px;
          height: 45px;

          img {
            height: 100%;
          }
        }

        .join-us {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 15px;
          width: fit-content;
          color: white;
          font-size: 0.8rem;
          text-align: center;
          text-decoration: none;
          letter-spacing: 1px;
          padding: 15px 20px;
          margin-top: 20px;
          margin-right: 14px;
          // background: rgb(29, 29, 29);
          background: $sec-design-color;
          border-radius: $primary-border-radius;
          -webkit-border-radius: $primary-border-radius;
          -moz-border-radius: $primary-border-radius;
          -ms-border-radius: $primary-border-radius;
          -o-border-radius: $primary-border-radius;

          &:hover {
            color: $sec-design-color;
            background: rgba(245, 133, 0, 0.03);
            // background-image: url(../../public/img/gg.jpg);
            // background-size: cover;
            // background-repeat: no-repeat;
            // background-position: center;

            svg path {
              fill: $sec-design-color;
              stroke: $sec-design-color;
            }
          }

          svg {
            display: none;
            width: 40px;
            height: 40px;

            path {
              fill: white;
              stroke: white;
            }
          }
        }
      }
    }
  }
}

.members-and-tags-counter-container {
  // display: flex;
  // justify-content: space-around;
  // align-items: center;
  font-family: "Mark GEO CAPS Medium";
  display: none; // grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 40px auto 4px auto;
  // background: white;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    padding: 10px;
    padding-top: 17px;
    background: white;
    // border: 1px solid lightgrey;
    border-radius: inherit;
    -webkit-border-radius: inherit;
    -moz-border-radius: inherit;
    -ms-border-radius: inherit;
    -o-border-radius: inherit;

    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    span {
      // font-size: 0.9rem;
      font-weight: 600;

      &:first-child {
        font-size: 0.85rem;
        letter-spacing: 1px;
      }

      &:nth-child(2) {
        color: $sec-design-color;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 1px;
      }
    }
  }
}

/****START HEAD BANNER****/
.head-banner {
  // display: none; // dont need display
  width: 100%;
  margin: 0 auto 0 auto;
  padding: 0;
  text-align: center;
  overflow: hidden;
  background: $back-new-color;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  .mySlides {
    display: none;
    width: 100%;
    margin: auto;
    height: 10em;
  }

  img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    object-fit: cover;
    cursor: pointer;
    // border-radius: $secondary-border-radius;
    // -webkit-border-radius: $secondary-border-radius;
    // -moz-border-radius: $secondary-border-radius;
    // -ms-border-radius: $secondary-border-radius;
    // -o-border-radius: $secondary-border-radius;
  }

  /* Slideshow container */
  .slideshow-container {
    position: relative;
    margin: auto;
  }

  .active {
    background: #717171;
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;
  }

  /* Fading animation */
  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  /* On smaller screens, decrease text size */
  @media only screen and (max-width: 300px) {
    .text {
      font-size: 11px;
    }
  }

  .active {
    display: block !important;
  }
}
/****END HEAD BANNER****/

.parent-container-long {
  width: 100%;
  margin: auto;
}

.parent-container-short {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.footer-parent-container {
  width: 100%;
  max-width: 1200px !important;
  margin: auto !important;
  // background: var(--bodyback-grey); // rgba(239, 245, 239, 1);
  // background-image: linear-gradient(223deg, #3c414d, #353a44);
  // background: rgba(0, 0, 0, 0.45);
  // background: #04bcf7;
  // background: white;
  // border-radius: $secondary-border-radius;
  // -webkit-border-radius: $secondary-border-radius;
  // -moz-border-radius: $secondary-border-radius;
  // -ms-border-radius: $secondary-border-radius;
  // -o-border-radius: $secondary-border-radius;
  border-top: 1px solid $hover-color;
  border-bottom: 1px solid $hover-color;

  .footer-parent-inside-container {
    width: 100%;
    max-width: 1200px !important;
    margin: 0 auto !important;
    // padding: 0 40px;
    // background: white;
    // border-radius: $secondary-border-radius;
    // -webkit-border-radius: $secondary-border-radius;
    // -moz-border-radius: $secondary-border-radius;
    // -ms-border-radius: $secondary-border-radius;
    // -o-border-radius: $secondary-border-radius;
  }
}

/****START MIDDLE CONTAINER****/
.header-top-container {
  display: none;
  // position: -webkit-sticky;
  // position: sticky;
  // top: 0;
  // left: 0;
  width: 100%;
  padding: 5px;
  background: var(--bodyback);
  z-index: 1000;
  border: none;

  // * {
  //   color: white;

  //   svg path {
  //     fill: white !important;
  //   }
  // }

  .header-top-inside-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0 5px;

    .header-left-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;

      .social-icons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;

        svg {
          width: 13px;
          max-height: 14px;

          path {
            fill: rgba(46, 46, 46, 1);
          }

          &:hover {
            path {
              fill: $sec-design-color;
            }
          }
        }
      }

      .top-aboutus {
        display: none;
        text-decoration: none;
        font-size: 0.7rem;
        padding: 0 14px;
        border: none;
        border-left: 2px solid rgba(46, 46, 46, 1);
        border-right: 2px solid rgba(46, 46, 46, 1);

        &:hover {
          color: $sec-design-color;
        }
      }

      .top-order-collar {
        color: $sec-design-color;
        text-decoration: none;
        font-size: 0.7rem;
        padding: 0 14px;
        border: none;
        border-left: 2px solid rgba(46, 46, 46, 1);
        border-right: 2px solid rgba(46, 46, 46, 1);

        &:hover {
          color: $sec-design-color;
        }
      }
    }

    .header-right-info-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 40px;

      p {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 7px;
        font-size: 0.8rem;

        svg {
          width: 17px;
          max-height: 17px;

          path {
            stroke: rgba(46, 46, 46, 1);
          }
        }
      }
    }
  }
}
/****END MIDDLE CONTAINER****/

/****START QR-COLLAR ORDER BUTTONS****/
.qr-collar-order-buttons-container-for-home {
  display: none !important;
  justify-content: flex-start;
  align-items: center;
  gap: 28px;
  width: 100%;
  padding: 0;
  margin: 35px auto 0 auto;

  .order-buttons-inside-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 28px;
    width: fit-content;

    button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 14px;
      width: 100%;
      padding: 10.5px 14px;
      font-size: $secondary-fontsize;
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      // background: rgba(75, 75, 75, 0.1);
      border: none;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      &:after {
        background: #fff;
        content: "";
        height: 155px;
        left: -75px;
        opacity: 0.5; // 0.2;
        position: absolute;
        top: -50px;
        width: 50px;
        // z-index: -10;
        transform: rotate(35deg);
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &:hover {
        background: $hover-color;

        &:after {
          left: 120%;
          transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }

      svg {
        width: 21px;
        height: 21px;
      }
    }

    a {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 14px;
      width: 100%;
      padding: 10.5px 14px;
      font-size: $secondary-fontsize;
      color: white;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      background: rgba(55, 55, 55, 1);
      border: none;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      &:after {
        background: #fff;
        content: "";
        height: 155px;
        left: -75px;
        opacity: 0.25;
        position: absolute;
        top: -50px;
        width: 50px;
        // z-index: -10;
        transform: rotate(35deg);
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &:hover {
        &:after {
          left: 120%;
          transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }

      ion-icon {
        color: white;
        font-size: 1rem;
      }
    }

    a.order-collars-page-links {
      color: black;
      background: rgba(75, 75, 75, 0.1);

      svg {
        width: 21px;
        height: 21px;
      }

      &:after {
        background: #fff;
        content: "";
        height: 155px;
        left: -75px;
        opacity: 0.5; // 0.2;
        position: absolute;
        top: -50px;
        width: 50px;
        // z-index: -10;
        transform: rotate(35deg);
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &:hover {
        background: $hover-color;

        &:after {
          left: 120%;
          transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }
    }
  }

  .search-box-and-currency {
    display: none; // flex;
    justify-content: flex-end;
    align-items: center;
    gap: 14px;
    width: fit-content;

    .search-box {
      width: 100%;
      // overflow: hidden;
      background: $third-color;
      background-image: url(../../public/svg/search.svg);
      background-size: 2em;
      background-repeat: no-repeat;
      background-position: 15px center;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;
      border: 1px solid white;

      &:hover {
        width: 100%;
      }

      input[type="search"] {
        width: 100%;
        padding: 10.5px 49px;
        font-size: $filter-fontsize;
        outline: none;
        background: transparent;
        border: none;

        &:hover,
        &:focus,
        &:active {
          background: transparent;
        }

        &::placeholder {
          font-size: $filter-fontsize;
        }
      }

      input[type="submit"] {
        display: none;
        width: 12%;
        padding: 1.2em;
        border: none;
        border-left: none;
        border-top-right-radius: $secondary-border-radius;
        border-bottom-right-radius: $secondary-border-radius;
        color: transparent;
        cursor: pointer;
        background: transparent;
        background-image: url(../../public/svg/search.svg);
        background-size: 2em;
        background-repeat: no-repeat;
        background-position: center center;
        float: right;
      }
    } /****END SEARCH BOX****/
  }
}
/****END QR-COLLAR ORDER BUTTONS****/

/****START SITE MAIN FILTER CONTAINER****/
.site-main-filtre-out-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto 50px auto;
  background: transparent;
  // background-image: url("../../public/img/qr-tag-back6.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  // background-attachment: fixed;

  * {
    font-size: 0.8rem !important;
  }

  .site-main-filter-single-search-container {
    position: relative;
    width: 100%;
    max-width: 1200px;
    background: var(--bodyback);
    padding: 20px;
    // backdrop-filter: blur(2px);
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;

    .pets-button-in-filter-container {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      margin: 0 0 23px 0;
      padding-bottom: 25px;
      border-bottom: 2px solid var(--bodyback-grey);

      * {
        border-radius: $secondary-border-radius !important;
        -webkit-border-radius: $secondary-border-radius !important;
        -moz-border-radius: $secondary-border-radius !important;
        -ms-border-radius: $secondary-border-radius !important;
        -o-border-radius: $secondary-border-radius !important;
      }

      .pets-button-container {
        .mid-border {
          border-left: 2px solid var(--bodyback) !important;
          border-right: 2px solid var(--bodyback) !important;
        }

        .pets-button {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 14px;
          cursor: pointer;
          padding: 13px 20px;
          font-size: $filter-fontsize;
          border: none;
          background: var(--bodyback-grey);
          box-shadow: none !important;
          border-radius: 0 !important;
          -webkit-border-radius: 0 !important;
          -moz-border-radius: 0 !important;
          -ms-border-radius: 0 !important;
          -o-border-radius: 0 !important;

          &:hover {
            background: $sec-design-color;
            border-color: $sec-design-color;
          }

          svg {
            width: 17px;
            max-height: 17px;
            border-radius: 0 !important;
            -webkit-border-radius: 0 !important;
            -moz-border-radius: 0 !important;
            -ms-border-radius: 0 !important;
            -o-border-radius: 0 !important;

            path {
              fill: rgb(84, 84, 84);
            }
          }
        }
      }

      .pets-button-container.active {
        .pets-button {
          background: $sec-design-color;
          border-color: $sec-design-color;

          span {
            color: white;
          }

          svg path {
            fill: white;
          }
        }
      }

      .pets-documents {
        background: var(--bodyback-grey);
        // box-shadow: var(--shadow);

        .wrapper {
          .select-button {
            position: relative;
            display: none !important;
          }

          .select-menu-dropdown {
            position: relative;
            display: block;

            ul.options {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;

              label {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 7px;
                width: 100%;
                margin: 0;
                padding: 13px 20px;
                color: $primary-color;
                white-space: nowrap;
                cursor: pointer;
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;

                &:hover {
                  color: $sec-design-color;
                }

                input.search-checkbox {
                  appearance: none;
                  width: 16px;
                  height: 16px;
                  cursor: pointer;
                  background: var(--bodyback);
                  border: 1px solid --bodyback;
                  border-radius: 50px;
                  -webkit-border-radius: 50px;
                  -moz-border-radius: 50px;
                  -ms-border-radius: 50px;
                  -o-border-radius: 50px;

                  &:hover {
                    border-color: $sec-design-color;
                  }

                  &:checked {
                    background: var(--bodyback-grey);
                    // background-image: url(../../public/svg/check.svg);
                    // background-position: center center;
                    // background-repeat: no-repeat;
                    // background-size: cover;
                    border: 4px solid $sec-design-color;
                  }
                }
              }
            }
          }
        }

        .currency {
          display: none; // flex;
          justify-content: center;
          align-items: center;
          padding: 7px;
          box-shadow: var(--shadow);
          border: none;
          background: rgba(255, 255, 255, 0.7);
          border-radius: $third-border-radius;
          -webkit-border-radius: $third-border-radius;
          -moz-border-radius: $third-border-radius;
          -ms-border-radius: $third-border-radius;
          -o-border-radius: $third-border-radius;

          .currency-switch {
            cursor: pointer;
            padding: 3.5px 5px;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;

            svg {
              position: relative;
              top: 0.1px;
              height: 18px;

              path {
                fill: $search-font-color;
              }
            }
          }

          .active {
            background: $sec-design-color;

            svg {
              path {
                fill: black;
              }
            }
          }
        }
      }

      .clear-filters {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
        cursor: pointer;
        padding: 11px;
        white-space: nowrap;
        font-size: $secondary-fontsize;
        // box-shadow: var(--shadow);
        border: none;
        background: var(--bodyback-grey);
        border-radius: $third-border-radius;
        -webkit-border-radius: $third-border-radius;
        -moz-border-radius: $third-border-radius;
        -ms-border-radius: $third-border-radius;
        -o-border-radius: $third-border-radius;

        &:hover {
          background: $sec-design-color;
        }

        svg {
          width: 21px;
          max-height: 19px;
        }
      }
    }

    .site-main-filtre {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      row-gap: 30px;
      width: 100%;
      border: none;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      .wrapper {
        position: relative;
        width: 100%;
        background: transparent;

        .select-button {
          position: relative;
          width: 100%;
          display: flex !important;
          justify-content: space-between;
          align-items: center;
          padding: 15px 20px;
          cursor: pointer;
          background: var(--bodyback-grey) !important;
          // box-shadow: var(--shadow);
          border-radius: $secondary-border-radius;
          -webkit-border-radius: $secondary-border-radius;
          -moz-border-radius: $secondary-border-radius;
          -ms-border-radius: $secondary-border-radius;
          -o-border-radius: $secondary-border-radius;

          div {
            color: $primary-color;
            font-size: $filter-fontsize;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 210px;
          }

          svg {
            width: 11px;
            // max-height: 20px;

            path {
              stroke: grey;
            }
          }
        }

        .select-menu-dropdown {
          position: absolute;
          display: none;
          width: 100%;
          background: var(--bodyback);
          padding: 10px;
          margin-top: 10px;
          z-index: 1000;
          overflow: hidden;
          border-radius: $secondary-border-radius;
          -webkit-border-radius: $secondary-border-radius;
          -moz-border-radius: $secondary-border-radius;
          -ms-border-radius: $secondary-border-radius;
          -o-border-radius: $secondary-border-radius;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

          .filter-default-text {
            width: 100%;
            text-align: center;
            padding: 30px;
          }

          .select-search {
            width: 100%;

            input {
              width: 100%;
              padding: 12px 40px;
              outline: none;
              background: white;
              background-image: url(../../public/svg/search.svg);
              background-repeat: no-repeat;
              background-position: 15px center !important;
              background-size: 17px;
              text-align: left;
              border: 1px solid lightgrey;
              border-radius: $secondary-border-radius;
              -webkit-border-radius: $secondary-border-radius;
              -moz-border-radius: $secondary-border-radius;
              -ms-border-radius: $secondary-border-radius;
              -o-border-radius: $secondary-border-radius;
            }
          }

          ul.options {
            max-height: 250px;
            background: transparent !important;
            margin: 10px 0 0 0;
            list-style: none;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 7px;
            }

            &::-webkit-scrollbar-track {
              background: $primary-bg;
              border-radius: $primary-border-radius;
              -webkit-border-radius: $primary-border-radius;
              -moz-border-radius: $primary-border-radius;
              -ms-border-radius: $primary-border-radius;
              -o-border-radius: $primary-border-radius;
            }

            &::-webkit-scrollbar-thumb {
              background: $sec-design-color;
              border-radius: $primary-border-radius;
              -webkit-border-radius: $primary-border-radius;
              -moz-border-radius: $primary-border-radius;
              -ms-border-radius: $primary-border-radius;
              -o-border-radius: $primary-border-radius;
            }

            label {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 10px;
              width: 100%;
              margin: 0;
              padding: 11px 10px;
              color: black;
              font-size: $filter-fontsize;
              cursor: pointer;
              border-radius: $secondary-border-radius;
              -webkit-border-radius: $secondary-border-radius;
              -moz-border-radius: $secondary-border-radius;
              -ms-border-radius: $secondary-border-radius;
              -o-border-radius: $secondary-border-radius;

              &:hover {
                background: $hover-color;
              }

              input.search-checkbox {
                appearance: none;
                width: 14px;
                height: 14px;
                cursor: pointer;
                background: white;
                border: 1px solid $last-color;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;

                &:checked {
                  // background: $sec-design-color;
                  // background-image: url(../../public/svg/check.svg);
                  // background-position: center center;
                  // background-repeat: no-repeat;
                  // background-size: cover;
                  border: 4px solid $sec-design-color;
                }
              }
            }
          }
        }
      }

      .select-button.active {
        // border-color: $border-primary-color;

        svg {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
        }

        .select-menu-dropdown {
          display: block;
        }
      }

      .filter-price {
        display: flex;
        justify-content: flex-start;
        gap: 0;
        width: 100%;

        input[type="text"] {
          width: 50%;
          padding: 15px 20px;
          font-size: $filter-fontsize;
          text-align: left;
          outline: none;
          background: var(--bodyback-grey);
          // box-shadow: var(--shadow);
          border: none;
          border-right: 2px solid var(--bodyback);
          border-radius: $secondary-border-radius;
          -webkit-border-radius: $secondary-border-radius;
          -moz-border-radius: $secondary-border-radius;
          -ms-border-radius: $secondary-border-radius;
          -o-border-radius: $secondary-border-radius;

          &::placeholder {
            color: $primary-color;
          }
        }

        input.from[type="text"] {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        input.to[type="text"] {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-right: none;
        }
      }

      .filter-search-button-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 15px 20px;
        opacity: 0.8;
        background: $sec-design-color;
        // box-shadow: var(--shadow);
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;

        &:hover {
          opacity: 1;
        }

        button.filtre-search-button {
          gap: 10.5px;
          width: 100%;
          color: black !important;
          background: transparent;
          // background-image: url(../../public/svg/search.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 20%;
          cursor: pointer;
          border: none;
          border-radius: inherit;
          -webkit-border-radius: inherit;
          -moz-border-radius: inherit;
          -ms-border-radius: inherit;
          -o-border-radius: inherit;
        }
      }
    }

    .contact-details-outside-container {
      display: none !important;
      margin: 49px 0 21px 0;

      .contact-details-inside-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 14px;
        width: fit-content;
        margin: auto;
        background: rgba(255, 255, 255, 0.8);
        border-radius: $third-border-radius;
        -webkit-border-radius: $third-border-radius;
        -moz-border-radius: $third-border-radius;
        -ms-border-radius: $third-border-radius;
        -o-border-radius: $third-border-radius;

        span {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 7px;
          white-space: nowrap;
          // color: $third-color;
          font-size: 0.8rem;
          padding: 10.5px 14px;

          svg {
            width: 17.5px;
            height: 17.5px;
          }
        }
      }
    }
  }
}
/****END SITE MAIN FILTER CONTAINER****/

/****START SITE MAIN FILTER CONTAINER-2****/
.filter-modal-version {
  position: absolute;
  top: 25%;
  left: 50%;
  display: flex;
  width: 80%;
  background: transparent !important;
  background-image: none;
  transform: translate(-50%, -25%);
  -webkit-transform: translate(-50%, -25%);
  -moz-transform: translate(-50%, -25%);
  -ms-transform: translate(-50%, -25%);
  -o-transform: translate(-50%, -25%);

  .no-hover {
    background: transparent !important;
  }

  .background-clear-filters {
    background: white !important;
    border-radius: $third-border-radius !important;
    -webkit-border-radius: $third-border-radius !important;
    -moz-border-radius: $third-border-radius !important;
    -ms-border-radius: $third-border-radius !important;
    -o-border-radius: $third-border-radius !important;
  }

  .modal-filter-border {
    border: 1px solid $hover-color !important;
    border-radius: $primary-border-radius;
    -webkit-border-radius: $primary-border-radius;
    -moz-border-radius: $primary-border-radius;
    -ms-border-radius: $primary-border-radius;
    -o-border-radius: $primary-border-radius;

    &:hover {
      background: $hover-color !important;
    }
  }

  .modal-filter-grid {
    grid-template-columns: repeat(4, 1fr) !important;
    gap: 28px;

    * {
      background: white !important;

      border-radius: $secondary-border-radius !important;
      -webkit-border-radius: $secondary-border-radius !important;
      -moz-border-radius: $secondary-border-radius !important;
      -ms-border-radius: $secondary-border-radius !important;
      -o-border-radius: $secondary-border-radius !important;
    }
  }
}
/****END SITE MAIN FILTER CONTAINER****/

/****START FILTER LEFT VERSION****/
.filter-left-version {
  position: -webkit-sticky;
  position: sticky;
  // top: 15%;
  width: 25%;

  .filter-left-version-inside-container {
    width: 100%;
    padding: 20px;
    border: none;
    background: $primary-bg;
    // border: 1px solid $last-color;
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;

    .pets-button-in-filter-container {
      position: relative;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      overflow: hidden;
      margin: auto;
      margin: 30px 0;
      border: none;
      background: var(--bodyback-grey);
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      .pets-button-container-for-left-version {
        width: 100%;

        .mid-border {
          border-left: 2px solid $primary-bg !important;
          border-right: 2px solid $primary-bg !important;
        }

        .pets-button {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          width: 100%;
          cursor: pointer;
          padding: 10px;
          font-size: $secondary-fontsize;
          overflow: hidden;
          border: none;
          background: transparent;
          // border-radius: $secondary-border-radius;
          // -webkit-border-radius: $secondary-border-radius;
          // -moz-border-radius: $secondary-border-radius;
          // -ms-border-radius: $secondary-border-radius;
          // -o-border-radius: $secondary-border-radius;

          &:after {
            background: #fff;
            content: "";
            height: 155px;
            left: -75px;
            opacity: 0.5; // 0.2;
            position: absolute;
            top: -50px;
            width: 50px;
            // z-index: -10;
            transform: rotate(35deg);
            transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          }

          &:hover {
            background: $hover-color;

            &:after {
              left: 120%;
              transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
            }
          }

          svg {
            display: none;
            width: 17px;
            max-height: 17px;

            path {
              fill: rgb(84, 84, 84);
            }
          }

          span {
            font-size: 0.8rem;
          }
        }
      }

      .pets-button-container-for-left-version.active {
        .pets-button {
          background: $hover-color;
        }
      }
    }

    .clear-filters-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .clear-filters {
        display: flex; // flex
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        padding: 12px 20px;
        white-space: nowrap;
        border: none;
        background: var(--bodyback-grey);
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;

        &:hover {
          background: $hover-color;
        }

        svg {
          width: 18px;
          max-height: 18px;
        }

        p {
          white-space: nowrap;
          font-size: 0.8rem;
        }
      }
    }

    .search-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0;
      margin: 30px auto;
      overflow: hidden;
      background: var(--bodyback-grey);
      border-radius: $secondary-border-radius;
      border: none;

      &:hover {
        background: $hover-color;
      }

      input[type="search"] {
        width: 80%;
        padding: 10px 20px;
        font-size: 0.8rem;
        border: none;
        outline: none;
        background: transparent;

        &:hover,
        &:focus,
        &:active {
          background: transparent;
        }

        &::placeholder {
          font-size: 0.8rem;
        }
      }

      input[type="submit"] {
        width: 20%;
        padding: 10px;
        border: none;
        cursor: pointer;
        color: transparent;
        background: transparent;
        background-image: url(../../public/svg/search.svg);
        background-size: 17px;
        background-repeat: no-repeat;
        background-position: center center;
      }
    } /****END SEARCH BOX****/

    .site-main-filtre {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      width: 100%;
      padding: 0;
      border: none;

      .wrapper {
        position: relative;
        width: 100%;
        background: transparent;
        // border: 1px solid red;

        .select-button {
          position: relative;
          width: 100%;
          display: flex !important;
          justify-content: space-between;
          align-items: center;
          padding: 15px 5px;
          cursor: pointer;
          background: transparent;
          border: none;
          border-bottom: 1px solid $last-color;
          // border-radius: $secondary-border-radius;
          // -webkit-border-radius: $secondary-border-radius;
          // -moz-border-radius: $secondary-border-radius;
          // -ms-border-radius: $secondary-border-radius;
          // -o-border-radius: $secondary-border-radius;

          &:hover {
            border-color: $border-secondary-color;
          }

          div {
            color: $primary-color;
            font-size: 0.8rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 90%;
          }

          svg {
            // display: none;
            width: 12px;

            path {
              stroke: grey;
            }
          }
        }

        .select-menu-dropdown {
          position: relative;
          display: block; // none; - for dropdown
          width: 100%;
          background: transparent;
          padding: 0;

          .filter-default-text {
            width: 100%;
            font-size: 0.85rem;
            text-align: center;
            padding: 35px;
          }

          .select-search {
            width: 100%;

            input {
              width: 100%;
              padding: 8px 40px;
              outline: none;
              background: $hover-color;
              background-image: url(../../public/svg/search.svg);
              background-repeat: no-repeat;
              background-position: 15px center;
              background-size: 15px;
              text-align: left;
              border: 1px solid transparent;

              &::placeholder {
                font-size: 0.8rem;
              }
            }
          }

          ul.options {
            max-height: 250px;
            background: transparent !important;
            margin: 0;
            margin-top: 7px;
            list-style: none;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 7px;
            }

            &::-webkit-scrollbar-track {
              background: transparent;
              border-radius: $primary-border-radius;
              -webkit-border-radius: $primary-border-radius;
              -moz-border-radius: $primary-border-radius;
              -ms-border-radius: $primary-border-radius;
              -o-border-radius: $primary-border-radius;
            }

            &::-webkit-scrollbar-thumb {
              background: $sec-design-color;
              border-radius: $primary-border-radius;
              -webkit-border-radius: $primary-border-radius;
              -moz-border-radius: $primary-border-radius;
              -ms-border-radius: $primary-border-radius;
              -o-border-radius: $primary-border-radius;
            }

            label {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 7px;
              width: 95%;
              padding: 10px 7px;
              color: black;
              font-size: 0.8rem;
              cursor: pointer;
              border: none;
              // border-bottom: 1px solid $last-color;
              border-radius: $primary-border-radius;
              -webkit-border-radius: $primary-border-radius;
              -moz-border-radius: $primary-border-radius;
              -ms-border-radius: $primary-border-radius;
              -o-border-radius: $primary-border-radius;

              &:hover {
                // color: $third-color;
                background: $hover-color;
              }

              // &:active {
              //   color: $sec-design-color;
              // }

              input.search-checkbox {
                appearance: none;
                width: 14px;
                height: 14px;
                cursor: pointer;
                background: white;
                border: 1px solid $last-color;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;

                &:hover {
                  border-color: $third-color;
                }

                &:checked {
                  background: transparent;
                  // background-image: url(../../public/svg/check.svg);
                  background-position: center center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  border: 4px solid $sec-design-color;
                }
              }
            }
          }
        }
      }

      .select-button.active {
        border-color: $border-primary-color;

        svg {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
        }

        .select-menu-dropdown {
          display: block;
        }
      }

      .filter-price {
        display: flex;
        justify-content: flex-start;
        gap: 0;
        width: 100%;
        margin: auto;

        input[type="text"] {
          width: 49%;
          padding: 15px;
          font-size: 0.8rem;
          text-align: left;
          outline: none;
          margin: auto;
          background: transparent;
          border: none;
          border-bottom: 1px solid $last-color;

          &:hover {
            border-color: $border-secondary-color;
          }

          &::placeholder {
            color: $primary-color;
          }
        }

        input.from[type="text"] {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        input.to[type="text"] {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      button.filtre-search-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 12px 15px;
        margin: 20px 0;
        color: black;
        background: $sec-design-color;
        opacity: 0.9;
        cursor: pointer;
        font-size: 0.9rem;
        border: none;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;

        &:hover {
          opacity: 1;
          // background-image: url(../../public/svg/search2.svg);
        }
      }
    }
  }
}
/****END FILTER LEFT VERSION****/

.tags-card-container-for-homepage {
  display: none; // dont need
  width: 100%;
  max-width: 1200px;
  // background: #21827c; // rgba(145, 180, 203, 0.4);
  margin: 30px auto;
  overflow: hidden;
  text-align: center;
  // background-image: url("../../public/img/back13.webp");
  background: var(--bodyback);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  h3 {
    display: none;
    margin: 30px auto;
  }

  .title-description {
    display: none;
    width: 80%;
    margin: 0 auto 50px auto;
  }

  .tags-card-inside-container-for-homepage {
    width: 100%;
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    align-items: center;
    // gap: 35px;
    margin: auto;
    // padding: 30px 0;

    .tag-card-for-home-page {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      height: 100%;
      padding: 40px 28px;
      background: rgba(255, 255, 255, 1);
      // border-radius: $secondary-border-radius;
      // -webkit-border-radius: $secondary-border-radius;
      // -moz-border-radius: $secondary-border-radius;
      // -ms-border-radius: $secondary-border-radius;
      // -o-border-radius: $secondary-border-radius;

      img {
        // display: none;
        // position: absolute;
        // top: -32px;
        // width: 100px;
        height: 200px;
      }

      svg {
        display: none;
        width: 72px;
        height: 72px;

        rect {
          fill: $sec-design-color;
        }
      }

      .tag-card-title {
        font-family: "BPG Excelsior Caps DejaVu 2010";
        font-size: 0.9rem;
        font-weight: 600;
      }

      .tag-card-description-1,
      .tag-card-description-2 {
        width: 85%;
        text-align: center;
        font-size: 0.85rem;
      }

      // &:hover {
      //   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      // }
    }
  }
}

/****QR-AND-GPS CARDS CONTAINER****/
.qr-and-gps-tags {
  display: none;
  width: 100%;
  margin: 40px auto;
  // padding: 31px 51px;
  // background: white;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  .qr-and-gps-tags-inside-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    width: 100%;

    .tag-container {
      position: relative;
      display: flex;
      //flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      width: 100%;
      // height: 330px;
      // max-height: 330px;
      padding: 30px;
      cursor: pointer;
      background: var(--bodyback);
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;

      &:hover {
        background: rgba(0, 0, 0, 0.4);
        // transform: scale(1.05);
        // -webkit-transform: scale(1.05);
        // -moz-transform: scale(1.05);
        // -ms-transform: scale(1.05);
        // -o-transform: scale(1.05);

        .tag-container-overlay {
          opacity: 1; // 1;

          &::before {
            opacity: 1;
          }
        }

        img {
          transform: scale(1.08);
          -webkit-transform: scale(1.08);
          -moz-transform: scale(1.08);
          -ms-transform: scale(1.08);
          -o-transform: scale(1.08);
        }
      }

      .tag-container-overlay {
        opacity: 0; // 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        // flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 10px;
        width: 100%;
        height: 100%;
        padding: 30px;
        text-align: center;
        // background: rgba(0, 0, 0, 0.2);
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;

        p {
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          width: fit-content;
          color: white;
          font-size: 1rem;
          font-weight: 600;
          padding: 5px 15px 5px 7px;
          //letter-spacing: 1px;
          background: rgba(0, 0, 0, 0.3);
          border-radius: $third-border-radius;
          -webkit-border-radius: $third-border-radius;
          -moz-border-radius: $third-border-radius;
          -ms-border-radius: $third-border-radius;
          -o-border-radius: $third-border-radius;

          &::before {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            content: "";
            width: 30px;
            padding: 0;
            height: 30px;
            background: transparent;
            opacity: 1;
            background-image: url(../../public/svg/arrow-circle.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            transition: all 0.3s;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -ms-transition: all 0.3s;
            -o-transition: all 0.3s;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
      }
    }

    // .container-1 {
    //   background: white;
    //   transform: scale(0.9);
    //   -webkit-transform: scale(0.9);
    //   -moz-transform: scale(0.9);
    //   -ms-transform: scale(0.9);
    //   -o-transform: scale(0.9);
    // }

    // .container-2 {
    //   background: lightgrey;
    //   transform: scale(0.8);
    //   -webkit-transform: scale(0.8);
    //   -moz-transform: scale(0.8);
    //   -ms-transform: scale(0.8);
    //   -o-transform: scale(0.8);
    // }

    // .container-2:hover {
    //   transform: scale(0.9);
    //   -webkit-transform: scale(0.9);
    //   -moz-transform: scale(0.9);
    //   -ms-transform: scale(0.9);
    //   -o-transform: scale(0.9);
    // }

    // .container-2:hover ~ .container-1 {
    //   transform: scale(0.8);
    //   -webkit-transform: scale(0.8);
    //   -moz-transform: scale(0.8);
    //   -ms-transform: scale(0.8);
    //   -o-transform: scale(0.8);
    // }
  }
}
/****QR-AND-GPS CARDS CONTAINER****/

/****QR-TAG CARDS CONTAINER****/
.qr-tag-list-container {
  display: flex; // flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 100%;
  margin: 40px auto;
  // padding: 30px 20px;
  // background: var(--bodyback);
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  .qr-tag-list-container-title {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    display: none;
    gap: 50px;
    width: 100%;
    border: 1px solid black;

    p {
      font-size: 1rem;
      font-weight: 600;
      text-align: center;
      margin: 20px 0;
    }
  }

  .qr-tag-list-inside-container {
    position: relative;
    width: 100%;
    // margin: 40px auto;
    // overflow: hidden;

    .prev,
    .next {
      cursor: pointer;
      position: absolute;
      top: 45%;
      z-index: 2;
      display: none; // flex;
      justify-content: center;
      align-items: center;
      padding: 8px 2.5px;
      color: black;
      font-weight: bold;
      background: rgba(77, 76, 76, 0.3);
      // background: rgb(46, 46, 46);
      // background: var(--bodyback);
      // border: 1px solid black;
      user-select: none;
      -webkit-user-select: none;
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;

      transition: 0.3s;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -ms-transition: 0.3s;
      -o-transition: 0.3s;

      svg {
        height: 22px;
        max-height: 22px;

        path {
          fill: white;
        }
      }
    }

    /* Position the "next button" to the right */
    .prev {
      left: -60px;
    }

    .next {
      right: -60px;
    }

    /* On hover, add a black background color with a little bit see-through */
    .prev:hover,
    .next:hover {
      // background: rgba(245, 135, 0, 0.8);
      background: $primary-color;

      svg path {
        fill: white;
      }
    }
  }

  .qr-tag-list-container-view-all {
    text-align: center;

    a {
      display: inline-block;
      color: white;
      font-size: 0.8rem;
      font-weight: 500;
      text-decoration: none;
      padding: 10px 20px;
      margin-top: 20px;
      background: black;
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;

      &:hover {
        color: $sec-design-color;
      }
    }
  }
}
/****QR-TAG CARDS CONTAINER****/

.qr-tag-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 100%;
  max-height: 325px;
  // padding: 7px;
  // background: var(--bodyback);
  // padding: 7px;
  // background: var(--bodyback-grey);
  // padding: 14px;
  // cursor: pointer;
  // background: white;
  // border: 1px solid red;
  // border-radius: $primary-border-radius;
  // -webkit-border-radius: $primary-border-radius;
  // -moz-border-radius: $primary-border-radius;
  // -ms-border-radius: $primary-border-radius;
  // -o-border-radius: $primary-border-radius;

  &:hover {
    .qr-tag-card-imgbox {
      .front-img {
        display: none;
      }

      .back-img {
        display: block;
        object-fit: cover;
      }
    }
  }

  .qr-tag-card-imgbox {
    width: 100%;
    height: 200px;
    // padding: 20px;
    //background: white;
    // overflow: hidden;
    // border: 1px solid lightgray;
    overflow: hidden;
    background: var(--bodyback);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    //border: 1px solid red;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .front-img {
      display: block;
    }

    .back-img {
      display: none;
    }
  }

  .qr-tag-name {
    // display: none;
    font-size: 0.85rem;
  }

  .qr-tag-price {
    display: none;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    // color: white;
    padding: 3px 0;
    // background: $sec-design-color;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
}

/****CARDS SWIPER CONTAINER****/
.cards-swiper-container {
  display: grid;
  grid-auto-flow: column;
  width: 100% !important;
}
/****CARDS SWIPER CONTAINER****/

/****START MAIN CONTAINER****/
.main-container {
  display: flex;
  // flex-direction: row-reverse;
  width: 100%;
  margin: 10px auto;
  padding: 0;
  padding-bottom: 35px;
  background: transparent; // rgba(245, 245, 245, 1);

  // border-radius: $secondary-border-radius;
  // -webkit-border-radius: $secondary-border-radius;
  // -moz-border-radius: $secondary-border-radius;
  // -ms-border-radius: $secondary-border-radius;
  // -o-border-radius: $secondary-border-radius;

  .left-container {
    width: 100%;
    margin: 0;
    border: none;
    border-bottom-left-radius: $secondary-border-radius;

    .left-container-posts-header {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      width: 100%;
      padding: 0;
      margin: 0 0 14px 0;
      background: transparent;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        margin: 0 7px 0 0;
        background: $sec-design-color;
        border-radius: $primary-border-radius;
        -webkit-border-radius: $primary-border-radius;
        -moz-border-radius: $primary-border-radius;
        -ms-border-radius: $primary-border-radius;
        -o-border-radius: $primary-border-radius;

        svg.department-icon {
          width: 17px;
          max-height: 17px;
          margin: auto;

          path {
            fill: white;
          }
        }
      }

      // .dep-lost {
      //   background: #f87171;
      // }

      // .dep-found {
      //   background: #10b981;
      // }

      // .dep-last-post {
      //   background: #509af8;
      // }

      p {
        color: $primary-color;
        font-size: 1.1rem;
        letter-spacing: 1;
      }

      .next-prev {
        position: absolute;
        right: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 7px;

        svg {
          width: 35px;
          max-height: 35x;
          cursor: pointer;

          path {
            stroke: grey;
          }

          &:hover {
            path {
              stroke: rgb(48, 48, 48);
            }
          }
        }
      }
    }

    .vip-post-container {
      position: relative;
      width: 100%;
      margin: 0 0 35px 0;
      padding: 0;
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;

      &:hover {
        svg.prev-next {
          display: flex;
        }
      }

      .vip-post-container-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 14px;
        width: 100%;
        margin: 0 auto 14px auto;
        background: transparent;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          background: $sec-design-color;
          padding: 7px;
          border-radius: $primary-border-radius;
          -webkit-border-radius: $primary-border-radius;
          -moz-border-radius: $primary-border-radius;
          -ms-border-radius: $primary-border-radius;
          -o-border-radius: $primary-border-radius;

          svg {
            width: 17px;
            max-height: 17px;
            margin: auto;

            path {
              fill: white;
            }
          }
        }

        p {
          color: $primary-color;
          font-size: 1.1rem;
          font-weight: bold;
          letter-spacing: 3;
        }

        .next-prev {
          position: absolute;
          right: 7px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 7px;

          svg {
            width: 35px;
            max-height: 35x;
            cursor: pointer;

            path {
              stroke: grey;
            }

            &:hover {
              path {
                stroke: rgb(48, 48, 48);
              }
            }
          }
        }
      }

      .vip-inside-cards-container {
        position: relative;
        width: 100%;
        overflow: hidden;
      }
    }

    .missing-post-main-container {
      position: relative;
      width: 100%;
      padding: 7px 0;
      margin: 35px 0;
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;

      &:hover {
        svg.prev-next {
          display: flex;
        }
      }

      .missing-card-container {
        overflow: hidden;
      }
    }

    .founded-post-main-container {
      position: relative;
      width: 100%;
      padding: 7px 0;
      margin: 0 0 35px 0;
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;

      &:hover {
        svg.prev-next {
          display: flex;
        }
      }

      .founded-card-container {
        overflow: hidden;
      }
    }

    .recently-added-container {
      position: relative;
      width: 100%;
      margin: 35px 0;
      padding: 7px 0;
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;

      .recently-added-inside-container {
        width: 100%;

        .card {
          width: 100%;
        }
      }
    }
  }

  .right-container {
    display: none;
    width: 20%;
    padding-top: 42px;
    border-bottom-right-radius: $secondary-border-radius;

    .right-container-posts {
      display: flex;
      flex-direction: column;
      gap: 25px;
      width: 90%;
      margin: 7px auto;
      padding: 0 7px;
      border: none;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      .right-post-inside-container {
        position: sticky;

        .right-post-header {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 0;
          width: 100%;
          padding: 5.5px 7px;
          margin: 0 auto 14px auto;
          background: transparent;
          // border: 1px dotted $sec-design-color;
          border-radius: $secondary-border-radius;
          -webkit-border-radius: $secondary-border-radius;
          -moz-border-radius: $secondary-border-radius;
          -ms-border-radius: $secondary-border-radius;
          -o-border-radius: $secondary-border-radius;

          svg {
            display: none !important;
            width: 7.2%;
            margin: 0 14px 0 0;

            path {
              fill: $primary-color;
            }
          }

          p {
            color: $primary-color;
            font-size: 0.85rem;
            font-weight: 2000;
            letter-spacing: 2;
          }
        }

        .urgently-post {
          padding: 7px;
          font-size: $secondary-fontsize;
          text-align: left;
        }

        .donation-post {
          display: flex;
          flex-direction: column;
          gap: 14px;
          padding: 7px;

          p {
            font-size: $secondary-fontsize;
            text-align: left;
            // color: $search-font-color;
          }

          hr {
            width: 60%;
            margin: 14px 0;
            padding: 0.1vh;
            background: $sec-design-color;
            border: 1px solid $sec-design-color;
            border-radius: $secondary-border-radius;
            -webkit-border-radius: $secondary-border-radius;
            -moz-border-radius: $secondary-border-radius;
            -ms-border-radius: $secondary-border-radius;
            -o-border-radius: $secondary-border-radius;
          }
        }
      }
    }
  }
}
/****END MAIN CONTAINER****/

/****START QR-COLLAR ORDER AND INSTRUCTION CONTAINER****/

p.qr-collar-title {
  font-size: 1rem;
  font-weight: 600;
  margin: 50px 0;
  text-align: center;
  cursor: default;
}

.video-instruction {
  display: block;
  width: 100%;
  height: 550px;
  max-height: 550px;
  margin: 0 auto 70px auto;
  object-fit: cover;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;
}

.qr-collar-order-container {
  position: relative;
  top: 0;
  width: 100%;
  padding: 0;
  // margin: 40px auto 60px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  gap: 35px;
  margin: 30px auto;
  background: transparent !important;

  * {
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
  }

  .qr-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 21px;
    //width: 100%;
    height: 100%;
    text-align: center;
    padding: 21px;
    border: none;
    cursor: default;
    color: $primary-color;
    font-weight: $secondary-fontweight;
    background: var(--bodyback);
    // border: 1px solid var(--bodyback-grey);
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;

    &:hover {
      border-color: $sec-design-color;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .qr-collar-button {
        color: $sec-design-color;

        span {
          color: $sec-design-color;
        }
      }
    }

    img {
      display: none;
      width: 170px;
      // height: 130px;
      margin-top: -80px;
    }

    p {
      width: 100%;
      font-size: 0.9rem;
      font-weight: 600;

      span {
        font-size: 0.9rem;
        font-weight: 500;
        margin-right: 7px;
      }
    }

    ul {
      width: fit-content;
      height: 100%;
      list-style-type: none;
      border: none;
      border-top: 2px solid var(--bodyback-grey);
      border-bottom: 2px solid var(--bodyback-grey);
      // background: red;

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 11px;
        font-size: 0.83rem;
        font-weight: 500;
        text-align: left;
        margin: 21.5px 0;

        .check-list {
          width: 17px;
          height: 17px;
          background-image: url("../../public/svg/check-without-circle.svg");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }

    .qr-collar-button {
      width: 100%;
      text-align: center;
      font-size: 1rem;
      font-weight: 500;
      text-decoration: none;
      padding: 0 12px;
      background: transparent;
      border: none;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      span {
        margin-right: 7px;
      }
    }
  }
} /****END QR-COLLAR ORDER CARDS CONTAINER****/

.qr-collar-instruction-container {
  position: relative;
  top: 0;
  width: 100%;
  padding: 0;
  margin: 10px auto;
  background: $primary-bg;
  // background-image: url(../../public/img/ka1.png);
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  img {
    width: 100%;
    margin: auto;
  }
} /****END QR-COLLAR INSTRUCTION CONTAINER****/

/****END QR-COLLAR ORDER AND INSTRUCTION CONTAINER****/

/****START CARD****/
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 100%;
  // height: 348px;
  // padding: 14px;
  overflow: hidden;
  background: var(--bodyback);
  // border: 1px solid $hover-color;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  &:hover {
    // transform: translateY(-5px);
    // -webkit-transform: translateY(-5px);
    // -moz-transform: translateY(-5px);
    // -ms-transform: translateY(-5px);
    // -o-transform: translateY(-5px);

    .slide-img-box-for-postcard {
      img {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
      }
    }
  }

  .slide-img-box-for-postcard {
    position: relative;
    width: 100%;
    height: 270px; // 170px;
    margin: 0;
    overflow: hidden;
    border: none !important;
    // border-radius: $primary-border-radius;
    // -webkit-border-radius: $primary-border-radius;
    // -moz-border-radius: $primary-border-radius;
    // -ms-border-radius: $primary-border-radius;
    // -o-border-radius: $primary-border-radius;

    &:hover {
      .dots-container {
        display: none; // flex;
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-top-left-radius: $secondary-border-radius;
      border-top-right-radius: $secondary-border-radius;
      border: none !important;
      outline: none !important;
      object-fit: cover;
      transition: 0.3s all ease-in-out;
      -webkit-transition: 0.3s all ease-in-out;
      -moz-transition: 0.3s all ease-in-out;
      -ms-transition: 0.3s all ease-in-out;
      -o-transition: 0.3s all ease-in-out;
      // border-radius: $primary-border-radius;
      // -webkit-border-radius: $primary-border-radius;
      // -moz-border-radius: $primary-border-radius;
      // -ms-border-radius: $primary-border-radius;
      // -o-border-radius: $primary-border-radius;
    }

    .dots-container {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      display: none;
      justify-content: center;
      align-items: flex-end;
      gap: 7px;
      width: 100%;
      height: 100%;
      text-align: center;
      margin: auto;
      padding: 0 3.5px;
      border-radius: $secondary-border-radius;

      .dot-button {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 15%;
        height: 100%;
        padding: 14px 0;

        &:last-child {
          &:hover {
            .more-photos {
              display: flex;
            }
          }
        }

        .dot {
          cursor: pointer;
          height: 7px;
          width: 100%;
          margin: 0;
          background: rgba(255, 255, 255, 0.85);
          transition: background 0.6s ease;
          border-radius: $secondary-border-radius;
          -webkit-border-radius: $secondary-border-radius;
          -moz-border-radius: $secondary-border-radius;
          -ms-border-radius: $secondary-border-radius;
          -o-border-radius: $secondary-border-radius;
        }

        .more-photos {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          width: 100%;
          height: 100%;
          display: none;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 14px;
          background: rgba(70, 68, 68, 0.8);

          svg {
            width: 70px;
            height: 70px;
            margin-top: 21px;

            path {
              stroke: $dark-mode-color3;
            }
          }

          p {
            color: $third-color;
            font-size: large;
          }
        }
      }

      .dot-button:hover {
        .dot {
          background: $sec-design-color;
        }
      }
    }
  }

  .for-missing-and-found-cards {
    gap: 21px !important;
  }

  .overlay-breed-and-save-container {
    position: absolute;
    bottom: 10px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 90%;

    .overlay-breed-container {
      // position: absolute;
      // bottom: 10px;
      // z-index: 1;
      width: 100%;
      text-align: center;
      font-size: 0.85rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 5px 10px;
      background: rgba(237, 237, 237, 1);
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;
    }

    .save-button-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
      background: rgba(237, 237, 237, 1);
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      .save-button {
        cursor: pointer;
        width: 18px;
        height: 18px;

        path {
          stroke-width: 5;
          stroke: black;
          fill: transparent;
        }
      }

      svg.save-button.active {
        path {
          stroke: $sec-design-color;
          fill: $sec-design-color;
        }
      }
    }
  }

  .overlay-card-info-container {
    position: absolute;
    top: 10px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    width: 90%;
    // padding: 7px;
    background: transparent !important;
    // background: rgba(237, 237, 237, 0.9);
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;

    .overlay-breed-container {
      width: 100%;
      text-align: center;
      font-size: 0.9rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 7px;
      background: rgba(237, 237, 237, 1);
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;
    }

    .price-doc-vacc-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 90%;
    }

    .overlay-doc-vacc-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      width: 100%;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        // white-space: nowrap;
        // font-size: 0.75rem;
        // padding: 2px 5px;
        padding: 5px;
        color: white;
        background: #26b753;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;

        img {
          height: 20px;
        }

        svg {
          // width: 20px;
          height: 15px;

          path {
            fill: none;
            stroke: white;
          }
        }
      }
    }

    .overlay-price {
      width: fit-content;
      max-width: 75%;
      white-space: nowrap;
      font-size: 0.85rem;
      padding: 3px 10px;
      background: rgba(237, 237, 237, 1);
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      .card-1-price-with-deal {
        font-size: 0.85rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      span.adopt {
        // display: inline-block;
        // padding: 1.8px 10.5px;
        font-size: 0.85rem;
        // font-weight: 600;
        color: $sec-design-color;
        // background: $sec-design-color;
        // border-radius: 10px;
        // -webkit-border-radius: 10px;
        // -moz-border-radius: 10px;
        // -ms-border-radius: 10px;
        // -o-border-radius: 10px;
      }

      span.missing {
        // display: inline-block;
        // padding: 3px 10.5px;
        font-size: 0.85rem;
        // font-weight: 600;
        color: red;
        padding-left: 2.5px;
        // background: red;
        // border-radius: 10px;
        // -webkit-border-radius: 10px;
        // -moz-border-radius: 10px;
        // -ms-border-radius: 10px;
        // -o-border-radius: 10px;
      }

      .founded {
        // padding: 3px 10.5px;
        font-size: 0.85rem;
        // font-weight: 600;
        color: #26b753;
        // padding-left: 2.5px;
        // background: #26b753;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
      }
    }

    .missing-founded-phone {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      font-size: 0.85rem;
      padding: 3px 10px;
      background: rgba(237, 237, 237, 1);
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .for-founded-and-lost-containers {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .card-info-container {
    position: relative;
    display: none; // flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    gap: 14px;
    width: 100%;
    // height: 35%;
    padding: 0 14px 14px 14px;
    // border: 1px solid red;

    .breed {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 0;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .breed-text {
        font-size: 0.9rem;
        // font-weight: 600;
        text-decoration: none;
        max-width: 85%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover {
          color: $sec-design-color;
        }
      }

      .save-button-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .save-button {
          cursor: pointer;
          width: 21px;
          height: 21px;

          path {
            stroke-width: 5;
            stroke: black;
            fill: transparent;
          }
        }

        svg.save-button.active {
          path {
            stroke: $sec-design-color;
            fill: $sec-design-color;
          }
        }
      }
    }

    .age-city {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 14px;
      margin: 0;

      .gender,
      .age,
      .city {
        font-size: 0.85rem;
        white-space: nowrap;
      }

      .city {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 21px;
      width: 100%;
      text-align: left;
      margin: 0;

      p {
        font-size: 0.85rem;
        font-weight: 600;
        white-space: nowrap;
      }

      .card-1-price-with-deal {
        font-size: 0.85rem;
        white-space: nowrap;
      }
    }

    hr {
      width: 100%;
      margin: 0 auto;
      border: 1px solid $last-color;
      background: $last-color;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;
    }

    span.adopt {
      display: inline-block;
      // padding: 1.8px 10.5px;
      font-size: 0.85rem;
      font-weight: 600;
      color: $sec-design-color;
      // background: $sec-design-color;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }

    span.missing {
      display: inline-block;
      // padding: 3px 10.5px;
      font-size: 0.85rem;
      font-weight: 600;
      color: red;
      padding-left: 2.5px;
      // background: red;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }

    span.founded {
      display: inline-block;
      // padding: 3px 10.5px;
      font-size: 0.85rem;
      font-weight: 600;
      color: #26b753;
      padding-left: 2.5px;
      // background: #26b753;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }

    .missing-founded-phone {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10.5px;
      font-size: 0.85rem;
      padding-right: 3px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .vaccine-documents {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    .documents {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 3px;
      font-size: 0.78rem;
      color: white;
      width: fit-content;
      // padding: 3.5px 7px;
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;
    }

    .vaccine-icon-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 3px;
      font-size: 0.78rem;
      color: white;
      width: fit-content;
      // padding: 3.5px 7px;
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;
    }

    .documents,
    .vaccine-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      //width: 22px;
      //height: 22px;
      white-space: nowrap;
      font-size: 0.75rem;
      padding: 2px 5px;
      // cursor: pointer;
      color: white;
      background: #26b753;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;

      svg {
        width: 20px;
        height: 20px;

        path {
          stroke: white;
        }
      }

      &:hover {
        &::after {
          position: absolute;
          //bottom: 30px;
          left: 60px;
          display: none; // flex;
          justify-content: space-between;
          align-items: center;
          gap: 3px;
          font-size: 0.72rem;
          content: attr(title);
          color: white;
          background: #26b753;
          padding: 2px 7px;
          border-radius: 7px;
          -webkit-border-radius: 7px;
          -moz-border-radius: 7px;
          -ms-border-radius: 7px;
          -o-border-radius: 7px;
        }
      }
    }

    .not {
      color: white;
      background: $last-color;

      svg path {
        stroke: white;
      }

      &:hover {
        &::after {
          color: black;
          background: $last-color;
        }
      }
    }

    .green {
      color: #26b753;
      // background: #26b753;
    }

    .red {
      background: $hover-color; // #f3f4f6;
    }
  }
}
/****END CARD****/

/****START CARD-2****/
.card-2-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  flex-grow: 1;
  // width: 72%;
  padding: 0;
  margin: auto;
  margin: 0;
  background: transparent;
  border-radius: $primary-border-radius;
  -webkit-border-radius: $primary-border-radius;
  -moz-border-radius: $primary-border-radius;
  -ms-border-radius: $primary-border-radius;
  -o-border-radius: $primary-border-radius;

  .inside-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 14px;
    width: 100%;
    padding: 0;
    margin: auto;

    .card-2 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 21px;
      width: 100%;
      height: 160px;
      padding: 10px;
      overflow: hidden;
      background: $primary-bg;
      // border: 1px solid $hover-color;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      // &:hover {
      //   transform: translateX(10px);
      //   -webkit-transform: translateX(10px);
      //   -moz-transform: translateX(10px);
      //   -ms-transform: translateX(10px);
      //   -o-transform: translateX(10px);
      // }

      .slide-img-box-for-card2 {
        position: relative;
        width: 170px;
        height: 100%;
        overflow: hidden;
        border: none;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;

        &:hover {
          .dots-container {
            display: flex;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          // border-radius: $secondary-border-radius;
          // -webkit-border-radius: $secondary-border-radius;
          // -moz-border-radius: $secondary-border-radius;
          // -ms-border-radius: $secondary-border-radius;
          // -o-border-radius: $secondary-border-radius;
        }

        .dots-container {
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 2;
          display: none;
          justify-content: center;
          align-items: flex-end;
          gap: 7px;
          width: 100%;
          height: 100%;
          text-align: center;
          margin: auto;
          padding: 0 3px;
          border-radius: $secondary-border-radius;

          .dot-button {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: 15%;
            height: 100%;
            padding: 7px 0;

            &:last-child {
              &:hover {
                .more-photos {
                  display: flex;
                }
              }
            }

            .dot {
              cursor: pointer;
              height: 5.5px;
              width: 100%;
              margin: 0;
              background: rgba(255, 255, 255, 0.85);
              transition: background 0.6s ease;
              border-radius: 2px;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              -ms-border-radius: 2px;
              -o-border-radius: 2px;
            }

            .more-photos {
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              width: 100%;
              height: 100%;
              display: none;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 14px;
              background: rgba(70, 68, 68, 0.8);

              svg {
                width: 60px;
                height: 60px;

                path {
                  stroke: $dark-mode-color3;
                }
              }

              p {
                color: $third-color;
                font-size: normal;
              }
            }
          }

          .dot-button:hover {
            .dot {
              background: $sec-design-color;
            }
          }
        }
      }

      .card-2-info-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: space-between;
        // row-gap: 7px;
        width: 75%;
        height: 100%;
        padding: 0;
        background: transparent;
        // border: 1px solid $hover-color;

        .card-2-breed-city {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0;
          // margin-bottom: 21px;
          // background: var(--bodyback-grey);

          a.breed {
            font-size: 0.9rem;
            font-weight: 600;
            text-decoration: none;

            &:hover {
              color: $sec-design-color;
            }
          }

          .city {
            font-size: 0.8rem;
          }
        }

        .sell-color {
          background: var(--bodyback-grey);
        }

        .found-color {
          background: #57b262;
        }

        .missing-color {
          background: rgb(238, 63, 83);
        }

        .adopt-color {
          background: $sec-design-color;
        }

        .card-2-vip-status {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3px 10px;
          background: $sec-design-color;
          border-radius: $secondary-border-radius;
          -webkit-border-radius: $secondary-border-radius;
          -moz-border-radius: $secondary-border-radius;
          -ms-border-radius: $secondary-border-radius;
          -o-border-radius: $secondary-border-radius;

          span {
            color: white;
            font-size: 0.65rem !important;
            // font-weight: 600;
            letter-spacing: 1px;
          }
        }

        .card-2-age-gender-doc {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 0;
          padding: 0;
          margin: 0;
          // border-bottom: 1px solid $hover-color;

          p {
            font-size: 0.8rem;

            i {
              display: none;
              font-size: 0.8rem;
            }
          }

          .vaccine {
            font-size: 0.8rem;
          }

          .green {
            color: rgb(87, 178, 98, 1);
          }

          .red {
            color: #f3f4f6;
          }
        }

        .card-2-price-rate {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          // padding: 7px 14px;
          // border-bottom: 1px dashed $hover-color;

          .price-currency {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
            width: fit-content;

            p {
              font-size: 0.9rem;
            }

            // .currency {
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   // gap: 1vh;
            //   width: 100%;
            //   height: 4vh;
            //   border: 0px solid $sec-design-color;
            //   border-radius: $secondary-border-radius;
            //   -webkit-border-radius: $secondary-border-radius;
            //   -moz-border-radius: $secondary-border-radius;
            //   -ms-border-radius: $secondary-border-radius;
            //   -o-border-radius: $secondary-border-radius;

            //   .currency-switch {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     cursor: pointer;
            //     padding: 0.5vh 1vh;
            //     border-radius: 50%;
            //     -webkit-border-radius: 50%;
            //     -moz-border-radius: 50%;
            //     -ms-border-radius: 50%;
            //     -o-border-radius: 50%;

            //     svg {
            //       position: relative;
            //       top: 0.25vh;
            //       width: 2.3vh;
            //       height: 2.3vh;

            //       path {
            //         fill: $search-font-color;
            //       }
            //     }
            //   }

            //   .active {
            //     background: $sec-design-color;

            //     svg {
            //       path {
            //         fill: black;
            //       }
            //     }
            //   }
            // }
          }
        }

        .price-with-deal {
          font-size: 0.85rem;
          // padding: 0 14px;
          // border-bottom: 1px dashed $hover-color;
        }

        .card-2-pet-adopt,
        .card-2-missing,
        .card-2-found {
          padding: 0;
          margin: 0;
          // border-bottom: 1px dashed $hover-color;
          // border-radius: $secondary-border-radius;
          // -webkit-border-radius: $secondary-border-radius;
          // -moz-border-radius: $secondary-border-radius;
          // -ms-border-radius: $secondary-border-radius;
          // -o-border-radius: $secondary-border-radius;
          // border-top-right-radius: $secondary-border-radius;
          // border-bottom-right-radius: $secondary-border-radius;

          p.adopt,
          p.missing,
          p.found {
            width: 100%;
            font-size: 0.85rem;
            text-align: left;
            padding: 0;
            margin: 0;
            // color: white;
            // background: $sec-design-color;
            border-radius: inherit;
            -webkit-border-radius: inherit;
            -moz-border-radius: inherit;
            -ms-border-radius: inherit;
            -o-border-radius: inherit;
          }
        }

        // .card-2-missing {
        //   p.missing {
        //     background: red !important;
        //   }
        // }

        // .card-2-found {
        //   p.found {
        //     background: green !important;
        //   }
        // }

        .viewpost-and-buttons {
          display: flex !important;
          justify-content: space-between;
          align-items: flex-end;
          // margin-top: -3px;
          padding: 0;

          .postdate-view {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 7px;

            p {
              font-size: 0.75rem;
              color: grey;
            }
          }

          .post-buttons {
            display: flex !important;
            justify-content: space-between;
            align-items: center;
            gap: 10.5px;

            button {
              display: flex !important;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              background: transparent;
              border: none;

              &:hover,
              &:focus,
              &:active {
                svg {
                  path {
                    fill: transparent;
                  }
                }
              }

              svg.save-button {
                width: 22px;
                max-height: 22px;

                path {
                  stroke-width: 5;
                  stroke: black;
                  fill: transparent;
                }
              }

              svg.save-button.active {
                path {
                  stroke: $sec-design-color;
                  fill: $sec-design-color;
                }
              }

              svg.message {
                width: 21px;
                max-height: 22px;

                // path {
                //   stroke: black;
                // }

                &:hover path {
                  stroke: $sec-design-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
/****END CARD-2****/

/****START SECOND FILTER****/
.filter-of-company {
  // position: -webkit-sticky;
  // position: sticky;
  // top: 100px;
  width: 25%;
  min-width: 25%;
  padding: 20px;
  border: none;
  background: $primary-bg;
  // border: 1px solid $last-color;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  .city-and-district-filter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 0;
    border: none;

    .wrapper {
      position: relative;
      width: 100%;
      background: transparent;
      // border: 1px solid red;

      .select-button {
        position: relative;
        width: 100%;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        padding: 15px 5px;
        cursor: pointer;
        background: transparent;
        border: none;
        border-bottom: 1px solid $last-color;
        // border-radius: $secondary-border-radius;
        // -webkit-border-radius: $secondary-border-radius;
        // -moz-border-radius: $secondary-border-radius;
        // -ms-border-radius: $secondary-border-radius;
        // -o-border-radius: $secondary-border-radius;

        &:hover {
          border-color: $border-secondary-color;
        }

        div {
          color: $primary-color;
          font-size: 0.8rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 90%;
        }

        svg {
          // display: none;
          width: 12px;

          path {
            stroke: grey;
          }
        }
      }

      .select-menu-dropdown {
        position: relative;
        display: block; // none; - for dropdown
        width: 100%;
        background: transparent;
        padding: 0;

        .filter-default-text {
          width: 100%;
          font-size: 0.85rem;
          text-align: center;
          padding: 35px;
        }

        .select-search {
          width: 100%;

          input {
            width: 100%;
            padding: 8px 40px;
            outline: none;
            background: $hover-color;
            background-image: url(../../public/svg/search.svg);
            background-repeat: no-repeat;
            background-position: 15px center;
            background-size: 15px;
            text-align: left;
            border: 1px solid transparent;

            &::placeholder {
              font-size: 0.8rem;
            }
          }
        }

        ul.options {
          max-height: 250px;
          background: transparent !important;
          margin: 0;
          margin-top: 7px;
          list-style: none;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 7px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: $primary-border-radius;
            -webkit-border-radius: $primary-border-radius;
            -moz-border-radius: $primary-border-radius;
            -ms-border-radius: $primary-border-radius;
            -o-border-radius: $primary-border-radius;
          }

          &::-webkit-scrollbar-thumb {
            background: $sec-design-color;
            border-radius: $primary-border-radius;
            -webkit-border-radius: $primary-border-radius;
            -moz-border-radius: $primary-border-radius;
            -ms-border-radius: $primary-border-radius;
            -o-border-radius: $primary-border-radius;
          }

          label {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
            width: 95%;
            padding: 10px 7px;
            color: black;
            font-size: 0.8rem;
            cursor: pointer;
            border: none;
            // border-bottom: 1px solid $last-color;
            border-radius: $primary-border-radius;
            -webkit-border-radius: $primary-border-radius;
            -moz-border-radius: $primary-border-radius;
            -ms-border-radius: $primary-border-radius;
            -o-border-radius: $primary-border-radius;

            &:hover {
              // color: $third-color;
              background: $hover-color;
            }

            // &:active {
            //   color: $sec-design-color;
            // }

            input.search-checkbox {
              appearance: none;
              width: 14px;
              height: 14px;
              cursor: pointer;
              background: white;
              border: 1px solid $last-color;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;

              &:hover {
                border-color: $third-color;
              }

              &:checked {
                background: transparent;
                // background-image: url(../../public/svg/check.svg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                border: 4px solid $sec-design-color;
              }
            }
          }
        }
      }
    }

    .select-button.active {
      border-color: $border-primary-color;

      svg {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
      }

      .select-menu-dropdown {
        display: block;
      }
    }
  }

  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 30px auto 15px auto;
    overflow: hidden;
    background: var(--bodyback-grey);
    border-radius: $secondary-border-radius;
    border: none;

    &:hover {
      background: $hover-color;
    }

    input[type="search"] {
      width: 80%;
      padding: 10px 20px;
      font-size: 0.8rem;
      border: none;
      outline: none;
      background: transparent;

      &:hover,
      &:focus,
      &:active {
        background: transparent;
      }

      &::placeholder {
        font-size: 0.8rem;
      }
    }

    input[type="submit"] {
      width: 20%;
      padding: 10px;
      border: none;
      cursor: pointer;
      color: transparent;
      background: transparent;
      background-image: url(../../public/svg/search.svg);
      background-size: 17px;
      background-repeat: no-repeat;
      background-position: center center;
    }
  } /****END SEARCH BOX****/

  .keyword-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 21px;
    margin-bottom: 30px;
    padding: 0 10px;
  }

  svg {
    height: 21px;
  }

  .keyword {
    font-size: 0.8rem;
    padding: 5px 10px;
    cursor: pointer;
    white-space: nowrap;
    background: $hover-color; // rgba(255, 255, 255, 0.5);
    border: 1px solid transparent;
    border-radius: $secondary-border-radius;

    &:hover {
      background: rgb(245, 133, 0, 0.6);
      border-color: rgb(245, 133, 0, 0.5);
    }
  }

  .keyword.active {
    background: rgb(245, 133, 0, 0.6);
    border-color: rgb(245, 133, 0, 0.5);
  }
}
/****END SECOND FILTER****/

/****START CARD-3****/
.company-container {
  width: 100%;
  padding: 0;
  margin: 0;

  .card-3-container {
    width: 100%;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;

    .card-3 {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 30px;
      padding: 20px;
      margin: 0;
      width: 100%;
      // height: 250px;
      text-align: left;
      background: $primary-bg;
      overflow: hidden;
      border: none;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      .company-img-box-and-name {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        width: 100%;

        img {
          width: 70px;
          height: 70px;
          object-fit: cover;
          border-radius: $secondary-border-radius;
          -webkit-border-radius: $secondary-border-radius;
          -moz-border-radius: $secondary-border-radius;
          -ms-border-radius: $secondary-border-radius;
          -o-border-radius: $secondary-border-radius;
        }

        .company-name {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;

          p {
            flex-grow: 1;
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: 600;
          }

          span {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            width: 100%;
            margin: 0;
            // padding: 2px 7px;
            // font-size: 0.6rem;
            // color: white;
            // background: #22c55e;
            border-radius: $primary-border-radius;
            -webkit-border-radius: $primary-border-radius;
            -moz-border-radius: $primary-border-radius;
            -ms-border-radius: $primary-border-radius;
            -o-border-radius: $primary-border-radius;

            span {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 7px;
              width: fit-content;
              margin: 0;
              padding: 2px 10px;
              font-size: 0.75rem;
              color: black;
              background: var(--bodyback-grey);
              border-radius: $primary-border-radius;
              -webkit-border-radius: $primary-border-radius;
              -moz-border-radius: $primary-border-radius;
              -ms-border-radius: $primary-border-radius;
              -o-border-radius: $primary-border-radius;

              svg {
                width: 20px;
                height: 20px;

                path {
                  fill: none;
                  stroke: #22c55e;
                }
              }
            }
          }
        }
      }

      hr {
        width: 100%;
        background: var(--bodyback-grey);
        border: 2px solid var(--bodyback-grey);
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;
      }

      .company-info-outside-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: space-between;
        width: 100%;
        height: 100%;
        padding: 0;

        .company-info-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 20px;

          .company-info-inside-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            svg {
              width: 23px;
              height: 23px;
              padding: 4px;
              background: var(--background);
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;

              path {
                stroke: transparent;
                fill: #5e5e5e;

                &:nth-child(2) {
                  fill: white;
                  stroke: white;
                }
              }
            }

            p {
              font-size: 0.8rem;
            }
          }
        }
      }

      .company-contact-button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 28px;
        width: 100%;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;
          width: 100%;
          font-size: 0.8rem;
          padding: 7px 14px;
          margin: auto;
          //cursor: pointer;
          border: none;
          border-radius: $primary-border-radius;
          -webkit-border-radius: $primary-border-radius;
          -moz-border-radius: $primary-border-radius;
          -ms-border-radius: $primary-border-radius;
          -o-border-radius: $primary-border-radius;

          img {
            height: 23px;
          }
        }
      }
    }
  }
}
/****END CARD-3****/

/****START  ****/
.card-4-container {
  width: 100%;
  // min-height: 100vh;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;

  .card-4 {
    border: none;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    height: fit-content;
    padding: 20px;
    margin: 0;
    text-align: left;
    background: var(--bodyback);
    overflow: hidden;
    //border: 1px solid $hover-color;
    //border-radius: 28px;
    //-moz-border-radius: 28px;
    //-ms-border-radius: 28px;
    //-o-border-radius: 28px;
    //-webkit-border-radius: 28px;
    border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;

    &:hover {
      .worker-status {
        color: white;
        background: $sec-design-color;
      }
    }

    .walker-img-name-and-rating-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      width: 100%;
      // border: 1px solid red;

      div.walker-img {
        width: 64px;
        height: 64px;
        overflow: hidden;
        background: rgba(145, 180, 203, 0.4);
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        // border: 1px solid red;
        // border-radius: 28px;
        // -moz-border-radius: 28px;
        // -ms-border-radius: 28px;
        // -o-border-radius: 28px;
        // -webkit-border-radius: 28px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          margin: auto;
        }
      }

      .walker-name-and-rating-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 5px;
        flex-grow: 1;
        // border: 1px solid red;
      }

      .rating-stars {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        span {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 3px;

          i {
            cursor: pointer;
            font-size: 0.8rem;
            color: $search-font-color;
          }

          i.active {
            font-size: 0.8rem;
            color: #22c55e;
          }
        }
      }

      .worker-name {
        font-size: 0.9rem;
      }
    }

    hr {
      width: 100%;
      margin: auto;
      background: var(--bodyback-grey);
      border: 2px solid var(--bodyback-grey);
      border-radius: $third-border-radius;
      -webkit-border-radius: $third-border-radius;
      -moz-border-radius: $third-border-radius;
      -ms-border-radius: $third-border-radius;
      -o-border-radius: $third-border-radius;
    }

    .worker-info-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      width: 100%;

      .worker-info-inside-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        width: 100%;

        p {
          flex-grow: 1;
          max-width: 80%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 0.8rem;
          color: rgba(55, 55, 55, 1);

          span {
            display: none;
          }
        }

        svg {
          width: 20px;
          height: 20px;
          padding: 4px;
          background: var(--background);
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;

          path {
            fill: #5e5e5e;
            stroke: transparent;
          }
        }
      }
    }

    .message-worker {
      display: none;
      width: fit-content;
      padding: 7px 20px;
      // margin: auto;
      background: var(--bodyback-grey);
      border: none;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;
    }
  }
}
/****END CARD-4****/

/****START PAGINATION****/
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // border: 1px solid green;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 0.78rem;
    padding: 7px 14px;
    margin: auto;
    text-decoration: none;
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;

    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.pagination a.active {
  // display: inline-block;
  background: $sec-design-color;
  color: white;
}
/****END PAGINATION****/

/****START STICKY-BANNER****/
.sticky-banner-container {
  display: none;
  //position: -webkit-sticky;
  //position: sticky;
  position: fixed;
  bottom: 7px;
  left: 50%;
  z-index: 1000;
  //top: 15%;
  width: 100%;
  height: 150px;
  max-width: 900px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);

  .close_sticky_banner {
    position: absolute;
    top: 14px;
    right: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #aaaaaa;
    font-size: 1.3rem;
    font-weight: bold;
    padding: 6px;
    background: white;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    &:hover,
    &:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
      background: var(--bodyback-grey);

      svg path {
        fill: black;
      }
    }

    svg {
      width: 18px;
      height: 18px;

      path {
        fill: grey;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;
  }
}

.sticky-banner-container2 {
  // position: -webkit-sticky;
  // position: sticky;
  // top: 0;
  // display: none;
  position: relative;
  width: 100%;
  height: 170px;
  margin: 7px auto;
  cursor: pointer;
  background: $last-color;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  p {
    position: relative;
    top: 40%;
    text-align: center;
    color: $secondary-color;
    font-size: 2rem;
    letter-spacing: 5;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }
}
/****START STICKY-BANNER****/

/****START FOOTER****/
.scroll-up {
  position: relative;
  display: none; // flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1300px; // 1200px;
  padding: 2px;
  background: $sec-design-color;
  //#36454f:;
  //rgba(239,245,239,1):;
  border: none;
  margin: auto;
  border-radius: $third-border-radius;
  -webkit-border-radius: $third-border-radius;
  -moz-border-radius: $third-border-radius;
  -ms-border-radius: $third-border-radius;
  -o-border-radius: $third-border-radius;

  &:hover {
    background: $sec-design-color;

    svg {
      path {
        fill: black;
      }
    }
  }

  svg {
    width: 18px;
    max-width: 18px;
    max-height: 18px;
    margin: 0 !important;

    path {
      fill: black;
    }
  }
}

.footer {
  font-family: "BPG Excelsior Caps DejaVu 2010";
  position: relative;
  top: 0;
  width: 100%;
  padding: 35px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;

  ul {
    li {
      list-style: none;
      line-height: 2em;

      span {
        color: black;
        font-size: 0.85rem;
        font-weight: 600;
        line-height: 3;
      }

      a {
        color: black;
        font-size: 0.8rem;
        font-weight: 500;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          color: $sec-design-color;
        }
      }

      p {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10.5px;
        color: black;
        font-size: 0.8rem;
        font-weight: 500;

        .our-services-check {
          width: 17px;
          height: 17px;
          background-image: url("../../public/svg/check-without-circle.svg");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

.footer-for-mobile {
  display: none;
}

.our-social-pages-and-contact-container {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 15px;
  padding: 14px 7px;
  background: transparent;
  border: none;

  p {
    font-size: 0.85rem;
    font-weight: 600;
    color: black;
    margin: 0 14px 0 0;
  }

  .social-icons-inside-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 21px;
    margin-top: 10px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      border: none;

      &:hover {
        // background: #cfd2d7;

        svg {
          // transform: scale(1.3);
          // -webkit-transform: scale(1.3);
          // -moz-transform: scale(1.3);
          // -ms-transform: scale(1.3);
          // -o-transform: scale(1.3);

          path {
            fill: $sec-design-color;
          }
        }

        .title {
          display: block;
        }
      }

      svg {
        width: 17px;
        height: 17px;

        path {
          fill: black; // rgb(65, 64, 64);
        }
      }

      .title {
        display: none;
        position: absolute;
        top: 100%;
        color: white;
        font-size: 0.85rem;
        font-weight: normal;
        padding: 3.5px 10.5px;
        background: rgb(65, 64, 64);
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        border-radius: $primary-border-radius;
        -webkit-border-radius: $primary-border-radius;
        -moz-border-radius: $primary-border-radius;
        -ms-border-radius: $primary-border-radius;
        -o-border-radius: $primary-border-radius;
      }
    }
  }

  .contact-details-outside-container {
    margin: 0;

    .contact-details-inside-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 14px;
      width: fit-content;
      margin: auto;
      // background: rgba(255, 255, 255, 0);
      border-radius: $third-border-radius;
      -webkit-border-radius: $third-border-radius;
      -moz-border-radius: $third-border-radius;
      -ms-border-radius: $third-border-radius;
      -o-border-radius: $third-border-radius;

      span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 7px;
        white-space: nowrap;
        color: black;
        font-size: 0.8rem;
        padding: 0;

        svg {
          width: 17px;
          height: 17px;

          path {
            stroke: black;
          }
        }
      }
    }
  }
}

.download-app-and-user-counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 21px 0;
  // margin: 0 auto 21px auto;

  .download-app {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 8px;
      background: black;
      border: 1px solid lightgrey;
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;

      &:hover {
        background: grey;
      }

      svg {
        max-height: 35px;
      }
    }
  }
}

.copyright {
  font-family: "BPG Excelsior Caps DejaVu 2010";
  position: relative;
  top: 0;
  width: 100%;
  max-width: 1200px;
  padding: 14px 0;
  margin: 0 auto;

  p {
    color: black;
    font-size: 0.8rem;
    font-weight: 600;
  }
}
/****END FOOTER****/

/****HERE START MODAL CONTAINERS****/

/****START Modal (background)****/
.modal-background {
  position: fixed;
  z-index: 999999999;
  top: 0;
  right: 0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
  background: rgba(0, 0, 0, 0.8);
  // animation-name: slidedown;
  // animation-duration: 0.5s;
}
/****END Modal (background)****/

.login-signup-parent-modal-container {
  position: relative;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  width: 500px; // 1000px;
  height: 630px;
  max-width: 1000px;
  max-height: 90%;
  margin: auto;
  overflow: hidden;
  background: transparent;
  background: white;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);

  .login-advertise-container {
    display: none;
    width: 50%;
    height: 100%;
    background: transparent;
    // background-image: url("../../public/img/preview.jpeg");
    // background-position: center center;
    // background-repeat: no-repeat;
    // background-size: contain;

    .mySwiper {
      width: 100%;
      height: 100%;
      --swiper-pagination-color: #f58500ff;
      --swiper-pagination-bullet-inactive-color: white;
      --swiper-pagination-bullet-inactive-opacity: 1;
      --swiper-pagination-bullet-size: 15px;
      --swiper-pagination-bullet-horizontal-gap: 7px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .advertise-container {
    display: none;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    width: 50%;
    height: 100%;
    border: none;
    border-left: 2px solid $hover-color;

    &:hover {
      .vertical-lane {
        animation-play-state: paused;
      }
    }

    .vertical-lane {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 21px;
      border: 3px solid black;

      // img {
      //   position: relative;
      //   width: 40vh;
      //   height: 40vh;
      //   object-fit: cover;
      //   border-radius: $secondary-border-radius;
      //   -webkit-border-radius: $secondary-border-radius;
      //   -moz-border-radius: $secondary-border-radius;
      //   -ms-border-radius: $secondary-border-radius;
      //   -o-border-radius: $secondary-border-radius;
      //   box-shadow: var(--shadow);
      // }
    }

    .move-down {
      animation: movedown 40s linear infinite;
      // animation-iteration-count: 2;
      -webkit-animation: movedown 40s;

      @keyframes movedown {
        from {
          top: -100vh;
        }
        to {
          top: 100vh;
        }
      }
    }

    .move-up {
      animation: moveup 40s;
      animation-iteration-count: 2;
      -webkit-animation: moveup 40s;

      @keyframes moveup {
        from {
          top: 100vh;
        }
        to {
          top: -100vh;
        }
      }
    }
  }
}

.logo-and-language-switcher-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  margin: 0;

  .close_login,
  .close_registration {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    font-size: 0.87rem;
    font-weight: 500;
    color: black;
    padding: 9px 14px;
    border: none;
    background: var(--bodyback-grey);
    // border: 1px solid $hover-color;
    border: none;
    cursor: pointer;

    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;

    &:hover,
    &:focus {
      border-color: $sec-design-color;

      svg path {
        stroke: $search-font-color;
      }
    }

    svg {
      width: 17px;
      height: 17px;

      path {
        stroke: rgba(55, 55, 55, 1);
      }
    }
  }

  .lang-switcher-container {
    // display: none;
    position: relative;
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    padding: 0;
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;

    button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10.5px;
      width: 100%;
      padding: 9px 14px;
      overflow: hidden;
      border: none;
      background: var(--bodyback-grey);
      // border: 1px solid $hover-color;
      cursor: pointer;
      border-radius: inherit;
      -webkit-border-radius: inherit;
      -moz-border-radius: inherit;
      -ms-border-radius: inherit;
      -o-border-radius: inherit;

      &:after {
        display: none;
        background: #fff;
        content: "";
        height: 155px;
        left: -75px;
        opacity: 0.5; // 0.2;
        position: absolute;
        top: -50px;
        width: 50px;
        // z-index: -10;
        transform: rotate(35deg);
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &:hover {
        border-color: $sec-design-color;

        &:after {
          left: 120%;
          transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }

      svg.earth {
        display: none;
        width: 17px;
        height: 17px;

        path {
          stroke: transparent !important;
          fill: rgb(46, 46, 46);
        }
      }

      img {
        // display: none;
        max-height: 18.5px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
      }

      span {
        font-size: 0.87rem;
        font-weight: 500;
        color: black;
      }

      svg.language-arrow {
        // display: none;
        height: 7.5px;
        transition: all 0.4s;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        -ms-transition: all 0.4s;
        -o-transition: all 0.4s;

        path {
          stroke: darkgrey;
        }
      }
    }

    .lang-list-container {
      display: none;
      position: absolute;
      z-index: 2;
      left: 50%;
      width: 100%;
      margin-top: 10px;
      padding: 7px;
      background: $primary-bg;
      overflow: hidden;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);

      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      ul {
        // display: none;

        h3 {
          display: none;
          color: gray;
          font-size: 0.87rem;
          padding: 14px 27px;
        }

        li {
          display: flex !important;
          justify-content: flex-start;
          align-items: center;
          gap: 14px;
          flex-wrap: nowrap;
          padding: 9px 27px;
          cursor: pointer;
          border-radius: $primary-border-radius;
          -webkit-border-radius: $primary-border-radius;
          -moz-border-radius: $primary-border-radius;
          -ms-border-radius: $primary-border-radius;
          -o-border-radius: $primary-border-radius;

          &:hover {
            // color: $sec-design-color;
            background: transparent;
            background: var(--bodyback-grey);
          }

          img {
            display: none;
            width: 17px;
            height: 17px;
          }

          input[type="radio"] {
            appearance: none;
            width: 12px;
            height: 12px;

            border: 1px solid #767676;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }

          p {
            font-size: 0.79rem;
          }
        }

        .current-currency.active {
          input[type="radio"] {
            appearance: none;
            width: 12px;
            height: 12px;
            // background: #767676;
            border: 4px solid $sec-design-color;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }
        }
      }
    }
  }

  .lang-switcher-container.active {
    button {
      border-color: $sec-design-color;

      svg.earth {
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
      }

      svg.language-arrow {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);

        path {
          stroke: $search-font-color;
        }
      }
    }

    .lang-list-container {
      display: block;

      svg {
        path {
          stroke: transparent;
        }
      }
    }
  } // here end lang switcher
}

/****START LOGIN CONTAINER****/
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  width: 100%; // 50%;
  height: 100%;
  background: var(--bodyback);
  margin: auto;
  padding: 35px 56px;

  form {
    width: 100%;

    .login-title {
      padding: 7px 3.5px;
    }

    label {
      position: relative;
      display: block;
      width: 100%;
      margin: 21px 0;
      margin-bottom: 10px;

      input {
        display: block;
        width: 100%;
        font-size: 0.8rem;
        padding: 17.5px 28px;
        background: var(--bodyback-grey);
        // border: 1px solid lightgray;
        border: none;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        &:hover {
          border-color: grey;
        }

        &::placeholder {
          color: grey;
          font-size: 0.87rem;
        }
      }

      input:invalid {
        border-color: red;
      }

      .visible-notvisible {
        position: absolute;
        bottom: 15px;
        right: 25px;
        background: transparent;

        svg {
          position: relative;
          height: 21px;
          width: 21px;
          cursor: pointer;
          pointer-events: none;

          path {
            stroke: $search-font-color;
          }
        }

        .pass-visible {
          display: none;
          transform: scale(1);
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
        }

        .pass-notvisible {
          display: block;
          transform: scale(1);
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
        }

        input {
          appearance: none;
          position: absolute;
          padding: 10.5px;
          border-radius: 0;
          border: none;
          cursor: pointer;
          background: transparent;
        }

        input:checked ~ .pass-notvisible {
          display: none;
        }

        input:checked ~ .pass-visible {
          display: block;
        }
      }
    }

    .error-text,
    .server-error-text {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 7px;
      margin-left: 3.5px;
      font-size: $secondary-fontsize;
      color: red;

      svg {
        width: 17.5px;
        height: 17.5px;

        path {
          fill: red;
        }
      }
    }

    .server-error-text {
      border: none;
    }

    .forgetpass-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      label {
        display: none;
      }

      a {
        text-decoration: none;
        font-size: 0.86rem;

        &:hover {
          color: $sec-design-color;
        }
      }

      a.active {
        .recover-password {
          display: block;
        }
      }
    }
  }

  input[type="submit"] {
    width: 100%;
    padding: 15px;
    margin: 21px auto;
    border: none;
    cursor: pointer;
    font-size: 0.87rem;
    opacity: 0.8;
    font-weight: 600;
    background: $sec-design-color;
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;

    &:hover {
      opacity: 1;
    }
  }

  .social-login-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    padding: 5px;
    width: 100%;
    margin: auto;

    button {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-size: 0.87rem;
      font-weight: 500;
      padding: 12px;
      color: $primary-color;
      background: var(--bodyback-grey);
      cursor: pointer;
      // border: 1px solid lightgray;
      border: none;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      &:hover {
        opacity: 0.8;
        border-color: gray;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .create-account {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 14px;
    font-size: 0.95rem;
    color: grey;

    span {
      color: $sec-design-color;
      cursor: pointer;

      &:hover {
        color: black;
      }
    }
  }
}
/****END LOGIN CONTAINER****/

/****START RECOVER PASSWORD CONTAINER****/
.recover-password {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 600px;
  height: 100%;
  max-width: 1200px;
  margin: 70px 0;
  padding: 21px 35px;
  overscroll-behavior: contain;
  background: $primary-bg;

  .recover-password-title {
    margin: 21px 3.5px;
  }

  p {
    font-size: 1.3rem;
    font-weight: bold;
    margin: 7px;
  }

  form {
    input {
      width: 100%;
      padding: 14px;
      margin: 7px 0;
      font-size: 0.87rem;
      border: 1px solid lightgrey;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;
    }

    .check-code {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      margin-top: 14px;

      img {
        width: 25%;
        height: 47px;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;
      }

      input {
        width: 35%;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;
      }
    }

    button {
      width: 100%;
      padding: 14px;
      font-size: 0.87rem;
      margin-top: 49px;
      cursor: pointer;
      background: $sec-design-color;
      border: none;
      opacity: 0.8;
      border: 1px solid $sec-design-color;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      &:hover {
        opacity: 1;
      }
    }

    .error-text {
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 7px;
      text-align: left;
      font-size: 0.85rem;
      font-weight: normal;
      color: red;
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: red;
        }
      }
    }
  }
}
/****END RECOVER PASSWORD CONTAINER****/

/****START EMAIL RESET PASSWORD CONTAINER****/
.email-reset-password {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 500px;
  height: 100%;
  max-width: 1200px;
  margin: 70px 0;
  padding: 21px 35px;
  overscroll-behavior: contain;
  background: $third-color;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  .email-reset-title {
    margin: 21px 3.5px;
  }

  p {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 7px;
  }

  form {
    input {
      width: 100%;
      padding: 14px;
      margin: 10.5px 0;
      font-size: 14px;
      border: 1px solid lightgrey;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;
    }

    .check-code {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 30px;
      margin-top: 7px;

      img {
        width: 25%;
        height: 50px;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;
      }

      input {
        width: 35%;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;
      }
    }

    button {
      width: 100%;
      padding: 14px;
      font-size: 0.85rem;
      margin-top: 40px;
      cursor: pointer;
      background: $sec-design-color;
      border: none;
      opacity: 0.8;
      border: 1px solid $sec-design-color;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      &:hover {
        opacity: 1;
      }
    }

    .error-text,
    .confirm-error-text {
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 7px;
      width: 100%;
      margin-bottom: 10px;
      text-align: left;
      font-size: 0.85rem;
      font-weight: normal;
      color: red;
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;

      svg {
        width: 15px;
        height: 15px;

        path {
          fill: red;
        }
      }
    }
  }
}
/****END EMAIL RESET PASSWORD CONTAINER****/

/****START REGISTRATION CONTAINER****/
.registration-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  gap: 35px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: $primary-bg;
  margin: auto;
  padding: 35px 56px;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    margin: 14px 0;
    background: transparent;
    border-radius: $primary-border-radius;
    -webkit-border-radius: $primary-border-radius;
    -moz-border-radius: $primary-border-radius;
    -ms-border-radius: $primary-border-radius;
    -o-border-radius: $primary-border-radius;
  }

  &::-webkit-scrollbar-thumb {
    // display: none;
    padding: 0;
    background: $sec-design-color;
    border-radius: $primary-border-radius;
    -webkit-border-radius: $primary-border-radius;
    -moz-border-radius: $primary-border-radius;
    -ms-border-radius: $primary-border-radius;
    -o-border-radius: $primary-border-radius;
  }

  form {
    display: block;
    width: 100%;
    margin: 0;

    .signup-title {
      padding: 11.5px 3.5px;
    }

    label {
      position: relative;
      display: block;
      width: 100%;
      margin: 21px 0;

      p {
        display: none;
        width: 100%;
        text-align: left;
        font-size: 0.78rem;
        font-weight: bold;
        padding: 7px 1.5px;
        color: $primary-color;
      }

      .error-text,
      .server-error-text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 7px;
        font-size: 0.78rem;
        color: red;
        margin-top: 5px;

        svg {
          width: 17.5px;
          height: 17.5px;

          path {
            fill: red;
          }
        }
      }
    }

    input[type="name"],
    input[type="lastname"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="address"] {
      background: var(--bodyback-grey);
      width: 100%;
      font-size: 0.87rem;
      padding: 17.5px 23px;
      margin: 0;
      // border: 1px solid lightgrey;
      border: none;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      &:hover {
        border-color: grey;
      }

      &::placeholder {
        color: grey;
        font-size: 0.87rem;
      }
    }

    .registration-text {
      font-size: 0.8rem;
      margin: 28px auto;
      padding: 0 3.5px;

      a {
        font-size: 0.8rem;
        color: $sec-design-color;
        text-decoration: none;
        cursor: pointer;
      }
    }

    input[type="submit"] {
      width: 100%;
      padding: 15px;
      margin: auto;
      border: none;
      font-size: 0.87rem;
      font-weight: 600;
      cursor: pointer;
      background: $sec-design-color;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;
      opacity: 0.8;

      &:hover {
        color: white;
        opacity: 1;
      }
    }

    select {
      // appearance: none;
      width: 100%;
      font-size: 0.87rem;
      padding: 17.5px 20px;
      margin: 0;
      background: var(--bodyback-grey);
      // border: 1px solid lightgrey;
      border: none;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      &:hover {
        border-color: grey;
      }

      option {
        appearance: none;
        text-indent: 15px;
        font-size: 0.8rem;
      }
    }
  }

  .existing-account {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 7px;
    font-size: 0.9rem;
    color: grey;

    span {
      color: $sec-design-color;
      font-size: 0.9rem;
      cursor: pointer;

      &:hover {
        color: black;
      }
    }
  }
}
/****END REGISTRATION CONTAINER****/

.binder {
  position: absolute;
  z-index: 1000000;
  display: block !important;
  width: 100%;
  height: 100%;
  background: var(--background);

  p {
    position: relative;
    top: 50%;
    width: 100%;
    font-size: 2.5rem;
    text-align: center;
    // border: 1px solid red;
    color: $sec-design-color;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }
}

.find-pet-info-language-switcher-for-desktop {
  position: relative !important;
  top: auto !important;
  left: auto !important;
  display: flex !important;
  flex-direction: row !important;
  box-shadow: none !important;
  background: transparent !important;

  img {
    width: 25px !important;
    cursor: pointer !important;
  }
}

.find-pet-info-language-switcher {
  position: fixed;
  top: 300px;
  right: 0;
  display: none; // flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  width: fit-content;
  padding: 3.5px 5px;
  background: var(--bodyback-grey);
  border-top-left-radius: $primary-border-radius;
  border-bottom-left-radius: $primary-border-radius;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;

  box-shadow: var(--shadow);

  &:hover {
    right: 0;
  }

  img {
    width: 21px;
    // border-radius: inherit;
    // -webkit-border-radius: inherit;
    // -moz-border-radius: inherit;
    // -ms-border-radius: inherit;
    // -o-border-radius: inherit;
  }
}

.rules-parent-container {
  width: 100%;
  max-width: 1200px;
  margin: 35px auto;
  background: var(--bodyback-grey);
  border-radius: $primary-border-radius;
  -webkit-border-radius: $primary-border-radius;
  -moz-border-radius: $primary-border-radius;
  -ms-border-radius: $primary-border-radius;
  -o-border-radius: $primary-border-radius;

  .rules-parent-container-header {
    display: none;
    background: green;
    padding: 50px;
  }

  .rules-inside-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 21px;
    padding: 14px;

    .table-of-content {
      position: sticky;
      top: 65px;
      left: 0;
      width: 27%;
      height: 560px;
      overflow-y: auto;
      list-style-type: none;
      padding: 14px;

      li {
        font-size: 0.78rem;
        font-weight: 500;
        cursor: pointer;
        margin: 14px;

        &:hover {
          font-weight: 600;

          a {
            font-weight: 600;
            color: black;
          }
        }

        a {
          font-size: 0.78rem;
          font-weight: 500;
          text-decoration: none;
        }
      }

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        margin: 21px 0;
        background: $hover-color;
        border-radius: $primary-border-radius;
        -webkit-border-radius: $primary-border-radius;
        -moz-border-radius: $primary-border-radius;
        -ms-border-radius: $primary-border-radius;
        -o-border-radius: $primary-border-radius;
      }

      &::-webkit-scrollbar-thumb {
        padding: 14px 0;
        background: rgb(95, 95, 95);
        border-radius: $primary-border-radius;
        -webkit-border-radius: $primary-border-radius;
        -moz-border-radius: $primary-border-radius;
        -ms-border-radius: $primary-border-radius;
        -o-border-radius: $primary-border-radius;
      }
    }

    .editor {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 35px;
      width: 73%;
      padding: 50px;
      background: $primary-bg;
      box-shadow: 0 10px 30px rgba(55, 58, 64, 0.11);
      border-radius: $primary-border-radius;
      -webkit-border-radius: $primary-border-radius;
      -moz-border-radius: $primary-border-radius;
      -ms-border-radius: $primary-border-radius;
      -o-border-radius: $primary-border-radius;

      .read-rules {
        text-align: left;
        line-height: 22px;

        * {
          font-size: 0.85rem;
        }

        h3 {
          font-size: 0.9rem;
          margin: 21px 0;
        }

        ul,
        ol,
        li,
        p {
          margin: 15px 0;
        }
        ul,
        ol {
          margin-left: 30px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
