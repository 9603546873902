@import "../variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@200&family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

// for normal desktops
// @media screen and (min-width: 1064.01px) and (max-width: 1280px) {
//     body {
//         // background-color: tomato;

//         .head-nav {
//             .head-nav-inside-container {
//                 border-radius: 0;
//                 -webkit-border-radius: 0;
//                 -moz-border-radius: 0;
//                 -ms-border-radius: 0;
//                 -o-border-radius: 0;
//             }
//         }
//     }
// }

@media screen and (max-width: 1320px) {
    body {
        // background-color: tomato;

        .head-nav {
            .head-nav-inside-container {
                max-width: 100%;
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
            }
        }

        .scroll-up {
            max-width: 100%;
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
        }

        .footer-parent-container {
            max-width: 100%;
            padding: 0 20px;
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
        }

        .copyright {
            padding: 14px 20px;
        }
    }
}
