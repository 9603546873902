@import "./variables.scss";

/****START Modal (background)****/
.modal-background {
  display: block;
  position: fixed;
  z-index: 999999999;
  padding: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
  background-color: rgba(0, 0, 0, 0.8);
  // animation-name: slidedown;
  // animation-duration: 0.5s;
}
/****END Modal (background)****/

.white {
  padding: 0 !important;
  overflow-y: auto !important;
  background-color: white !important;
}

.container-with-background-img {
  width: 100%;
  height: 100vh;
  padding: 5vh 10vh;
  // background-image: url(../../public/img/backo.jpg);
  background-size: cover;
  background-attachment: fixed;

  .logo-and-orderbuttons {
    // margin-top: 30vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10vh;
    // background-color: rgba(36, 36, 36, 0.5);
    // border-radius: $third-border-radius;
    // -webkit-border-radius: $third-border-radius;
    // -moz-border-radius: $third-border-radius;
    // -ms-border-radius: $third-border-radius;
    // -o-border-radius: $third-border-radius;

    .logo-box {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1vh;

      .logo-img-box {
        width: 4.5vh;
        height: 4.5vh;
        // background-image: url("../../public/img/pethub1.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      a {
        font-family: "Shantell Sans", cursive;
        font-size: 4.5vh;
        font-weight: bolder;
        color: $sec-design-color;
        text-decoration: none;
        letter-spacing: 0.3vh;

        span {
          font-size: 4.5vh;
          font-weight: bolder;
          color: rgba(55, 55, 55, 1);
        }
      }
    } /****END LOGO BOX*****/

    .qr-collar-order-buttons-container-for-home {
      width: fit-content;
      margin: 0 !important;
      padding: 0.5vh 5vh;
      background-color: rgba(36, 36, 36, 0.5);
      border-radius: $third-border-radius;
      -webkit-border-radius: $third-border-radius;
      -moz-border-radius: $third-border-radius;
      -ms-border-radius: $third-border-radius;
      -o-border-radius: $third-border-radius;

      .order-buttons-inside-container {
        backdrop-filter: blur(2px);

        button {
          color: white;
          // font-size: 1.9vh;
          // font-weight: 500;
          background-color: transparent;

          svg path {
            stroke: white;
          }
        }

        a {
          color: $sec-design-color;
          background-color: transparent;

          ion-icon {
            color: $sec-design-color;
          }
        }
      }
    }
  }
}

.collar-order-modal-container {
  position: relative;
  // top: 50%;
  // left: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  margin: auto;
  padding: 2vh 4vh;
  background-color: $third-color;
  border-radius: $secondary-border-radius;
  -webkit-border-radius: $secondary-border-radius;
  -moz-border-radius: $secondary-border-radius;
  -ms-border-radius: $secondary-border-radius;
  -o-border-radius: $secondary-border-radius;

  // transform: translate(-50%, -50%);
  // -webkit-transform: translate(-50%, -50%);
  // -moz-transform: translate(-50%, -50%);
  // -ms-transform: translate(-50%, -50%);
  // -o-transform: translate(-50%, -50%);

  .slideshow-and-imglist {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    height: 80vh;

    .collar-manual-slideshow-container {
      position: relative;
      width: 100%;
      height: 64vh;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .prev,
      .next {
        cursor: pointer;
        position: absolute;
        top: 45%;
        width: auto;
        padding: 2vh 3.4vh;
        color: $third-color;
        font-weight: bold;
        font-size: 3vh;
        background-color: rgba(30, 30, 30, 0.5);
        border-radius: 50%;
        user-select: none;
        -webkit-user-select: none;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
      }

      /* Position the "next button" to the right */
      .prev {
        left: 7vh;
        border-radius: 50%;
      }

      .next {
        right: 7vh;
        border-radius: 50%;
      }

      /* On hover, add a black background color with a little bit see-through */
      .prev:hover,
      .next:hover {
        background-color: rgba(14, 14, 14, 0.9);
      }
    }

    .imglist {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2vh;
      width: 100%;
      padding: 0.5vh 0;
      overflow-x: auto;
      border-radius: $secondary-border-radius;
      -webkit-border-radius: $secondary-border-radius;
      -moz-border-radius: $secondary-border-radius;
      -ms-border-radius: $secondary-border-radius;
      -o-border-radius: $secondary-border-radius;

      .img-box {
        width: 15vh;
        height: 14vh;
        cursor: pointer;
        overflow: hidden;
        border-radius: $primary-border-radius;
        -webkit-border-radius: $primary-border-radius;
        -moz-border-radius: $primary-border-radius;
        -ms-border-radius: $primary-border-radius;
        -o-border-radius: $primary-border-radius;
        border: 1px solid lightgrey;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .collar-header-and-options-container {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    width: 45%;

    .collar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 2.9vh;
      }

      .close_login {
        color: #aaaaaa;
        font-size: 7vh;
        font-weight: bold;

        &:hover,
        &:focus {
          color: #000;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    .qr-without-collar {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .select-color {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2vh;
        margin-left: 5vh;
        // border: 1px solid black;

        .select-color-text {
          margin: 0 0.5vh;
          font-size: 2.3vh;
        }

        button {
          cursor: pointer;
          padding: 1.3vh;
          transition: 0.2s ease-out;
          border: none;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          // border-radius: 10px;
          // -webkit-border-radius: 10px;
          // -moz-border-radius: 10px;
          // -ms-border-radius: 10px;
          // -o-border-radius: 10px;

          &:hover {
            transform: scale(1.7);
            -webkit-transform: scale(1.7);
            -moz-transform: scale(1.7);
            -ms-transform: scale(1.7);
            -o-transform: scale(1.7);
          }

          &:nth-child(2) {
            background-color: black;
          }

          &:nth-child(3) {
            background-color: red;
          }

          &:nth-child(4) {
            background-color: #737a40ff;
          }

          &:nth-child(5) {
            background-color: yellow;
          }

          &:nth-child(6) {
            background-color: blue;
          }
        }

        button.active {
          transform: scale(1.7);
          -webkit-transform: scale(1.7);
          -moz-transform: scale(1.7);
          -ms-transform: scale(1.7);
          -o-transform: scale(1.7);
        }
      }
    }

    .collar-quantity-and-price {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        margin-left: 5.5vh;
        font-size: 2.3vh;
      }
    }

    .warning {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1vh;
      margin: 2vh 0 1vh 0;

      svg {
        width: 3vh;
        height: 3vh;

        path {
          fill: red;
        }
      }

      p {
        color: red;
        font-size: 1.8vh;
      }
    }

    form {
      width: 100%;
      margin: auto;

      .input-container {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        // row-gap: 2.5vh;

        input {
          width: 47%;
          padding: 2vh;
          font-size: 2vh;
          border: 1px solid $last-color;
          border-radius: $secondary-border-radius;
          -webkit-border-radius: $secondary-border-radius;
          -moz-border-radius: $secondary-border-radius;
          -ms-border-radius: $secondary-border-radius;
          -o-border-radius: $secondary-border-radius;

          ::placeholder {
            font-size: 2vh;
          }

          &:hover {
            border-color: $border-secondary-color;
          }
        }
      }

      .qr-collar-rules {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1vh;
        margin: 3.7vh 0 2vh 0;

        input[type="checkbox"] {
          appearance: none;
          padding: 0.8vh;
          border: 2px solid grey;
          border-radius: 3px;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;

          &:hover {
            border-color: black;
          }

          &:checked {
            background-color: #086effff;
            background-image: url(../../public/svg/check.svg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-color: #086effff;
          }
        }

        p {
          font-size: 1.8vh;

          a {
            font-size: 1.8vh;
          }
        }
      }

      .order-button {
        width: 100%;
        display: block;
        font-size: 2.5vh;
        text-align: center;
        padding: 2vh 15vh;
        margin: auto;
        cursor: pointer;
        background-color: $sec-design-color;
        border: none;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;
      }
    }

    .wrapper {
      position: relative;
      width: 47%;
      background-color: transparent;

      .select-button {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.1vh 0;
        cursor: pointer;
        background-color: transparent;
        border: 1px solid $last-color;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;

        &:hover {
          border-color: $border-secondary-color;
        }

        div {
          color: $primary-color;
          font-size: 2vh;
          padding: 0 0 0 2vh;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 90%;
        }

        svg {
          position: absolute;
          top: 39%;
          right: 5%;
          width: 1.8vh;

          path {
            stroke: grey;
          }
        }
      }

      .select-menu-dropdown {
        position: absolute;
        display: none;
        width: 100%;
        background-color: $primary-bg;
        padding: 1vh;
        margin: 0.5vh 0 0 0;
        z-index: 1000;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;

        -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);

        .select-search {
          width: 100%;

          input {
            width: 100%;
            padding: 1.4vh 5vh;
            outline: none;
            background-color: $primary-bg;
            background-image: url(../../public/svg/search.svg);
            background-repeat: no-repeat;
            background-position: 5% center;
            background-size: 2.5vh;
            text-align: left;
            border: 1px solid lightgrey;
            border-radius: $primary-border-radius;
            -webkit-border-radius: $primary-border-radius;
            -moz-border-radius: $primary-border-radius;
            -ms-border-radius: $primary-border-radius;
            -o-border-radius: $primary-border-radius;
          }
        }

        ul.options {
          max-height: 30vh;
          background-color: transparent !important;
          margin: 1vh 0 0 0;
          list-style: none;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 1vh;
          }

          &::-webkit-scrollbar-track {
            background-color: white;
            border-radius: $primary-border-radius;
            -webkit-border-radius: $primary-border-radius;
            -moz-border-radius: $primary-border-radius;
            -ms-border-radius: $primary-border-radius;
            -o-border-radius: $primary-border-radius;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $sec-design-color;
            border-radius: $primary-border-radius;
            -webkit-border-radius: $primary-border-radius;
            -moz-border-radius: $primary-border-radius;
            -ms-border-radius: $primary-border-radius;
            -o-border-radius: $primary-border-radius;
          }

          label {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1vh;
            width: 95%;
            margin: 1vh 0;
            padding: 1.5vh 1vh;
            color: black;
            font-size: 1.8vh;
            cursor: pointer;
            border-radius: $secondary-border-radius;
            -webkit-border-radius: $secondary-border-radius;
            -moz-border-radius: $secondary-border-radius;
            -ms-border-radius: $secondary-border-radius;
            -o-border-radius: $secondary-border-radius;

            &:hover {
              color: white;
              background-color: $sec-design-color;
            }

            &:active {
              color: $sec-design-color;
            }

            input.search-checkbox {
              appearance: none;
              width: 2.5vh;
              height: 2.5vh;
              cursor: pointer;
              background-color: white;
              border: 1px solid grey;
              border-radius: 50px;
              -webkit-border-radius: 50px;
              -moz-border-radius: 50px;
              -ms-border-radius: 50px;
              -o-border-radius: 50px;

              &:hover {
                border-color: white;
              }

              &:checked {
                background-color: $sec-design-color;
                background-image: url(../../public/svg/check.svg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                border: 1px solid $sec-design-color;
              }
            }
          }

          li {
            color: black;
            font-size: 1.7vh;
            padding: 1.4vh 1.4vh;
            cursor: pointer;
            border-radius: $secondary-border-radius;
            -webkit-border-radius: $secondary-border-radius;
            -moz-border-radius: $secondary-border-radius;
            -ms-border-radius: $secondary-border-radius;
            -o-border-radius: $secondary-border-radius;

            &:hover {
              color: white;
              background-color: $sec-design-color;
            }
          }
        }
      }
    }

    .select-button.active {
      border-color: grey;
      // -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
      // -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
      // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);

      i {
        float: right;
        font-size: 1.8vh;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
      }

      svg {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
      }

      .select-menu-dropdown {
        display: block;
      }
    }
  }
}
