@import "../variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@200&family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

// for small mobiles
@media screen and (max-width: 375px) {
    .parent-container-short,
    .userpanel-container {
        width: 100% !important;
    }

    .modal-menu-for-mobile {
        width: 70% !important;
    }
}

// for normal mobiles
@media screen and (max-width: 767px) {
    body {
        padding: 0;
        margin: 0;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }

    * {
        padding: 0;
        margin: 0;
    }

    .parent-container-short,
    .userpanel-container {
        width: 95%;
        padding: 0 14px;
    }

    .full-site-parent-container,
    .footer-parent-container {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    .breeds-dropdown-size {
        width: 100% !important;

        ul.options {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            scroll-behavior: contain !important;
        }
    }

    .flex {
        display: block !important;
    }

    .city-dropdown-size {
        width: 100% !important;

        ul.options {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            scroll-behavior: contain !important;
        }
    }

    .modal-box-for-mobile {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2000;
        width: 100%;
        height: 100vh;
        background: rgba(23, 23, 23, 0.8) !important;
    }

    .modal-box-for-mobile.active {
        display: block;
    }

    .hr-for-mobile-menu {
        width: 40%;
        margin: 10px auto;
        background: transparent;
        border: 1px solid transparent;
        border-radius: $third-border-radius;
        -webkit-border-radius: $third-border-radius;
        -moz-border-radius: $third-border-radius;
        -ms-border-radius: $third-border-radius;
        -o-border-radius: $third-border-radius;
    }

    .modal-menu-for-mobile {
        position: relative;
        top: 50%;
        width: 100% !important;
        height: 100%;
        margin: auto;
        //margin-left: auto;
        text-align: left;
        // padding-left: 30px;
        padding: 60px 30px;
        clear: both;
        background: var(--bodyback-grey);
        // border-radius: 30px;
        // -webkit-border-radius: 30px;
        // -moz-border-radius: 30px;
        // -ms-border-radius: 30px;
        // -o-border-radius: 30px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);

        p {
            display: none;
            width: fit-content;
            color: black;
            font-size: 1.5rem;
            padding: 10px 7px;
            padding-top: 0;
            margin-bottom: 10px;
            margin-left: 12px;
            border: none;
            border-bottom: 1px solid rgba(55, 55, 55, 1);
        }

        a {
            color: black;
            padding: 14px;
            font-size: 0.9rem;
            font-weight: 700;
            display: block;
            text-decoration: none;
            text-align: center;
        }
    }

    .close-modal-icon-box {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        // padding: 10px 30px;
        border: none;
        margin-bottom: 50px;

        .close_mobilemenu {
            height: 28px;
            color: black;
            text-align: center;
            font-size: 3rem;
            border: none;
        }

        .close_mobilemenu:hover,
        .close_mobilemenu:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
        }
    }
    /****END MODAL MENU FOR MOBILE CONTAINER****/

    .header-top-container {
        display: flex !important;
        border-bottom: 1px solid var(--background);

        .header-top-inside-container {
            .header-left-container {
                gap: 15px;

                .social-icons {
                    gap: 5px;
                }

                .top-aboutus {
                    display: none;
                }

                .top-order-collar {
                    display: none;
                }
            }
        }
    }

    .header-middle-parent-container {
        .header-middle-inside-container {
            padding: 10px;
            background: var(--bodyback);

            // .logo-box {
            //     .logo-img-box {
            //         display: none;
            //     }

            //     a {
            //         color: $sec-design-color;

            //         span {
            //             color: $sec-design-color;
            //         }
            //     }
            // }

            .header-middle-short-menu {
                display: none;
            }

            .header-search-container {
                display: none;
            }

            .header-right-container {
                gap: 21px;

                .menu-for-mobile {
                    display: flex !important;
                    padding: 0;
                    margin: 0 !important;
                    border-color: transparent;

                    &:hover {
                        border-color: transparent;
                    }

                    svg {
                        height: 23px;
                        max-height: 23px;
                    }
                }

                .login-signup-container {
                    button {
                        padding: 0;
                        background: transparent;
                        border-color: transparent;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;

                        &:hover {
                            border-color: transparent;
                        }

                        svg {
                            height: 25px;
                            max-height: 25px;
                            padding: 0 !important;
                            background: transparent !important;

                            path {
                                stroke: black;
                                fill: black;
                            }
                        }

                        p {
                            display: none;
                        }
                    }
                }

                .login-signup-container.active {
                    button {
                        border-color: transparent;
                    }
                }

                .user-head-container {
                    position: static !important;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;

                    button {
                        position: static !important;
                        padding: 0;
                        background: transparent;
                        border-color: transparent;

                        &:hover {
                            border-color: transparent;
                        }

                        svg {
                            width: 25px;
                            max-height: 25px;
                        }

                        .user-profile-first-letters {
                            font-size: 0.7rem;
                            width: 20px;
                            height: 20px;
                        }

                        a.user-fullname {
                            display: none;
                        }
                    }

                    .user-dropdown-container {
                        left: auto;
                        right: 0px !important;
                        //width: 100% !important;
                        margin-top: 0;
                        padding-top: 10px;
                        height: 100vh !important;
                        border-radius: 0;
                        -webkit-border-radius: 0;
                        -moz-border-radius: 0;
                        -ms-border-radius: 0;
                        -o-border-radius: 0;
                    }
                }

                .user-head-container.active {
                    button {
                        border-color: transparent;
                        background: transparent !important;
                    }
                }

                .language-and-darkmode {
                    gap: 21px;
                    padding: 0;

                    .lang-switcher-container {
                        position: static !important;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;

                        button {
                            position: static !important;
                            padding: 0;
                            background: transparent;
                            border-color: transparent;
                            border-radius: 0;
                            -webkit-border-radius: 0;
                            -moz-border-radius: 0;
                            -ms-border-radius: 0;
                            -o-border-radius: 0;

                            &:hover {
                                border-color: transparent;
                            }

                            img {
                                height: 23px;
                                max-height: 23px;
                            }

                            span {
                                display: none;
                            }
                        }
                    }

                    .lang-list-container {
                        left: auto;
                        right: 0px !important;
                        //width: 100% !important;
                        margin-top: 10px !important;
                        padding-top: 10px;
                        height: 100vh !important;
                        border-radius: 0 !important;
                        -webkit-border-radius: 0 !important;
                        -moz-border-radius: 0 !important;
                        -ms-border-radius: 0 !important;
                        -o-border-radius: 0 !important;
                    }

                    .lang-switcher-container.active {
                        button {
                            border-color: transparent;
                            background: transparent !important;
                        }
                    }

                    .dark-mode-btn {
                        padding: 0;
                        background: transparent;
                        border-color: transparent;

                        &:hover {
                            border-color: transparent;
                        }

                        svg {
                            height: 25px;
                            max-height: 25px;
                        }
                    }
                }
            }
        }
    }

    .head-nav {
        display: none;
    }

    .qr-tags-swiper {
        margin-top: 20px;
        height: fit-content !important;
        max-height: fit-content !important;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;

        .swiper-button-next,
        .swiper-button-prev {
            display: none;
            right: 0;
        }

        .swiper-button-prev {
            left: 0;
        }
    }

    .qr-tags-container {
        height: 450px !important;
        background-position: right;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;

        // .overlay-2 {
        //     .text-for-qr-tags-container {
        //         .right-face-img {
        //             width: 100% !important;
        //             height: 300px !important;
        //             object-fit: contain !important;
        //         }
        //     }
        // }

        // .overlay-3 {
        //     .text-for-qr-tags-container {
        //         .right-face-img {
        //             width: 100% !important;
        //             height: 300px !important;
        //             object-fit: contain !important;
        //         }
        //     }
        // }

        .qr-tags-container-overlay {
            .text-for-qr-tags-container {
                flex-direction: column;
                flex-direction: column-reverse;
                justify-content: flex-start;
                align-items: center !important;
                gap: 10px;
                padding: 0;
                // background-color: rgba(0, 0, 0, 0.6);

                .qr-tag-main-text {
                    width: 100%;
                    text-align: center;
                    gap: 10px;
                    padding: 0 15px;

                    p {
                        margin-top: 15px;
                        text-align: center;
                        font-size: 1rem;
                        // color: var(--bodyback) !important;

                        .priority {
                            font-size: 1rem;
                        }
                    }

                    span {
                        // color: var(--bodyback);
                    }

                    a {
                        font-size: 0.65rem;
                        margin-top: 10px;
                        margin: 10px auto 0 auto;
                    }

                    .pethub_app_text {
                        font-size: 0.75rem;
                    }

                    .app-download {
                        justify-content: center;
                    }

                    .join-us {
                        margin: auto;
                        margin-top: 30px;
                    }
                }

                .right-face-img {
                    max-width: 60%;
                }
            }
        }
    }

    .members-and-tags-counter-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .qr-tag-list-container {
        margin: 30px 0 !important;

        .qr-tag-list-inside-container {
            .prev,
            .next {
                display: none !important;
            }
        }
    }

    .qr-tag-card {
        max-height: 420px;

        .qr-tag-card-imgbox {
            height: 360px;

            img {
                object-fit: cover;
            }
        }
    }

    .qr-and-gps-tags {
        .qr-and-gps-tags-inside-container {
            grid-template-columns: repeat(1, 1fr);

            .tag-container {
                width: 90%;
                margin: auto;
            }
        }
    }

    .tags-card-container-for-homepage {
        .tags-card-inside-container-for-homepage {
            grid-template-columns: repeat(1, 1fr);

            .tag-card-for-home-page {
                width: 90%;
                height: auto;
                margin: auto;
            }
        }
    }

    .qr-and-gps-tags {
        .qr-and-gps-tags-inside-container {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .site-main-filtre-out-container {
        width: 100%;
        height: auto;
        position: relative;
        top: 0;
        left: 0;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;

        .site-main-filter-single-search-container {
            width: 100%;
            padding: 14px 21px;
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;

            &::-webkit-scrollbar {
                width: 3.5px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent !important;
            }

            &::-webkit-scrollbar-thumb {
                background-color: transparent !important;
            }

            .pets-button-in-filter-container {
                flex-wrap: wrap;
                column-gap: 0;
                overflow: auto;
                margin: 7px 0;

                .pets-button-container {
                    .pets-button {
                        width: 100%;
                        padding: 13px 30px;

                        svg {
                            display: none;
                        }
                    }
                }

                .pets-documents {
                    justify-content: flex-start;
                    gap: 7px;

                    .wrapper {
                        .select-menu-dropdown {
                            width: 100%;

                            ul.options {
                                justify-content: flex-start;
                                gap: 0;
                                padding: 0;

                                label {
                                    width: auto;
                                    padding: 13px;
                                }
                            }
                        }
                    }

                    .currency {
                        .currency-switch {
                            svg {
                                height: 14px;
                            }
                        }
                    }
                }

                .clear-filters {
                    padding: 12px;
                    color: white;

                    &:hover {
                        background-color: $sec-design-color;
                    }

                    svg {
                        max-height: 30px;

                        path {
                            stroke: black;
                        }
                    }
                }
            }

            .site-main-filtre {
                width: 100%;
                display: flex;
                flex-direction: column;
                row-gap: 0;
                margin: 0;
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
                border: none;

                .wrapper {
                    width: 100%;
                    margin: 7px auto;

                    .select-button {
                        padding: 15px;
                        background-color: $fourth-color;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;

                        &:hover {
                            background-color: $fourth-color;
                        }

                        div {
                            color: $primary-color;
                            padding-left: 7px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            max-width: 90%;
                        }

                        svg {
                            right: 10%;
                        }
                    }

                    .select-menu-dropdown {
                        width: 100%;
                        margin: 7px 0;
                        // border-radius: $third-border-radius;
                        // -webkit-border-radius: $third-border-radius;
                        // -moz-border-radius: $third-border-radius;
                        // -ms-border-radius: $third-border-radius;
                        // -o-border-radius: $third-border-radius;
                    }
                }

                .filter-price {
                    display: block;
                    margin: 7px auto;
                    padding: 0;
                    width: 100%;
                    overflow: hidden;
                    background: white;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;
                    box-shadow: var(--shadow);

                    input[type="text"] {
                        width: 50%;
                        margin: 0;
                        padding: 15px 20px;
                    }

                    .currency {
                        display: none;
                    }
                }

                .filter-search-button-container {
                    padding: 0;
                    margin: 7px 0;
                    background-color: transparent !important;
                    border: none !important;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;

                    button.filtre-search-button {
                        width: 100%;
                        padding: 16px;
                        margin: auto;
                        color: black !important;
                        background: $sec-design-color;
                        background-image: url(../../../public/svg/search.svg);
                        background-repeat: no-repeat;
                        background-position: 35% center;
                        background-size: 5.5%;
                        border: none !important;
                        border-radius: inherit;
                        -webkit-border-radius: inherit;
                        -moz-border-radius: inherit;
                        -ms-border-radius: inherit;
                        -o-border-radius: inherit;

                        &:hover {
                            color: black !important;
                        }
                    }
                }
            }

            .contact-details-outside-container {
                display: none !important;
            }
        }
    }

    .filter-left-version {
        width: 100%;
        margin-bottom: 25px !important;
        padding: 0;

        .filter-left-version-inside-container {
            .search-box {
                margin: 0 !important;
            }

            .pets-button-in-filter-container {
                width: 100%;

                .pets-button-container-for-left-version {
                    width: 100%;

                    .pets-button {
                    }
                }
            }

            .site-main-filtre {
                .wrapper {
                    .select-button {
                    }

                    .select-menu-dropdown {
                        display: none;
                    }

                    .select-button.active {
                        .select-menu-dropdown {
                            display: block !important;
                        }
                    }
                }
            }
        }
    }

    .card-2-container {
        width: 100% !important;

        .inside-container {
            width: 100% !important;
            padding: 0;

            .card-2 {
                flex-direction: column !important;
                height: auto !important ;

                .slide-img-box-for-card2 {
                    width: 100% !important;
                    height: 300px !important;
                }

                .card-2-info-container {
                    width: 100% !important;
                    row-gap: 30px;
                    padding: 10px !important;

                    .card-2-breed-city {
                        margin: 0 !important;
                    }

                    .card-2-age-gender-doc {
                        flex-wrap: wrap !important;
                        column-gap: 5px !important;
                        row-gap: 15px !important;
                        padding: 0 !important;
                    }

                    .card-2-price-rate {
                    }
                }
            }
        }
    }

    .card {
        gap: 21px;
        width: 100%;
        margin: auto;
        // padding: 21px;
        background: var(--bodyback);

        .slide-img-box-for-postcard {
            height: 400px;
            border-radius: inherit;
            -webkit-border-radius: inherit;
            -moz-border-radius: inherit;
            -ms-border-radius: inherit;
            -o-border-radius: inherit;
        }

        .card-info-container {
            gap: 28px;
            padding: 0 3px;
        }

        .for-missing-and-found-cards {
            gap: 35px !important;
        }
    }

    .order-collars-container {
        width: 100% !important;
        padding: 0 !important;

        .collar-order-modal-container {
            flex-direction: column;
            width: 100% !important;

            .slideshow-and-imglist-and-description {
                width: 100% !important;

                .slideshow-and-imglist {
                    width: 100% !important;
                }

                .collar-description-block-container {
                    width: 95%;
                    padding: 0 10px;
                    margin: auto;
                }
            }

            .collar-header-and-options-container {
                width: 100% !important;
                padding: 0 10px;

                .separate-container {
                    padding: 0 5px !important;
                    // background: transparent !important;
                }

                .collar-header {
                    display: none !important;
                }

                .qr-without-collar {
                    flex-wrap: wrap;
                }

                .qr-tag-size {
                    gap: 5px !important;

                    div {
                        width: fit-content;
                        font-size: 0.85rem;
                        text-align: center;
                        padding: 10px 15px;
                        cursor: pointer;
                        background: var(--bodyback-grey);
                        // border: 1px solid transparent;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;
                    }
                }

                form {
                    .input-container {
                        row-gap: 17px !important;

                        input {
                            width: 100% !important;
                            padding: 16px !important;
                        }
                    }

                    .separate-container {
                        .qr-collar-rules {
                            input[type="checkbox"] {
                                padding: 7px !important;
                            }
                        }
                    }
                }

                .wrapper {
                    width: 100% !important;

                    .select-button {
                        padding: 16px !important;
                    }
                }
            }
        }
    }

    .qr-collar-instruction-container {
        margin: 0 auto !important;
    }

    .qr-collar-order-container {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 40px !important;
    }

    .video-instruction {
        width: 100%;
        height: auto !important;
        max-height: auto !important;
    }

    .custom-drop-down {
        background: var(--bodyback-grey) !important;
    }

    .select-color-container {
        .select-color {
            .color-button-container {
                // display: grid !important;
                // grid-template-columns: repeat(2, 1fr) !important;

                button {
                    justify-content: flex-start !important;
                }
            }
        }
    }

    .post-page-main-container {
        .modal-for-img {
            .full-img-box {
                img.modal-img {
                    width: 95%;
                    height: auto !important;
                }
            }
        }

        .city-view-id-container {
            gap: 14px !important;
            padding: 0 !important;
        }

        .post-img-info-container {
            flex-direction: column;
            width: 100% !important;
            padding: 0 !important;

            .right-container-for-banner-in-single-post {
                row-gap: 7px !important;
                width: 100% !important;
                max-width: 100% !important;
                padding: 10px 0 !important;

                img {
                    width: calc(100vw - 0px) !important;
                    max-height: 150px !important;
                    object-fit: cover;
                    border-radius: 0 !important;
                    -webkit-border-radius: 0 !important;
                    -moz-border-radius: 0 !important;
                    -ms-border-radius: 0 !important;
                    -o-border-radius: 0 !important;
                }
            }

            .post-img-and-info-container {
                flex-direction: column !important;
                width: 100% !important;

                .post-page-img-gallery {
                    flex-direction: column !important;
                    gap: 6px !important;
                    margin: 0 !important;

                    .slideshow-container {
                        width: 100% !important;
                        border-radius: 0 !important;
                        -webkit-border-radius: 0 !important;
                        -moz-border-radius: 0 !important;
                        -ms-border-radius: 0 !important;
                        -o-border-radius: 0 !important;

                        img {
                            border-radius: inherit !important;
                            -webkit-border-radius: inherit !important;
                            -moz-border-radius: inherit !important;
                            -ms-border-radius: inherit !important;
                            -o-border-radius: inherit !important;
                        }
                    }

                    .img-list-container {
                        flex-direction: row !important;
                        justify-content: center !important;
                        width: 100% !important;
                        max-width: 100% !important;
                        height: auto !important;
                        // padding: 0 !important;
                        margin: 0 !important;
                        border-radius: 0 !important;
                        -webkit-border-radius: 0 !important;
                        -moz-border-radius: 0 !important;
                        -ms-border-radius: 0 !important;
                        -o-border-radius: 0 !important;

                        img {
                            width: 70px !important;
                            height: 70px !important;
                            // border-radius: 0 !important;
                            // -webkit-border-radius: 0 !important;
                            // -moz-border-radius: 0 !important;
                            // -ms-border-radius: 0 !important;
                            // -o-border-radius: 0 !important;
                        }

                        // &::-webkit-scrollbar-track {
                        //     margin: 0 15px;
                        // }
                    }
                }

                .seller-info-container-static {
                    flex-direction: column !important;
                    padding: 15px !important;
                    margin: 0 !important;
                    border-radius: 0 !important;
                    -webkit-border-radius: 0 !important;
                    -moz-border-radius: 0 !important;
                    -ms-border-radius: 0 !important;
                    -o-border-radius: 0 !important;

                    .seller-phone-message-outside-container {
                        flex-direction: column !important;
                        width: 100% !important;

                        .seller-phone {
                            width: 100% !important;
                            padding: 15px !important;
                            background: #26b753 !important;
                            border-radius: $secondary-border-radius !important;
                            -webkit-border-radius: $secondary-border-radius !important;
                            -moz-border-radius: $secondary-border-radius !important;
                            -ms-border-radius: $secondary-border-radius !important;
                            -o-border-radius: $secondary-border-radius !important;

                            svg path {
                                stroke: white !important;
                            }

                            p {
                                color: white !important;
                            }
                        }

                        .send-message {
                            width: 100% !important;
                            padding: 15px !important;
                            background: var(--bodyback-grey) !important;
                            border-radius: $secondary-border-radius !important;
                            -webkit-border-radius: $secondary-border-radius !important;
                            -moz-border-radius: $secondary-border-radius !important;
                            -ms-border-radius: $secondary-border-radius !important;
                            -o-border-radius: $secondary-border-radius !important;
                        }
                    }

                    .price {
                        width: 100% !important;
                        padding: 7px;
                        // background: rgba(55, 55, 55, 1);

                        p {
                            // color: white;
                            padding: 0 17px !important;
                        }

                        .currency {
                            width: fit-content !important;
                        }
                    }

                    .price-with-deal {
                        width: 100% !important;
                        padding: 15px;
                        background: rgba(55, 55, 55, 1);
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;

                        p {
                            color: white;
                        }
                    }

                    .adopt-singlepost,
                    .founded-singlepost,
                    .missing-singlepost {
                        width: 100% !important;
                        border-radius: $secondary-border-radius !important;
                        -webkit-border-radius: $secondary-border-radius !important;
                        -moz-border-radius: $secondary-border-radius !important;
                        -ms-border-radius: $secondary-border-radius !important;
                        -o-border-radius: $secondary-border-radius !important;
                    }
                }

                .post-description-and-info-main-container {
                    width: 100% !important;

                    .post-description-container {
                        width: 100% !important;
                        margin-bottom: 0 !important;
                        border-radius: 0 !important;
                        -webkit-border-radius: 0 !important;
                        -moz-border-radius: 0 !important;
                        -ms-border-radius: 0 !important;
                        -o-border-radius: 0 !important;
                    }

                    .sticky-banner-container2 {
                        width: 100% !important;
                        margin: auto !important;
                    }

                    .post-pet-info-container {
                        width: 100% !important;
                        margin-top: 0 !important;
                        border-radius: 0 !important;
                        -webkit-border-radius: 0 !important;
                        -moz-border-radius: 0 !important;
                        -ms-border-radius: 0 !important;
                        -o-border-radius: 0 !important;

                        .post-pet-info {
                            label {
                                p {
                                }

                                input[type="text"] {
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .card-4-container {
        grid-template-columns: repeat(1, 1fr) !important;
        // padding: 10px !important;

        .card-4 {
            gap: 28px;
            width: 90%;
            margin: auto;
        }
    }

    /****START CONTACT US PAGE****/
    .contact-container {
        flex-direction: column !important;
        gap: 25px !important;
        margin: 0 !important;

        iframe.map {
            width: 100% !important;
            height: 300px !important;
        }

        .contact-info {
        }
    }
    /****FINISH CONTACT US PAGE****/

    .verify-account {
        flex-direction: column;
        width: 80%;
        margin: 150px auto;
        padding: 20px 5px !important;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;

        svg {
            width: 30px;
            height: 30px;
        }
    }

    .success-payment {
        flex-direction: column;
        width: 80%;
        margin: 150px auto;
        padding: 20px 5px !important;
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;

        svg {
            width: 30px;
            height: 30px;
        }
    }

    .userpanel-container {
        width: 100%;
        gap: 0 !important;
        margin: 20px 0 !important;

        .userpanel-left-container {
            display: none;
        }

        .userpanel-screen-main-container {
            width: 100% !important;

            .add-post-container {
                form.add-post {
                    .fields-container {
                        .for-currency-price-deal {
                            flex-wrap: wrap;
                            // flex-direction: column !important;
                        }

                        .wrapper2 {
                            padding: 10px !important;
                        }
                    }

                    .upload-post-img {
                        margin: 0 !important;

                        .upload-photo {
                            width: 70px !important;
                            height: 70px !important;

                            p {
                                display: none !important;
                            }

                            input[type="file"] {
                                position: absolute;
                                opacity: 0;
                                width: 100%;
                                height: 100%;
                                cursor: pointer;
                                padding: 0 !important;
                                margin: 0;
                                background-color: $primary-bg;
                            }
                        }

                        .uploaded-photo-box {
                            width: 70px !important;
                            height: 70px !important;
                        }
                    }

                    input[type="submit"] {
                        width: 100% !important;
                        margin: 15px 0 !important;
                    }
                }
            }

            .active-post-container {
                width: 100% !important;
                padding: 0 !important;

                .myactive-post-lit-cont {
                    flex-direction: column !important;
                    gap: 7px;
                    max-height: 100% !important;
                    // overflow: hidden;

                    div.act-post-img {
                        width: 100% !important;
                        min-width: 100%;
                        min-height: 250px !important;
                        max-height: 250px !important;
                    }

                    .myactive-post-info-container {
                        padding: 0 4px;

                        .myactive-post-info-inside-container {
                            gap: 15px;
                            border: 1px solid transparent;

                            ul {
                                // flex-direction: column;
                                justify-content: space-between !important;
                                align-items: flex-start;
                                flex-wrap: wrap;
                                width: fit-content !important;
                                max-width: 100% !important;
                                gap: 14px !important;

                                li {
                                    font-size: 0.9rem !important;

                                    span {
                                        font-size: 0.9rem;
                                    }
                                }

                                span {
                                    font-size: 0.9rem;
                                }
                            }
                        }
                    }

                    .vip-edit-delete-container {
                        width: 100% !important;
                        height: auto !important;
                        padding-right: 0 !important;
                        background: $sec-design-color;
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;

                        .edit-delete {
                            flex-direction: row !important;
                            justify-content: center !important;
                            gap: 30px !important;
                            opacity: 1 !important;
                        }
                    }
                }
            }

            .personal-info-container {
                form.user-information {
                    flex-direction: column !important;

                    .screen-user-photo {
                        width: 80%;

                        .photo {
                            img {
                            }
                        }

                        .upload-user-photo-btn {
                        }
                    }

                    .user-information-inside-container {
                        justify-content: center;
                        gap: 10px;

                        input {
                            width: 100%;
                            padding: 15px;
                        }

                        select {
                            width: 100%;
                            padding: 15px;
                        }

                        input.submit {
                            width: 100%;
                            padding: 15px;
                        }
                    }
                }

                form.change-password {
                    flex-direction: column;
                    width: 100%;
                    padding: 15px;

                    input[type="password"],
                    [type="submit"] {
                        width: 100%;
                        padding: 15px;
                    }
                }
            } // END PERSONAL INFO CONTAINER

            .generate-qr-container {
                width: 100%;

                .qr-collar-order-buttons-container {
                    flex-direction: column;

                    button,
                    a {
                        width: 80%;
                        padding: 16px;
                    }
                }

                .purchased-collars {
                    .purchased-collars-cards-container {
                        grid-template-columns: repeat(1, 1fr);
                        // padding: 0 15px;

                        .modal-background {
                            background: rgba(0, 0, 0, 0.9);

                            .qr-collar-buttons-instruction-container {
                                position: absolute;
                                top: 0;
                                left: 0;
                                justify-content: flex-start;
                                width: 100%;
                                height: 100%;
                                transform: none;
                                -webkit-transform: none;
                                -moz-transform: none;
                                -ms-transform: none;
                                -o-transform: none;
                            }
                        }

                        .purchased-collars-card {
                            flex-direction: column;
                            justify-content: center;
                            // height: 100%;

                            .pet-img-and-buttons {
                                width: 100%;

                                .purchased-collars-card-pet-name {
                                    padding: 10px 0;
                                }

                                .img-box {
                                    width: 300px;
                                    height: 300px;
                                    min-height: 300px !important;
                                }

                                .edit-delete-container {
                                    padding: 7px 10px;

                                    .hide-instruction-btn {
                                        display: none !important;
                                    }

                                    button {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }

                    .pet-info-outside-container {
                        position: relative !important;
                        // margin-top: 5px;

                        .pet-info-top-buttons-container {
                            .close-update-container {
                                display: none !important;
                            }
                        }

                        .pet-info-container {
                            .pet-info-container-title {
                                width: 100% !important;
                            }
                        }
                    }
                }
            }

            .favorite-post-container {
                padding: 0 10px;

                .favorite-post-inside-container {
                    grid-template-columns: repeat(1, 1fr);
                }
            }

            .user-message-container {
                flex-direction: column;
                height: 100%;
                padding: 10px;

                .user-list-container {
                    flex-direction: row;
                    width: 100%;
                    max-width: 100%;
                    overflow-x: auto;
                    overflow-y: hidden;

                    .user-name {
                        p {
                            white-space: nowrap;
                        }
                    }
                }

                .read-send-message-container {
                    width: 100%;

                    .read-message {
                        height: 30vh;
                    }

                    textarea {
                        height: 12vh;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    .confirmation-background-container {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
        min-height: 100%;
        padding: 35px;
        background: rgba(0, 0, 0, 0.9);
        border-radius: $secondary-border-radius;
        -webkit-border-radius: $secondary-border-radius;
        -moz-border-radius: $secondary-border-radius;
        -ms-border-radius: $secondary-border-radius;
        -o-border-radius: $secondary-border-radius;

        .confirmation-inside-container {
            width: 100%;
        }
    }

    .scroll-up {
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    .footer {
        display: none;
    }

    .footer-for-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 21px;
        padding-top: 30px;

        .footer-for-mobile-title {
            width: fit-content;
            margin: auto;

            p {
                font-size: 0.85rem;
                font-weight: 600;
                cursor: pointer;
                text-align: center;
                color: black;
            }

            ul {
                display: none;
                padding: 7px 0;

                li {
                    text-align: center;
                    color: $primary-color;
                    margin: 13px 0;
                    list-style-type: none;

                    a {
                        font-size: 0.9rem;
                        text-decoration: none;
                        color: black;
                    }

                    p {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 11px;
                        color: black;
                        cursor: default;
                        font-size: 0.85rem;
                        font-weight: 500;

                        .our-services-check {
                            width: 15px;
                            height: 15px;
                            background-image: url("../../../public/svg/check-without-circle.svg");
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }
        }

        .footer-for-mobile-title.active {
            ul {
                display: block;
            }
        }

        .social-icons-inside-container-for-mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5vh;
            margin: 3vh auto;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.3vh 0.1vh;
                border: none;

                svg {
                    width: 17px;
                    height: 17px;

                    path {
                        fill: black;
                    }
                }
            }
        }
    }

    .download-app-and-user-counter {
        width: 100%;
        margin: 7px auto;

        .download-app {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 25px;
            margin: auto;

            a {
                img {
                    height: 28px;
                    max-height: 28px;
                }
            }
        }
    }

    .copyright {
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 0;
        padding: 14px 0;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;

        p {
            font-size: 0.75rem;
            color: black;
        }
    }

    .modal-background {
        background: white !important;
        padding: 0 !important;
    }

    .login-signup-parent-modal-container {
        width: 100%;
        height: 100vh;
        margin: 0 !important;
        padding: 0 !important;

        .login-advertise-container {
            display: none;
        }
    }

    .login-container {
        width: 100%;
        padding: 40px 20px;
    }

    .registration-container {
        width: 100%;
        padding: 40px 20px;
    }

    .recover-password {
        width: 100%;
        padding: 20px !important;
        margin: 150px 0 !important;

        .recover-password-title {
            margin: 14px 3.5px;
        }

        p {
            font-size: 1rem;
            margin: 7px 3.5px;
        }

        form {
            .check-code {
                display: none;

                img {
                    width: 40%;
                }

                input {
                    width: 50%;
                }
            }

            button[type="submit"] {
                margin: 25px 0 !important;
            }
        }
    }

    /****START EMAIL RESET PASSWORD CONTAINER****/
    .email-reset-password {
        width: 100%;

        form {
            .check-code {
                display: none; // flex;
            }
        }
    }
    /****END EMAIL RESET PASSWORD CONTAINER****/

    .rules-parent-container {
        width: 95%;
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 20px;

        .rules-inside-container {
            padding: 7px;

            .table-of-content {
                display: none;
            }

            .editor {
                width: 100%;
                padding: 20px;
            }
        }
    }

    .binder {
        display: none !important;
    }

    .find-pet-info-container {
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        // gap: 0;
        width: 100%;
        height: 100%;
        // margin: 0 auto !important;
        padding: 0;
        // padding-bottom: 20px;
        background: var(--background) !important;

        .find-pet-info-container-header {
            flex-direction: column;
            width: 100% !important;
            // height: auto !important;
            // margin-top: 20px;
            padding: 70px 0 50px 0;
            background: transparent !important;

            .pet-photo-and-name-container {
                position: relative;
                width: 250px;
                height: 250px;
                // display: flex;
                // justify-content: center;
                // align-items: center;
                // width: fit-content;
                overflow: hidden;
                // height: 100% !important;
                padding: 0 !important;
                margin: auto !important;
                box-shadow: none !important;
                background: transparent !important;
                border: 2px solid rgba(55, 55, 55, 1);
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;

                img {
                    width: 100%;
                    height: 100%;
                    // min-width: 250px; //100%:;
                    // min-height: 250px; //400px:;
                    // max-width: 250px; //100%:;
                    // max-height: 250px; //400px:;
                    // margin: 50px auto 30px auto !important;
                    padding: 0 !important;
                    object-fit: cover;
                    // border-radius: 50%;
                    // -webkit-border-radius: 50%;
                    // -moz-border-radius: 50%;
                    // -ms-border-radius: 50%;
                    // -o-border-radius: 50%;
                    // border: 10px solid $sec-design-color;
                    // box-shadow: var(--shadow);
                    box-shadow: none !important;
                }

                .pet-name {
                    display: none;
                    position: absolute;
                    bottom: 60px;
                    left: 50%;
                    width: fit-content;
                    max-width: 80%;
                    color: rgba(55, 55, 55, 1);
                    text-align: center !important;
                    font-size: 1.4rem;
                    font-weight: 600;
                    padding: 2px 20px;
                    margin: 0 auto;
                    background: var(--background); // rgba(55, 55, 55, 1);
                    //margin-top: -80px;
                    border-radius: $third-border-radius;
                    -webkit-border-radius: $third-border-radius;
                    -moz-border-radius: $third-border-radius;
                    -ms-border-radius: $third-border-radius;
                    -o-border-radius: $third-border-radius;
                    transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                }
            }
        }

        .find-pet-info-container-body {
            width: 100%;
            background: var(--background);
            // border-top-left-radius: 35px;
            // border-top-right-radius: 35px;
            // box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.45);

            .find-pet-info-call-owner {
                position: relative;
                z-index: 1;
                display: flex !important;
                //flex-direction: column;
                justify-content: space-between !important;
                align-items: center;
                gap: 15px;
                width: 90% !important;
                padding: 8px 11px !important;
                margin: 17px auto !important;
                //margin-bottom: 0 !important;
                background: rgba(55, 55, 55, 1);
                border-radius: $third-border-radius;
                -webkit-border-radius: $third-border-radius;
                -moz-border-radius: $third-border-radius;
                -ms-border-radius: $third-border-radius;
                -o-border-radius: $third-border-radius;

                button,
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 2vh;
                    width: fit-content;
                    font-size: 2vh;
                    text-decoration: none;
                    //font-weight: 500;
                    cursor: pointer;
                    padding: 14px;
                    border: none;

                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;

                    svg {
                        width: 22px;
                        height: 22px;

                        path {
                            stroke-width: 0.8 !important;
                            stroke: rgba(55, 55, 55, 1) !important;
                            fill: rgba(55, 55, 55, 1);
                            fill-opacity: 0.24;
                        }
                    }
                }

                .call-owner {
                    color: white;
                    background: #26b753 !important;
                    border-color: transparent !important;

                    svg path {
                        stroke-width: 0.8 !important;
                        stroke: white !important;
                        fill: white;
                        fill-opacity: 1;
                    }
                }
            }

            .logo-button-container {
                position: relative;
                flex-direction: column;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                // margin-top: -30px;
                background: transparent;
                // box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.45);
                // border-radius: 0;
                // -webkit-border-radius: 0;
                // -moz-border-radius: 0;
                // -ms-border-radius: 0;
                // -o-border-radius: 0;
                // border-top-left-radius: 30px;
                // border-top-right-radius: 30px;

                .logo-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0px 0 17px 0;
                    background: var(--background);

                    img {
                        height: 27px;
                        object-fit: contain;
                    }
                }
            }

            .find-pet-info-outside-container {
                flex-direction: column;
                align-items: center;
                gap: 20px;
                width: 100%;
                padding: 0;
                margin-top: 30px;

                .pet-type {
                    display: block;
                }

                .find-pet-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 14px;
                    width: 100%;
                    padding: 10px 25px;
                    background: transparent;
                    border-radius: 0; // 23px:;
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    -ms-border-radius: 0;
                    -o-border-radius: 0;

                    .find-pet-info-header {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;

                        .header-text {
                            position: relative;
                            // width: 100%;
                            color: white;
                            font-size: 0.95rem;
                            font-weight: 800;
                            padding: 8.5px 14px;
                            background: $sec-design-color;
                            border-radius: $third-border-radius;
                            -webkit-border-radius: $third-border-radius;
                            -moz-border-radius: $third-border-radius;
                            -ms-border-radius: $third-border-radius;
                            -o-border-radius: $third-border-radius;

                            &:after {
                                display: none;
                                content: "";
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 30px;
                                height: 100%;
                                background: $primary-bg;
                                border-radius: 50%;
                                -webkit-border-radius: 50%;
                                -moz-border-radius: 50%;
                                -ms-border-radius: 50%;
                                -o-border-radius: 50%;
                            }
                        }
                    }

                    .label-container {
                        width: 100%;
                        padding: 0 13px;

                        span {
                            color: grey;
                            font-size: 0.9rem;
                            font-weight: 600;
                        }
                        p {
                            width: 100%;
                            // color: white;
                            font-size: 1rem;
                            font-weight: 500;
                            padding: 7px 0; // 7px 14px;
                            // margin-left: 1vh;
                            // background: rgba(55, 55, 55, 1);
                            border-bottom: 2px solid $hover-color;
                            // border-radius: $secondary-border-radius;
                            // -webkit-border-radius: $secondary-border-radius;
                            // -moz-border-radius: $secondary-border-radius;
                            // -ms-border-radius: $secondary-border-radius;
                            // -o-border-radius: $secondary-border-radius;
                        }
                    }

                    textarea {
                        width: 100%;
                        height: 90px;
                        max-height: 90px;
                        // color: white;
                        font-size: 1rem;
                        font-weight: 500;
                        padding: 0 13px;
                        margin-bottom: 20px;
                        background: transparent;
                        border: none;
                    }

                    // .vaccinated {
                    //   color: white;
                    //   background: #26b753;
                    // }

                    // .not-vaccinated {
                    //   color: white;
                    //   background: red;
                    // }
                }
            }

            .locked-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                width: 80%;
                margin: 30px auto;
                padding: 50px 20px;
                border: 3px solid grey;
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;

                .locked-info-svg {
                    width: 45px;
                    height: 45px;
                }

                .locked-info-text {
                    font-size: 1.1rem;
                    font-weight: 700;
                }
            }

            .find-pet-info-container-locked-tbns {
                display: none; // flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                margin: 30px auto;

                a {
                    color: white;
                    font-size: 1rem;
                    padding: 14px 20px;
                    background: rgba(55, 55, 55, 1);
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;
                }
            }

            .find-pet-info-language-switcher-for-desktop {
                display: none !important;
            }
        }
    }

    .find-pet-info-language-switcher-for-desktop {
        display: flex !important;
        gap: 30px !important;
        margin-top: 30px !important;

        img {
            width: 33px !important;
        }
    }

    .find-pet-info-language-switcher {
        position: fixed;
        top: 300px;
        right: 0;
        display: none;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 14px;
        width: fit-content;
        padding: 3.5px 5px;
        background: var(--bodyback-grey);
        border-top-left-radius: $primary-border-radius;
        border-bottom-left-radius: $primary-border-radius;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        -o-transition: 0.5s;

        box-shadow: var(--shadow);

        &:hover {
            right: 0;
        }

        img {
            width: 30px;
            // height: 21px;
            // border-radius: inherit;
            // -webkit-border-radius: inherit;
            // -moz-border-radius: inherit;
            // -ms-border-radius: inherit;
            // -o-border-radius: inherit;
        }
    }

    .dark-mode {
        .header-middle-parent-container {
            .header-middle-inside-container {
                .search-box-and-modal-filter-button {
                    display: none !important;
                }

                .header-right-container {
                    .login-signup-container {
                        button {
                            border-color: transparent !important;
                            background: transparent !important;
                        }
                    }

                    .user-head-container {
                        button {
                            background: transparent !important;
                            border-color: transparent !important;
                        }
                    }

                    .user-head-container.active {
                        button {
                            border-color: transparent !important;
                            background: transparent !important;
                        }
                    }

                    .language-and-darkmode {
                        .lang-switcher-container {
                            button {
                                background: transparent !important;
                                border-color: transparent !important;
                            }
                        }

                        .lang-switcher-container.active {
                            button {
                                border-color: transparent !important;
                                background: transparent !important;
                            }
                        }
                    }

                    .dark-mode-btn {
                        background: transparent !important;
                        border-color: transparent !important;
                    }
                }
            }
        }

        .head-nav {
            background-color: $dark-mode-background;
        }

        .site-main-filtre-out-container {
            background-image: url("../../../public/img/back.jpg") !important;
            border-radius: 0 !important;
            -webkit-border-radius: 0 !important;
            -moz-border-radius: 0 !important;
            -ms-border-radius: 0 !important;
            -o-border-radius: 0 !important;

            .site-main-filter-single-search-container {
                border-radius: 0 !important;
                -webkit-border-radius: 0 !important;
                -moz-border-radius: 0 !important;
                -ms-border-radius: 0 !important;
                -o-border-radius: 0 !important;
            }
        }
    }
}
