@import "./variables.scss";

.custom-wrapper {
    // display: none;
    width: 100% !important;
}

.custom-select-btn {
    width: 100% !important;
    padding: 0 !important;
    margin-bottom: 30px !important;
    background: transparent !important;

    div {
        font-size: 1.1rem !important;
    }

    svg {
        display: none;
    }
}

.custom-drop-down {
    position: relative !important;
    display: flex !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    // background: var(--bodyback-grey) !important;
    box-shadow: none !important;

    ul {
        display: flex;
        justify-content: space-between !important;
        align-items: center !important;
        // gap: 50px;
        width: 100% !important;
        max-height: auto;
        margin: 0 !important;

        li {
            display: flex !important;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            padding: 12px 18px !important;
            font-size: 0.85rem !important;
            white-space: nowrap !important;
            background: var(--bodyback-grey);
            border-radius: $secondary-border-radius !important;
            -webkit-border-radius: $secondary-border-radius !important;
            -moz-border-radius: $secondary-border-radius !important;
            -ms-border-radius: $secondary-border-radius !important;
            -o-border-radius: $secondary-border-radius !important;

            &:hover {
                // color: $sec-design-color !important;
                background: rgba(77, 76, 76, 0.1) !important;
            }

            // &::before {
            //     position: relative;
            //     content: "";
            //     width: 10px;
            //     height: 10px;
            //     padding: 3px;
            //     background: var(--bodyback);
            //     border-radius: $secondary-border-radius;
            //     -webkit-border-radius: $secondary-border-radius;
            //     -moz-border-radius: $secondary-border-radius;
            //     -ms-border-radius: $secondary-border-radius;
            //     -o-border-radius: $secondary-border-radius;
            // }
        }

        li.active {
            &::before {
                background-image: url("../../public/svg/check-without-circle.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
}

.custom-wrapper-quantity {
    width: fit-content !important;
}

.custom-select-btn-quantity {
    gap: 15px !important;
    padding: 10px !important;
    background: transparent !important;

    div {
        max-width: 100% !important;
    }

    svg {
        position: relative !important;
        // display: none;
    }
}

.custom-drop-down-quantity {
    ul {
        &::-webkit-scrollbar {
            display: none !important;
        }

        li {
            text-align: center !important;
        }
    }
}

h4 {
    width: 100%;
    text-align: center;
    margin: 0 auto 20px auto;
}

/****START Modal (background)****/
.order-collars-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    margin-bottom: 15px;
    margin: 10px auto;
    // background: var(--bodyback);
    border-radius: $secondary-border-radius;
    -webkit-border-radius: $secondary-border-radius;
    -moz-border-radius: $secondary-border-radius;
    -ms-border-radius: $secondary-border-radius;
    -o-border-radius: $secondary-border-radius;

    * {
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
    }

    .collar-order-modal-container {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 35px;
        width: 100%;
        height: auto;
        margin: auto;
        padding: 0;
        background-color: transparent !important;

        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;

        transform: translate(0) !important;
        -webkit-transform: translate(0) !important;
        -moz-transform: translate(0) !important;
        -ms-transform: translate(0) !important;
        -o-transform: translate(0) !important;

        .slideshow-and-imglist-and-description {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50%;

            .slideshow-and-imglist {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 500px;
                overflow: hidden;
                // background: red;
                border: none;
                border-radius: $primary-border-radius;
                -webkit-border-radius: $primary-border-radius;
                -moz-border-radius: $primary-border-radius;
                -ms-border-radius: $primary-border-radius;
                -o-border-radius: $primary-border-radius;

                .collar-manual-slideshow-container {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;

                    img {
                        width: 70%;
                        height: 70%;
                        object-fit: cover;
                        border-radius: inherit;
                        -webkit-border-radius: inherit;
                        -moz-border-radius: inherit;
                        -ms-border-radius: inherit;
                        -o-border-radius: inherit;
                    }

                    .prev,
                    .next {
                        cursor: pointer;
                        position: absolute;
                        top: 45%;
                        display: none; // flex;
                        justify-content: center;
                        align-items: center;
                        width: auto;
                        padding: 5px;
                        background-color: rgba(30, 30, 30, 0.5);
                        border-radius: 50%;
                        user-select: none;
                        -webkit-user-select: none;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;

                        svg {
                            height: 30px;
                            max-height: 30px;

                            path {
                                fill: white;
                            }
                        }
                    }

                    /* Position the "next button" to the right */
                    .prev {
                        left: 0;
                    }

                    .next {
                        right: 0;
                    }

                    /* On hover, add a black background color with a little bit see-through */
                    .prev:hover,
                    .next:hover {
                        background-color: rgba(14, 14, 14, 0.9);
                    }
                }

                .imglist {
                    position: absolute;
                    bottom: 0;
                    display: flex;
                    gap: 14px;
                    width: 100%;
                    padding: 0;
                    overflow-x: auto;
                    // background: red;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;

                    &::-webkit-scrollbar {
                        height: 5px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: transparent;
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $sec-design-color;
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;
                    }

                    .img-box {
                        width: 64px;
                        height: 64px;
                        min-width: 64px;
                        min-height: 64px;
                        max-width: 64px;
                        max-height: 64px;
                        cursor: pointer;
                        padding: 1px;
                        overflow: hidden !important;
                        background: var(--bodyback);
                        border: none;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: inherit;
                            -webkit-border-radius: inherit;
                            -moz-border-radius: inherit;
                            -ms-border-radius: inherit;
                            -o-border-radius: inherit;
                        }
                    }
                }
            }

            .collar-description-block-container {
                padding: 15px;
                margin-top: 35px;
                background: var(--bodyback);
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;

                .inside-block-1,
                .inside-block-2,
                .inside-block-3,
                .inside-block-4 {
                    border: none;
                    border-bottom: 1px solid $hover-color;

                    p.title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 35px;
                        width: 100%;
                        cursor: pointer;
                        font-size: 0.87rem;
                        font-weight: 600;
                        padding: 17px 0;
                        background-image: url("../../public/svg/downarrow.svg");
                        background-size: 0;
                        background-position: center center;
                        background-repeat: no-repeat;

                        svg {
                            width: 25px;
                            max-height: 25px;
                            padding: 4px;
                            background: rgba(77, 76, 76, 0.3);
                            border-radius: 50%;
                            -webkit-border-radius: 50%;
                            -moz-border-radius: 50%;
                            -ms-border-radius: 50%;
                            -o-border-radius: 50%;

                            &:hover {
                                background: rgba(77, 76, 76, 0.6);
                            }

                            path {
                                fill: white;
                            }
                        }
                    }

                    p.tag-feature-list {
                        display: none;
                        font-size: 0.87rem;
                        font-weight: 400;
                        margin: 14px;
                    }

                    ul {
                        display: none;
                        margin: 14px;

                        li {
                            font-size: 0.87rem;
                            font-weight: 400;
                            margin: 14px;
                        }
                    }

                    .under {
                        margin: 14px 20px !important;
                    }
                }

                .inside-block-1.active,
                .inside-block-2.active,
                .inside-block-3.active,
                .inside-block-4.active {
                    p.title {
                        background-image: url("../../public/svg/minus.svg");
                    }

                    p.text {
                        display: block;
                    }

                    p.tag-feature-list {
                        display: block;
                    }

                    ul {
                        display: block;
                    }

                    svg {
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                    }
                }
            }
        }

        .collar-header-and-options-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 50%;
            // padding: 7px;
            border: none;
            // background: var(--bodyback);
            // border-radius: $primary-border-radius;
            // -webkit-border-radius: $primary-border-radius;
            // -moz-border-radius: $primary-border-radius;
            // -ms-border-radius: $primary-border-radius;
            // -o-border-radius: $primary-border-radius;

            .collar-header {
                display: none; // flex;
                justify-content: space-between;
                align-items: center;
                padding: 0;
                margin: 0;
                border: none;

                p {
                    font-size: 1.2rem;
                }

                .close_login {
                    display: none;
                    justify-content: center;
                    align-items: center;
                    gap: 7px;
                    color: #aaaaaa;
                    font-size: 1.7rem;
                    font-weight: bold;
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        color: #000;
                        text-decoration: none;
                        cursor: pointer;

                        span {
                            color: #000;
                            text-decoration: none;
                            cursor: pointer;
                        }
                    }

                    span {
                        color: #aaaaaa;
                        font-size: 1rem;
                        font-weight: bold;
                        text-decoration: none;
                    }
                }
            }

            .qr-without-collar {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
            }

            p.qr-collar-price {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0 11px;
                margin-top: 40px;
                font-size: 0.95rem;

                .quantity-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                }
            }

            .select-color-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin: 40px 0;
                padding: 18px 28px;
                // padding: 0px 5px;
                background: var(--bodyback-grey);
                // border: 1px solid $border-primary-color;
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;

                .select-color {
                    // display: flex;
                    // justify-content: flex-start;
                    // align-items: center;
                    // gap: 20px;
                    width: 100%;

                    .select-color-text {
                        display: none;
                        margin: 0;
                        font-size: 0.85rem;
                        margin-bottom: 20px;
                    }

                    .color-button-container {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 25px;
                        flex-wrap: wrap;
                        width: 100%;

                        button {
                            position: relative;
                            display: flex !important;
                            justify-content: space-between;
                            align-items: center;
                            gap: 8px;
                            cursor: pointer;
                            // padding: 9px 21px;
                            transition: 0.2s ease-out;
                            background: transparent;
                            // border: 3px solid transparent;
                            border: none;
                            border-radius: $secondary-border-radius;
                            -webkit-border-radius: $secondary-border-radius;
                            -moz-border-radius: $secondary-border-radius;
                            -ms-border-radius: $secondary-border-radius;
                            -o-border-radius: $secondary-border-radius;

                            &::before {
                                position: relative;
                                width: 20px;
                                height: 20px;
                                content: "";
                                border-radius: 50%;
                                -webkit-border-radius: 50%;
                                -moz-border-radius: 50%;
                                -ms-border-radius: 50%;
                                -o-border-radius: 50%;
                            }

                            &:nth-child(1)::before {
                                background-color: rgb(45, 44, 44);
                            }

                            &:nth-child(2)::before {
                                background-color: #f28117;
                            }

                            &:nth-child(3)::before {
                                background-color: #ecdacc;
                            }

                            &:nth-child(4)::before {
                                background-color: lightpink;
                            }

                            span {
                                display: none;
                            }
                        }

                        button.active {
                            &::before {
                                background-image: url("../../public/svg/check-without-circle-white.svg");
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: 65%;
                            }

                            span {
                                display: block;
                            }
                        }
                    }
                }
            }

            .separate-container {
                flex-grow: 1;
                padding: 20px;
                background: var(--bodyback);
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;
            }

            .qr-tag-size {
                display: flex; // flex;
                justify-content: flex-start;
                align-items: center;
                gap: 14px;
                margin: 40px 0;

                div {
                    width: fit-content;
                    font-size: 0.85rem;
                    text-align: center;
                    padding: 10px 15px;
                    cursor: pointer;
                    background: var(--bodyback-grey);
                    // border: 1px solid transparent;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;

                    // &:hover {
                    //     border-color: grey;
                    // }
                }

                div.active {
                    color: white;
                    background: $sec-design-color;
                    // border-color: grey;
                }
            }

            .warning {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 7px;
                width: 100%;
                margin: auto;
                padding: 16px 11px;
                border: none;
                background: rgba(255, 0, 0, 0.8);
                border-radius: $secondary-border-radius;
                -webkit-border-radius: $secondary-border-radius;
                -moz-border-radius: $secondary-border-radius;
                -ms-border-radius: $secondary-border-radius;
                -o-border-radius: $secondary-border-radius;

                svg {
                    width: 18px;
                    height: 18px;

                    path {
                        fill: white;
                    }
                }

                p {
                    color: white;
                    font-size: 0.8rem;
                }
            }

            form {
                width: 100%;
                margin: auto;

                .input-container {
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    row-gap: 10px;

                    input {
                        width: 48%;
                        padding: 14.5px 27px;
                        font-size: 0.78rem;
                        background: var(--bodyback-grey);
                        border: none;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;

                        ::placeholder {
                            font-size: 0.78rem;
                        }

                        // &:hover {
                        //     border-color: $border-secondary-color;
                        // }
                    }

                    hr {
                        width: 98%;
                        margin: auto;
                        border: 1px solid $hover-color;
                    }

                    .pets-info-for-qr {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: center;
                        row-gap: 24.5px;
                        width: 100%;

                        .pet-photo-container {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;

                            .pet-photo {
                                width: 105px;
                                height: 105px;
                                //margin: auto;
                                cursor: pointer;
                                background: var(--bodyback-grey);
                                background-image: url("../../public/img/photo-camera.svg");
                                background-position: center center;
                                background-size: 30%;
                                background-repeat: no-repeat;
                                overflow: hidden;
                                border: 3px dashed $border-primary-color;
                                border-radius: 50%;
                                -webkit-border-radius: 50%;
                                -moz-border-radius: 50%;
                                -ms-border-radius: 50%;
                                -o-border-radius: 50%;

                                &:hover {
                                    border-color: grey;
                                }

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                    }

                    textarea.comment-for-qr-tag {
                        width: 100%;
                        height: 100px;
                        padding: 14.5px;
                        font-size: 0.78rem;
                        resize: none;
                        background-color: var(--bodyback-grey);
                        border: none;
                        border-radius: $secondary-border-radius;
                        -webkit-border-radius: $secondary-border-radius;
                        -moz-border-radius: $secondary-border-radius;
                        -ms-border-radius: $secondary-border-radius;
                        -o-border-radius: $secondary-border-radius;

                        &:hover {
                            border-color: grey;
                        }
                    }
                }

                .qr-collar-rules {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 7px;
                    margin: 27px 0;

                    input[type="checkbox"] {
                        appearance: none;
                        width: 3px;
                        height: 3px;
                        max-width: 3px;
                        min-width: 3px;
                        padding: 16px;
                        cursor: pointer;
                        border: 2px solid grey;
                        border-radius: 3px;
                        -webkit-border-radius: 3px;
                        -moz-border-radius: 3px;
                        -ms-border-radius: 3px;
                        -o-border-radius: 3px;

                        &:hover {
                            border-color: $sec-design-color;
                        }

                        &:checked {
                            background-color: $sec-design-color;
                            background-image: url(../../public/svg/check-without-circle-white.svg);
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            border-color: $sec-design-color;
                        }
                    }

                    p {
                        font-size: 0.8rem;

                        a {
                            font-size: 0.8rem;
                            color: $sec-design-color;
                            text-decoration: underline;
                        }
                    }
                }

                .order-button {
                    width: 100%;
                    display: block;
                    font-size: 1rem;
                    text-align: center;
                    padding: 11px 0;
                    margin: auto;
                    color: white;
                    cursor: pointer;
                    background-color: $sec-design-color;
                    border: none;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;
                }
            }

            p.shipping-title {
                font-size: 0.95rem;
                font-weight: 600;
                // margin: 40px 3px 20px 3px;
                margin: 20px 3px;
            }

            .shipping {
                margin-bottom: 27px;

                .shipping-header-flex {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 7px;
                    margin: 0 4px;

                    svg {
                        width: 22px;
                        height: 22px;

                        path {
                            fill: rgb(77, 77, 77);
                        }
                    }

                    p {
                        font-size: 0.78rem;
                        font-weight: 500;
                    }
                }

                .inside-container {
                    .delivery-prices {
                        font-size: 0.78rem;
                        padding: 10px;
                        margin: 3.5px 32px;
                        background-color: $hover-color;
                        border-radius: $primary-border-radius;
                        -webkit-border-radius: $primary-border-radius;
                        -moz-border-radius: $primary-border-radius;
                        -ms-border-radius: $primary-border-radius;
                        -o-border-radius: $primary-border-radius;
                    }

                    .delivery-report {
                        font-size: 0.7rem;
                        font-weight: normal;
                        margin: 0 35px;
                    }
                }
            }

            .wrapper {
                position: relative;
                width: 48%;
                background: transparent;

                .select-button {
                    position: relative;
                    width: 100%;
                    display: flex !important;
                    justify-content: space-between;
                    align-items: center;
                    padding: 14.5px 27px;
                    cursor: pointer;
                    background: var(--bodyback-grey);
                    border: none;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;

                    &:hover {
                        border-color: $border-secondary-color;
                    }

                    div {
                        color: $primary-color;
                        font-size: 0.78rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        max-width: 90%;
                        padding: 0;
                    }

                    svg {
                        width: 11px;
                        height: 11px;

                        path {
                            stroke: grey;
                        }
                    }
                }

                .select-menu-dropdown {
                    position: absolute;
                    display: none;
                    width: 100%;
                    background-color: var(--bodyback);
                    padding: 7px;
                    margin-top: 10px;
                    z-index: 1000;
                    border-radius: $secondary-border-radius;
                    -webkit-border-radius: $secondary-border-radius;
                    -moz-border-radius: $secondary-border-radius;
                    -ms-border-radius: $secondary-border-radius;
                    -o-border-radius: $secondary-border-radius;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

                    .filter-default-text {
                        width: 100%;
                        font-size: 0.78rem;
                        text-align: center;
                        padding: 35px;
                    }

                    .select-search {
                        width: 100%;

                        input {
                            width: 100%;
                            padding: 10px 35px !important;
                            outline: none;
                            background-color: $primary-bg;
                            background-image: url(../../public/svg/search.svg);
                            background-repeat: no-repeat;
                            background-position: 10px center;
                            background-size: 16px;
                            text-align: left;
                            border: 1px solid lightgrey;
                            border-radius: $primary-border-radius;
                            -webkit-border-radius: $primary-border-radius;
                            -moz-border-radius: $primary-border-radius;
                            -ms-border-radius: $primary-border-radius;
                            -o-border-radius: $primary-border-radius;

                            &::placeholder {
                                font-size: 0.78rem;
                            }
                        }
                    }

                    ul.options {
                        max-height: 250px;
                        background-color: transparent !important;
                        margin-top: 7px;
                        list-style: none;
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            width: 7px;
                        }

                        &::-webkit-scrollbar-track {
                            background-color: transparent;
                            border-radius: $primary-border-radius;
                            -webkit-border-radius: $primary-border-radius;
                            -moz-border-radius: $primary-border-radius;
                            -ms-border-radius: $primary-border-radius;
                            -o-border-radius: $primary-border-radius;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $sec-design-color;
                            border-radius: $primary-border-radius;
                            -webkit-border-radius: $primary-border-radius;
                            -moz-border-radius: $primary-border-radius;
                            -ms-border-radius: $primary-border-radius;
                            -o-border-radius: $primary-border-radius;
                        }

                        li {
                            color: black;
                            font-size: 0.75rem;
                            padding: 11px 10px;
                            cursor: pointer;
                            border-radius: $primary-border-radius;
                            -webkit-border-radius: $primary-border-radius;
                            -moz-border-radius: $primary-border-radius;
                            -ms-border-radius: $primary-border-radius;
                            -o-border-radius: $primary-border-radius;

                            &:hover {
                                background-color: var(--bodyback-grey);
                            }
                        }
                    }
                }
            }

            .select-button.active {
                outline: none;
                // border-color: grey;

                svg {
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                }

                .select-menu-dropdown {
                    display: block;
                }
            }
        }
    }
}
