@import "../variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@200&family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

// for small desktops
@media screen and (min-width: 1023px) and (max-width: 1064px) {
    .header-middle-parent-container {
        .header-middle-inside-container {
            max-width: 1000px;
            gap: 30px;
        }
    }

    .header-top-container {
        .header-top-inside-container {
            // max-width: 1000px;
        }
    }

    .head-nav {
        .head-nav-inside-container {
            // max-width: 1000px;

            .service-for-large-desktop {
                display: none;
            }

            div.institutions-dropdown-button-container {
                display: flex;
            }
        }
    }

    .qr-tags-container {
        max-width: 1000px;
    }

    .qr-and-gps-tags {
        .qr-and-gps-tags-inside-container {
            // display: flex;
            // flex-wrap: wrap;
            // justify-content: space-around;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-items: center;

            .tag-container {
                width: 100%;
            }
        }
    }

    .members-and-tags-counter-container {
        max-width: 1000px;
    }

    .tags-card-container-for-homepage {
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;

        .tags-card-inside-container-for-homepage {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;

            .tag-card-for-home-page {
                justify-content: center !important;
                width: 45%;
                height: 400px;
            }
        }
    }

    .site-main-filtre-out-container {
        .site-main-filter-single-search-container {
            max-width: 1000px;
            // border-radius: 0;
            // -webkit-border-radius: 0;
            // -moz-border-radius: 0;
            // -ms-border-radius: 0;
            // -o-border-radius: 0;
        }
    }

    .parent-container-short,
    .userpanel-container {
        padding: 0 20px;
        // max-width: 1000px;
    }

    .footer-parent-container {
        .footer-parent-inside-container {
            // max-width: 1000px !important;
        }
    }

    .order-collars-container {
        max-width: 1000px !important;
    }

    .userpanel-container {
        min-height: 80vh !important;
        // max-width: 1000px !important;
        .userpanel-screen-main-container {
            .active-post-container {
                width: 97%;
            }

            .purchased-collars {
                .purchased-collars-cards-container {
                    grid-template-columns: repeat(1, 1fr) !important;
                    padding: 0 40px;
                }
            }
        }
    }
}
